import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { from, of } from 'rxjs';
import { LayoutState } from 'src/app/shared/models/layout-state.model';
import { AppService, VibrationService } from 'src/app/shared/services';
import { SharedModule } from 'src/app/shared/shared.module';



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-mobile-engine-debug-popover',
  templateUrl: 'mobile-engine-debug.popover.html',
  styleUrls: ['./mobile-engine-debug.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileEngineDebugPopover {

  mobileEngineLayoutState: LayoutState;
  mobileEngineOperationalMode: 'hybrid' | 'online';

  constructor(
    private appService: AppService,
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    const windowAny: any = window;
    this.mobileEngineLayoutState = this.navParams.get('mobileEngineLayoutState');
    this.mobileEngineOperationalMode = windowAny.mobileEngine?.operationalMode;
  }

  mobileEngineOperationalModeChange(online: boolean) {
    const windowAny: any = window;
    windowAny.mobileEngine.operationalMode = online ? 'online' : 'hybrid';

    this.appService.initMobileEngineAndThenInitWsAndAuthenticate(this.appService.getDeviceEnrollment());
    this.popoverCtrl.dismiss();
  }

  mobileEngineLayoutStateChange(online: boolean) {
    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('DebugChangeOnlineStateAsync', online))
    .subscribe(() => {
      this.popoverCtrl.dismiss();
    });
  }

  mobileEngineRebootCloud() {
    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('DebugRebootCloudAsync'))
    .subscribe(() => {
      this.popoverCtrl.dismiss();
    });
  }

}
