import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { md5 } from 'js-md5';
import { Observable, from, of, throwError } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { TestPrinterPopover } from 'src/app/popovers';
import { BluetoothDevice, BluetoothDeviceMode, BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models/runtime-layout/solution-device-control-scanner-enabled-type.enum';
import { LogUtils } from 'src/app/shared/utils';
import { Notification } from '../../../models';
import { BasePlugin, PluginType } from '../../../services';
import { BrowserUtils } from '../../../utils/browser-utils';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlInput1Component } from '../input1/control-input1.component';


@Component({
  selector: 'lc-control-update-esl1',
  templateUrl: 'control-update-esl1.component.html',
  styleUrls: ['./control-update-esl1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlUpdateESL1Component extends ControlBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly emptyPayload = '060710000000011E060656085550005401054015zGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHmGILbGMLzGmGHlGJIKbGMLzGmGHlGJaIbGMLzGmGHlGJaIbGMLzGmGHlGJaINaGMLzGmGHlGJaINaGMLzGmGHlGJIMLaGMLzGmGHlGJIMLaGMLzGmGHlGJIHKaGMLjGMKcGJIKtGHlGJIHKaGMLGHKaGHKGJKJaINbGbINsGHlGJIJIaGMLGHKaGHKGJIHaILaGJbIKsGHlGJIJIaGMLGHKaGHKGJIMaILaGHcIsGHlGJIGINGMLGHKaGHKGJaIGIKaGMIGHINrGHlGJIGINGMLGHKaGHKGJIKGHKaGILGJINrGHlGJIGMLGMLGHKaGHKGJILGHIaGINaGILrGHlGJIGMLGMLGHKaGHKGJINGJIGJIbGMLrGHlGJIGHKGMLGHKaGHKGJIaGJIGJIbGMLrGHlGJIGHKGMLGHKaGHKGJIaGJIGJIbGMKrGHlGJIGJIGMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIGJINMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGINMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGILMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGMLMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGHKMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGHKMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGJIMLGHKaGHKGJIaGJIGHKbGHKrGHlGJIaGJIMLGHKaGHKGJIaGJIGHIbGMLrGHlGJIbGaILGHKaGHKGJIaGJIGJIbGMLrGHlGJIbGaILGHKaGMKGJIaGJIGJIbGMLrGHlGJIbGMILGHIaGIKGJIaGJIGJINaGINrGHlGJIbGMILGJIGJIKGJIaGJIaGILGJINrGHlGJIbGHILGJILHIKGJIaGJIaGaIGMIsGHlGJIbGHILaGbI10KGJIaGJIaGMbIKsGHlGJIbGJILaGbIHKGJIaGJIaGHbILsGHlGJIcGINaGMIKJKGJIaGJIbGbINsGHvGJINkGHILtGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGHzGzGgGH';
  readonly defaultPassword = '00000000';
  readonly delayBetweenWritesInMs = 100;
  readonly maxShortValue = 65536;

  @ViewChild('controlVerificationComponent') controlVerificationComponent: ControlInput1Component;

  private btPlugin: BasePlugin;
  private eslBluetoothAddress: string;
  private eslPayload: string;
  private nextHighBitSeq: number;
  private updated: boolean;

  theme: string;
  staticControlVerification: any;
  private failedUpdateReason: any;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);

    this.eslBluetoothAddress = RuntimeLayoutUtils.parseRV(this.layoutControl, 'EslBluetoothAddress') || '';
    this.eslPayload = RuntimeLayoutUtils.parseRV(this.layoutControl, 'EslPayload') || this.emptyPayload;
    this.cdr.markForCheck();

    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanVerification')) return;

    const simpleBarcodeScannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Simple | SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner | SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner;
    this.staticControlVerification = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Scan Verification') + ':',
      scannerEnabledType: simpleBarcodeScannerEnabledType,
      keyboardType: KeyboardType.None,
      verificationErrorText: RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanVerificationText'),
    };
    this.scannerService.ignoreScanInPrimaryLayoutControl = true;
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.start();

    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.scannerService.ignoreScanInPrimaryLayoutControl = false;
  }

  start() {
    const isTestDeveloper = this.localSettingsService.get().runDeviceDebugTestDeveloper;
    if (isTestDeveloper) {
      this.showTestPopover();
    } else {
      this.tryToPrint();
    }
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    context.set('Updated', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(!!this.updated),
      valueTypeId: RuntimeLayoutValueType.Bool
    }));

    if (!this.updated) {
      context.set('FailedUpdateReason', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(this.failedUpdateReason || this.translateService.instant('-- No error message--')),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  tryToPrint() {
    this.failedUpdateReason = undefined;
    this.vibrationService.vibrate();

    if (!BrowserUtils.isDeviceApp()) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Updating ESL is only available on device apps.'),
        type: RuntimeLayoutNotifyType.Alert
      }));
      return;
    }

    const eslDevice = new BluetoothDevice({
      id: this.eslBluetoothAddress,
      mode: BluetoothDeviceMode.BLE,
      type: BluetoothDeviceType.ESL,
      settings: {
        disconnectOnInactivityTimeoutMS: 1000,
      }
    });

    this.busyService.setBusy(
      true,
      this.translateService.instant('Connecting to ESL...')
    );
    this.cdr.markForCheck();

    let authAppRandomBytes = new Uint8Array(4);

    this.btPlugin.action({
      command: 'connect',
      device: eslDevice,
      skipSaveSettingRemotely: true,
    })
    .pipe(
      mergeMap(() => {
        return this.btPlugin.action({
          command: 'requestMtu',
          device: eslDevice,
          mtu: 247,
        });
      }),
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        return this.auth1Request(eslDevice, authAppRandomBytes);
      }),
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        return this.btPlugin.action({
          command: 'read',
          device: eslDevice,
          serviceUUID: BluetoothDevice.EslConfigServiceUUID,
          characteristicUUID: BluetoothDevice.EslConfigNotifyCharacteristicUUID,
        });
      }),
      mergeMap((response: ArrayBuffer) => {
        const auth1Response = new Uint8Array(response);
        return this.handleAuth1ResponseAndSentAuth2Request(eslDevice, authAppRandomBytes, auth1Response);
      }),
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        return this.btPlugin.action({
          command: 'read',
          device: eslDevice,
          serviceUUID: BluetoothDevice.EslConfigServiceUUID,
          characteristicUUID: BluetoothDevice.EslConfigNotifyCharacteristicUUID,
        });
      }),
      map((response: ArrayBuffer) => {
        const auth2Response = new Uint8Array(response);
        return this.handleAuth2Response(eslDevice, auth2Response);
      }),
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        return this.btPlugin.action({
          command: 'requestMtu',
          device: eslDevice,
          mtu: eslDevice.settings.writeChunkSize,
        });
      }),
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        this.busyService.setBusy(
          true,
          this.translateService.instant('Writing to ESL...')
        );
        this.cdr.markForCheck();

        return this.writeData(eslDevice, 0);
      }),
    )
    .subscribe(() => {
      this.successHandler();
    }, (error: any) => {
      this.errorHandler(error);
    });

    /*KESL.connectAndWrite(
      {
        eslAddress: this.eslBluetoothAddress,
        eslType: 6,
        payload: this.eslPayload,
        payloadType: 'full',
      },
      (success: any) => {
        this.ngZone.run(() => {
          setTimeout(() => {
            KESL.connectAndWrite(
              {
                eslAddress: this.eslBluetoothAddress,
                eslType: 6,
                payload: 'hello world',
                payloadType: 'text',
                payloadTextRow: 0,
                payloadTextColumn: 0,
                payloadBackgroundColor: 'transparent',
                payloadTextColor: 'red',
                payloadTextBackgroundColor: 'white',
              },
              (success: any) => {
                this.successHandler();
              }, (error: any) => {
                this.errorHandler(error);
              }
            );
          }, 20*1000);
        });
      }, (error: any) => {
        this.errorHandler(error);
      }
    );*/
  }

  private auth1Request(eslDevice: BluetoothDevice, authAppRandomBytes: Uint8Array): Observable<any> {
    this.busyService.setBusy(
      true,
      this.translateService.instant('Authenticating ESL...')
    );
    this.cdr.markForCheck();

    const randomNumber = ~~(Math.random() * 0xFFFFFFF);
    authAppRandomBytes[0] = (randomNumber >> 24) & 0xFF;
    authAppRandomBytes[1] = (randomNumber >> 16) & 0xFF;
    authAppRandomBytes[2] = (randomNumber >> 8) & 0xFF;
    authAppRandomBytes[3] = (randomNumber >> 0) & 0xFF;

    const auth1Request = new Uint8Array(6);
    auth1Request[0] = 0x13;
    auth1Request[1] = 0x1;
    auth1Request.set(authAppRandomBytes, 2);
    LogUtils.log('eslAuth1Request', auth1Request.toString());
    return this.btPlugin.action({
      command: 'write',
      device: eslDevice,
      writeData: this.bytesToString(auth1Request),
      serviceUUID: BluetoothDevice.EslConfigServiceUUID,
      characteristicUUID: BluetoothDevice.EslConfigWriteCharacteristicUUID,
    });
  }

  private handleAuth1ResponseAndSentAuth2Request(eslDevice: BluetoothDevice, authAppRandomBytes: Uint8Array, auth1Response: Uint8Array): Observable<any> {
    if (!auth1Response?.length) return throwError(new Error('Empty auth1Response!'));

    LogUtils.log('eslAuth1Response', auth1Response.toString());
    if (auth1Response[0] !== 0x13) return of(null); // not the msg we were expecting...
    if (auth1Response[1] !== 0x1 && auth1Response[1] !== 0x11) return of(null); // invalid authentication type

    let authDeviceRandomBytes = auth1Response.slice(2, 6);

    const authAppMd5Data = new Uint8Array(12 + this.defaultPassword.length);
    const authDeviceMd5Data = new Uint8Array(12 + this.defaultPassword.length);

    if (auth1Response[1] === 0x1) {
      const macAddressBytes = this.hexStringToBytes(eslDevice.id.replace(/:/g, ''));
      const macAddressBytesReversed = new Uint8Array(6);
      for (let i = 0; i < 6; i++){
        macAddressBytesReversed[i] = macAddressBytes[5-i];
      }
      authAppMd5Data.set(macAddressBytesReversed, 0);
      authAppMd5Data[6] = 0xA9;
      authAppMd5Data[7] = 0xB1;
      authAppMd5Data.set(authAppRandomBytes, 8);
      authAppMd5Data.set(this.stringToBytes(this.defaultPassword), 12);

      const authAppMd5 = md5.array(authAppMd5Data).toString();
      const authDeviceMd5 = auth1Response.slice(6).toString();
      if (authAppMd5 !== authDeviceMd5) return of(null); // incorrect md5...

      authDeviceMd5Data.set(macAddressBytesReversed, 0);
      authDeviceMd5Data[6] = 0xA9;
      authDeviceMd5Data[7] = 0xB1;
      authDeviceMd5Data.set(authDeviceRandomBytes, 8);
      authDeviceMd5Data.set(this.stringToBytes(this.defaultPassword), 12);

      const auth2Request = new Uint8Array(18);
      auth2Request[0] = 0x13;
      auth2Request[1] = 0x2;
      auth2Request.set(new Uint8Array(md5.array(authDeviceMd5Data)), 2);
      LogUtils.log('eslAuth2Request', auth2Request.toString());
      return this.btPlugin.action({
        command: 'write',
        device: eslDevice,
        writeData: this.bytesToString(auth2Request),
        serviceUUID: BluetoothDevice.EslConfigServiceUUID,
        characteristicUUID: BluetoothDevice.EslConfigWriteCharacteristicUUID,
      });
    } else if (auth1Response[1] === 0x11) {
      return of(null); // not supported at the moment
    }

    return of(null);
  }

  private handleAuth2Response(eslDevice: BluetoothDevice, auth2Response: Uint8Array): void {
    if (!auth2Response?.length) throw new Error('Empty auth2Response!');

    LogUtils.log('eslAuth2Response', auth2Response.toString());
    if (auth2Response[0] !== 0x13) throw new Error('Invalid auth2Response[0].'); // not the msg we were expecting...
    if (auth2Response[1] !== 0x2) throw new Error('Authentication failed.'); // 0x2 = success, otherwise, error

    eslDevice.settings.writeChunkSize = (auth2Response[2] & 0xFF) - 3; // MTU_SIZE_HEAD
    return;
  }

  private writeData(eslDevice: BluetoothDevice, dataIndex: number): Observable<any> {
    return this.writePDU(eslDevice, dataIndex)
    .pipe(
      delay(this.delayBetweenWritesInMs),
      mergeMap(() => {
        return this.btPlugin.action({
          command: 'read',
          device: eslDevice,
          serviceUUID: BluetoothDevice.EslConfigServiceUUID,
          characteristicUUID: BluetoothDevice.EslConfigNotifyCharacteristicUUID,
        });
      }),
      mergeMap((response: ArrayBuffer) => {
        const pduResponse = new Uint8Array(response);
        if (!pduResponse?.length) return of(null);
        LogUtils.log('eslPduResponse', pduResponse.toString());

        const dataType = (pduResponse[0] >> 4) & 0xF;
        const frameType = pduResponse[0] & 0xF;

        let nextDataIndex = (pduResponse[1] & 0xFF) << 8;
        nextDataIndex += pduResponse[2] & 0xFF;
        nextDataIndex += this.nextHighBitSeq * this.maxShortValue;

        let ackCause = (pduResponse[5] & 0xFF) << 8;
        ackCause += pduResponse[6] & 0xFF;
        if (ackCause == 0x0) { // Success
          return of(null);
        } else if (ackCause == 0x4) { // Expect Next
          return this.writeData(eslDevice, nextDataIndex);
        } else {
          return throwError(new Error('Data exchange error: ' + ackCause));
        }
      }),
    );
  }

  private writePDU(eslDevice: BluetoothDevice, dataIndex: number) {
    const pduHeadLength = 3;
    const dataType = 4; // DATA_TYPE_ASCII_ZIP
    let dataLength = 0;
    let pduTag = 0;
    const maxDataSize = (eslDevice.settings.writeChunkSize || 90) - pduHeadLength;
    if (this.eslPayload.length < maxDataSize) {
      pduTag = 3; // FrameSingle = 3
      dataLength = this.eslPayload.length;
    } else if (dataIndex === 0) {
      pduTag = 0; // FrameStart = 0
      dataLength = maxDataSize;
    } else if (dataIndex + maxDataSize < this.eslPayload.length) {
      pduTag = 1; // FrameMiddle = 1
      dataLength = maxDataSize;
    } else if (dataIndex + maxDataSize >= this.eslPayload.length) {
      pduTag = 2; // FrameEnd = 2
      dataLength = this.eslPayload.length - dataIndex;
    }

    const pdu = new Uint8Array(dataLength + pduHeadLength);
    pdu[0] = ((dataType << 4) + pduTag) & 0xFF;
    this.nextHighBitSeq = ~~((dataIndex + dataLength) / this.maxShortValue);
    const currLowBitSeq = (dataIndex % this.maxShortValue);
    pdu[1] = (currLowBitSeq & 0xFF00) >> 8;
    pdu[2] = currLowBitSeq & 0xFF;

    pdu.set(this.stringToBytes(this.eslPayload.slice(dataIndex, dataIndex + dataLength)), 3);

    LogUtils.log('eslPduRequest', dataIndex, dataLength, pdu.toString());
    return this.btPlugin.action({
      command: 'write',
      device: eslDevice,
      writeData: this.bytesToString(pdu),
      serviceUUID: BluetoothDevice.EslConfigServiceUUID,
      characteristicUUID: BluetoothDevice.EslConfigWriteCharacteristicUUID,
    });
  }

  private showTestPopover(): void {
    from(this.popoverCtrl.create({
      component: TestPrinterPopover,
      cssClass: `popover-test-printer`,
      backdropDismiss: false,
      showBackdrop: true
    }))
    .pipe(
      mergeMap((popover: HTMLIonPopoverElement) => {
        popover.present();
        return from(popover.onDidDismiss())
        .pipe(
          map((result: OverlayEventDetail<any>) => {
            return result.data;
          })
        );
      })
    ).subscribe((context: any) => {
      if (context) {
        this.updated = context.printed;

        if (this.updated) {
          this.successHandler();
        } else {
          this.errorHandler(context.failedPrintReason);
        }
      } else {
        this.tryToPrint();
      }
    });
  }

  private successHandler() {
    this.updated = true;
    this.busyService.setBusy(false);

    this.noUserInteractionHandler('Updated');

    this.cdr.markForCheck();
  }

  private errorHandler(error: any) {
    this.updated = false;
    this.failedUpdateReason = error;
    this.busyService.setBusy(false);

    const errorMsg = typeof error === 'string' ? error
    : error?.message ? error.message
    : JSON.stringify(error);
    this.notificationService.showNotification(new Notification({
      title: this.translateService.instant('Notification'),
      text: this.translateService.instant('UpdateESL error:') + ' ' + errorMsg,
      type: RuntimeLayoutNotifyType.Alert
    }));

    this.noUserInteractionHandler('Failed');

    this.cdr.markForCheck();
  }

  private noUserInteractionHandler(portName: string) {
    if (
      !RuntimeLayoutUtils.parseRV(this.layoutControl, 'UserInteraction') &&
      !RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanVerification')
    ) {
      const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
      eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(portName),
        valueTypeId: RuntimeLayoutValueType.String
      }));

      this.triggerEvent.emit({
        eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
      });
    }
  }

  runScanVerification() {
    const controlContext = this.controlVerificationComponent?.getControlContext();
    const scanValue = RuntimeLayoutUtils.parseLayoutValue(controlContext?.get('TextBox'));
    if (scanValue === RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanVerificationValue')) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Scan verified successfuly.'),
        type: RuntimeLayoutNotifyType.Confirmation
      }));

      this.triggerEvent.emit({
        platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton,
      });
    } else {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanVerificationText'),
        type: RuntimeLayoutNotifyType.Alert
      }));
    }
  }

  private stringToBytes(str: string) {
    const array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        array[i] = str.charCodeAt(i);
     }
     return array;
  }

  private bytesToString(buffer: ArrayBuffer) {
    return String.fromCharCode.apply(null, new Uint8Array(buffer));
  }

  private hexStringToBytes(hexString: string): Uint8Array {
    if (!hexString) return null;

    hexString = hexString.toUpperCase();
    for (let i = 0; i < hexString.length; i++){
      if (this.charToByte(hexString[i]) < 0) return null;
    }

    const length = hexString.length / 2;
    const result = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        let pos = i * 2;
        result[i] = this.charToByte(hexString[pos]) << 4 | this.charToByte(hexString[pos + 1]);
    }
    return result;
  }

  private charToByte(c: string): number {
    return '0123456789ABCDEF'.indexOf(c);
  }

  private bytesToHexString(src: Uint8Array): string {
    if (src?.length <= 0) return null;

    let result = '';
    for (let i = 0; i < src.length; i++) {
      let v = src[i] & 0xFF;
      const hv = v.toString(16);
      if (hv.length < 2) result += '0';
      result += hv;
    }
    return result;
  }

}

