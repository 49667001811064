import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";

export class LayoutClientAuthenticationMessage {
    messageType: LayoutMessageType;
    enrollmentGuidId: string | null;
    fromTick: bigint;
    noSnapshot: boolean;
    clientVersion: string | null;
    deviceDateTime: string | null;
    runDeviceDebug: boolean;
    sourceEnrollmentGuidId: string | null;
    enrollmentEnvironmentGuidId: string | null;
    runCodeToken: string | null;
    deviceSolutionSetGuidId: string | null;
    deviceSolutionSetTick: bigint;
    deviceSolutionGuidId: string | null;
    deviceSolutionTick: bigint;
    deviceEngineGuidId: string | null;
    deviceReload: boolean;
    clientRunEngineType: number;
    layoutClientDataType: string | null;

    constructor() {
        this.messageType = 0;
        this.enrollmentGuidId = null;
        this.fromTick = 0n;
        this.noSnapshot = false;
        this.clientVersion = null;
        this.deviceDateTime = null;
        this.runDeviceDebug = false;
        this.sourceEnrollmentGuidId = null;
        this.enrollmentEnvironmentGuidId = null;
        this.runCodeToken = null;
        this.deviceSolutionSetGuidId = null;
        this.deviceSolutionSetTick = 0n;
        this.deviceSolutionGuidId = null;
        this.deviceSolutionTick = 0n;
        this.deviceEngineGuidId = null;
        this.deviceReload = false;
        this.clientRunEngineType = 0;
        this.layoutClientDataType = null;

    }

    static serialize(value: LayoutClientAuthenticationMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutClientAuthenticationMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(18);
        writer.writeInt32(value.messageType);
        writer.writeString(value.enrollmentGuidId);
        writer.writeInt64(value.fromTick);
        writer.writeBoolean(value.noSnapshot);
        writer.writeString(value.clientVersion);
        writer.writeString(value.deviceDateTime);
        writer.writeBoolean(value.runDeviceDebug);
        writer.writeString(value.sourceEnrollmentGuidId);
        writer.writeString(value.enrollmentEnvironmentGuidId);
        writer.writeString(value.runCodeToken);
        writer.writeString(value.deviceSolutionSetGuidId);
        writer.writeInt64(value.deviceSolutionSetTick);
        writer.writeString(value.deviceSolutionGuidId);
        writer.writeInt64(value.deviceSolutionTick);
        writer.writeString(value.deviceEngineGuidId);
        writer.writeBoolean(value.deviceReload);
        writer.writeInt32(value.clientRunEngineType);
        writer.writeString(value.layoutClientDataType);

    }

    static serializeArray(value: (LayoutClientAuthenticationMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutClientAuthenticationMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutClientAuthenticationMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutClientAuthenticationMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutClientAuthenticationMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutClientAuthenticationMessage();
        if (count == 18) {
            value.messageType = reader.readInt32();
            value.enrollmentGuidId = reader.readString();
            value.fromTick = reader.readInt64();
            value.noSnapshot = reader.readBoolean();
            value.clientVersion = reader.readString();
            value.deviceDateTime = reader.readString();
            value.runDeviceDebug = reader.readBoolean();
            value.sourceEnrollmentGuidId = reader.readString();
            value.enrollmentEnvironmentGuidId = reader.readString();
            value.runCodeToken = reader.readString();
            value.deviceSolutionSetGuidId = reader.readString();
            value.deviceSolutionSetTick = reader.readInt64();
            value.deviceSolutionGuidId = reader.readString();
            value.deviceSolutionTick = reader.readInt64();
            value.deviceEngineGuidId = reader.readString();
            value.deviceReload = reader.readBoolean();
            value.clientRunEngineType = reader.readInt32();
            value.layoutClientDataType = reader.readString();

        }
        else if (count > 18) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageType = reader.readInt32(); if (count == 1) return value;
            value.enrollmentGuidId = reader.readString(); if (count == 2) return value;
            value.fromTick = reader.readInt64(); if (count == 3) return value;
            value.noSnapshot = reader.readBoolean(); if (count == 4) return value;
            value.clientVersion = reader.readString(); if (count == 5) return value;
            value.deviceDateTime = reader.readString(); if (count == 6) return value;
            value.runDeviceDebug = reader.readBoolean(); if (count == 7) return value;
            value.sourceEnrollmentGuidId = reader.readString(); if (count == 8) return value;
            value.enrollmentEnvironmentGuidId = reader.readString(); if (count == 9) return value;
            value.runCodeToken = reader.readString(); if (count == 10) return value;
            value.deviceSolutionSetGuidId = reader.readString(); if (count == 11) return value;
            value.deviceSolutionSetTick = reader.readInt64(); if (count == 12) return value;
            value.deviceSolutionGuidId = reader.readString(); if (count == 13) return value;
            value.deviceSolutionTick = reader.readInt64(); if (count == 14) return value;
            value.deviceEngineGuidId = reader.readString(); if (count == 15) return value;
            value.deviceReload = reader.readBoolean(); if (count == 16) return value;
            value.clientRunEngineType = reader.readInt32(); if (count == 17) return value;
            value.layoutClientDataType = reader.readString(); if (count == 18) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutClientAuthenticationMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutClientAuthenticationMessage | null)[] | null {
        return reader.readArray(reader => LayoutClientAuthenticationMessage.deserializeCore(reader));
    }
}
