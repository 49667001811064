import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { Settings } from '../../models';
import { BluetoothDevice, BluetoothDeviceMode, BluetoothDeviceType } from '../../models/bluetooth-device.model';
import { StorageService } from '../app/storage.service';
import { ThemeService } from '../theme/theme.service';



@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService {

  private readonly storageKey = 'lc_settings';
  private readonly defaultTheme = 'light';
  private readonly defaultFontSize = 'fontSize';
  private settingsSubject: BehaviorSubject<Settings>;

  private settings: Settings;

  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private themeService: ThemeService,
  ) {
    this.settings = new Settings();
    this.settings.vibration = !this.platform.is('ios');
    this.settingsSubject = new BehaviorSubject(this.settings);

    this.storageService.get(this.storageKey)
    .subscribe((settings: Settings) => {
      if (settings) {
        settings.vibration = settings.vibration != null ? settings.vibration : !this.platform.is('ios');
        this.settings = new Settings(settings);
      }

      this.themeService.setTheme(
        this.settings ? this.settings.theme : this.defaultTheme,
        this.settings ? this.settings.fontSize : this.defaultFontSize,
      );

      this.settingsSubject.next(this.settings);
    });
  }

  reset(): void {
    this.set(new Settings()).subscribe();
  }

  get(): Settings {
    return this.settings;
  }

  set(settings: Settings): Observable<void> {
    if (settings) this.settings = settings;

    this.themeService.setTheme(
      this.settings ? this.settings.theme : this.defaultTheme,
      this.settings ? this.settings.fontSize : this.defaultFontSize,
    );
    this.settingsSubject.next(this.settings);

    return this.storageService.set(this.storageKey, this.settings);
  }

  commitLocalChanges() {
    // we need to store the btDevices.isConnected as false.
    const settingsToSave = JSON.parse(JSON.stringify(this.settings));
    for (const device of settingsToSave.btDevices || []) {
      device.isConnected = false;
    }
    this.storageService.set(this.storageKey, settingsToSave).subscribe();
  }

  listen(): Observable<Settings> {
    return this.settingsSubject as Observable<Settings>;
  }

  getBtDevice(deviceId: string): BluetoothDevice {
    return this.settings.btDevices
    .find((d: BluetoothDevice) => {
      return d.id === deviceId;
    });
  }

  getBtDevices(mode?: BluetoothDeviceMode, types?: BluetoothDeviceType[]): BluetoothDevice[] {
    return this.settings.btDevices
    .filter((d: BluetoothDevice) => {
      return (!mode || d.mode === mode) &&
        (!types?.length || types.indexOf(d.type) >= 0);
    });
  }

  addOrUpdateBtDevice(device: BluetoothDevice): void {
    if (!device) return;

    const existing = this.settings.btDevices.find((d: BluetoothDevice) => {
      return d.mode === device.mode && d.id === device.id;
    });
    if (!existing) {
      this.settings.btDevices.push(device instanceof BluetoothDevice ? device : new BluetoothDevice(device));
    } else {
      Object.assign(existing, device);
    }

    this.commitLocalChanges();
  }

  removeBtDevice(device: BluetoothDevice): void {
    if (!device) return;

    const index = this.settings.btDevices.findIndex((d: BluetoothDevice) => {
      return d.mode === device.mode && d.id === device.id;
    });
    if (index < 0) return;

    this.settings.btDevices.splice(index, 1);
    this.commitLocalChanges();
  }

  clearBtDevices(): void {
    this.settings.btDevices.length = 0;

    this.commitLocalChanges();
  }

}
