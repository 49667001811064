import { Injectable, Injector } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { DictString } from 'src/app/shared/models/dict.model';
import { BrowserUtils, LogUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';
import { CipherLab } from 'cordova-plugin-cipherlab';


export interface CipherLabPluginSettings {

}


@Injectable({
  providedIn: 'root'
})
export class CipherLabPlugin extends BasePlugin {

  private readonly intentActionBarcode = 'com.cipherlab.barcodebaseapi.PASS_DATA_2_APP';
  private readonly intentActionRfid = 'com.cipherlab.rfidservice.SERVICE_TAG_DATA';

  private isBarcodeScannerEnabled: boolean;
  private isPluginAllowedChecked: boolean;
  private isRfidScannerEnabled: boolean;
  private rfidScanValueTickMap: DictString<number> = {};
  private settings: CipherLabPluginSettings;

  constructor(
    private device: Device,
    injector: Injector,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'CipherLabPlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    const manufacturer = this.platform.is('cordova') ? (this.device.manufacturer || '').toLowerCase() : '';
    return BrowserUtils.isDeviceApp() && manufacturer.indexOf('cipherlab') >= 0;
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on CipherLab device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.registerScannerCallback();

    return of(null);
  }

  private registerScannerCallback(): void {
    this.log('Registering to receive barcode/rfid scanner data...');
    (window as any).plugins.intentShim.unregisterBroadcastReceiver();
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        this.intentActionBarcode,
        this.intentActionRfid,
      ],
      filterCategories: [
        'android.intent.category.DEFAULT',
      ]},
      this.handleScannerIntent.bind(this)
    );
  }

  private handleScannerIntent(intent: any): void {
    intent.extras = intent.extras || {};
    LogUtils.log('CipherLab Intent: ', intent);
    // {\n  \"EPC_length\": 12,\n  \"ReadData\": \"\",\n  \"response\": 0,\n  \"PC\": \"3000\",\n  \"EPC\": \"e2801191a5030060f21a00f2\",\n  \"TID\": \"e2801191200060f290d00307\",\n  \"rssi\": -50.4,\n  \"type\": 0,\n  \"length1\": 12,\n  \"length2\": 12,\n  \"data1\": \"e2801191a5030060f21a00f2\",\n  \"data2\": \"e2801191200060f290d00307\",\n  \"ReadData_length\": 0,\n  \"TID_length\": 12\n },\n \"action\": \"com.cipherlab.rfidservice.SERVICE_TAG_DATA\",\n \"flags\": 16\n}"

    if (intent.action === this.intentActionBarcode) {
      if (!this.isBarcodeScannerEnabled) return;

      const value = intent.extras['Decoder_Data'];
      const valueType = intent.extras['Decoder_CodeType_String'];
      this.scannerService.emitScan({
        source: 'SCANNER',
        value: value,
        valueType: valueType,
      });
    } else if (intent.action === this.intentActionRfid && intent.extras['response'] === 0) {
      if (!this.isRfidScannerEnabled) return;

      const epc = intent.extras['EPC'] || intent.extras['data1'];
      const rssi = intent.extras['rssi'];
      const tid = intent.extras['TID'] || intent.extras['data2'];
      const value = intent.extras['data2'] || intent.extras['data1'];
      const valueType = intent.extras['type'];
      const now = Date.now();
      if (now - (this.rfidScanValueTickMap[value] || 0) <= 250) return; // ignore scans of the same tag that happen too close to one another

      this.rfidScanValueTickMap[value] = now;
      this.scannerService.emitScan({
        rssi: rssi,
        source: 'RFID',
        tagEpc: epc,
        tagId: tid,
        value: value,
        valueType: valueType,
      });
    }
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on CipherLab device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'enable_rfid':
        if (!this.isRfidScannerEnabled) {
          this.log('Enabling RFID scanner...');
          CipherLab.setRFIDMode(1);
        }
        /* Testing...
        if (!this.isRfidScannerEnabled) {
          setTimeout(() => {
            for (let i = 0; i < 500; i++) {
              setTimeout(() => {
                if (!this.isRfidScannerEnabled) return;

                const value = (~~(Math.random() * 200)).toString();
                const now = Date.now();
                if (now - (this.rfidScanValueTickMap[value] || 0) <= 250) return;

                this.rfidScanValueTickMap[value] = now;
                this.scannerService.emitScan({
                  source: 'RFID',
                  value: value,
                  valueType: '0',
                });
              }, i * 5);
            }
          }, 250);
        } */
        this.isRfidScannerEnabled = true;
        return of(null);
      case 'disable_rfid':
        if (this.isRfidScannerEnabled) this.log('Disabling RFID scanner...');
        this.isRfidScannerEnabled = false;
        return of(null);
      case 'enable_scanner':
        if (!this.isBarcodeScannerEnabled) this.log('Enabling barcode scanner...');
        this.isBarcodeScannerEnabled = true;
        return of(null);
      case 'disable_scanner':
        if (this.isBarcodeScannerEnabled) this.log('Disabling barcode scanner...');
        this.isBarcodeScannerEnabled = false;
        return of(null);
      case 'has_rfid':
        return of(true);
      case 'has_scanner':
        return of(true);
      case 'toggle_soft_scan':
        this.log('Toggle soft scanner state...');
        this.sendBroadcast('android.intent.action.FUNC_BUTTON');
        setTimeout(() => {
          this.sendBroadcast('android.intent.action.FUNC_RELEASE_BUTTON');
        }, 3 * 1000);
        return of(null);
      default:
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on CipherLab device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on CipherLab device...');
    }

    return of({
      enabled: this.isPluginAllowed(),
      isRfidScannerEnabled: this.isRfidScannerEnabled,
      log: Array.from(this.logRingBuffer),
    });
  }

  private sendBroadcast(action: string, extraName?: string, extraValue?: any,) {
    (window as any).plugins.intentShim.sendBroadcast({
      action: action,
      extras: extraName ? {
        [extraName]: extraValue,
        'SEND_RESULT': 'false'
      } : {
        'SEND_RESULT': 'false'
      }
    },
      () => { }, // Success in sending the intent, not success of DW to process the intent.
      () => { }, // Failure in sending the intent, not failure of DW to process the intent.
    );
  }

}
