import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { KeyboardComponent } from './components';


@NgModule({
  declarations: [
    KeyboardComponent,
  ],
  exports: [
    KeyboardComponent,
  ],
  imports: [
    SharedModule,
  ],
})
export class KeyboardModule { }
