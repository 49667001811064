import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { LogUtils } from '../../utils';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';
import { ClientAuthService } from './client-auth.service';
import { delay } from 'rxjs/operators';
import { HeartbeatService } from '../app/heartbeat.service';
import { RuntimeLayoutSnapshot } from '../../models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutChangeActiveScreen } from '../../models/memorypack/RuntimeLayoutChangeActiveScreen';
import { LayoutCoreMessage } from '../../models/memorypack/LayoutCoreMessage';
import { LayoutMessageType } from '../../models/memorypack/LayoutMessageType';
import { LayoutCriticalErrorMessage } from '../../models/memorypack/LayoutCriticalErrorMessage';
import { LayoutMessageResult } from '../../models/memorypack/LayoutMessageResult';
import { RuntimeLayoutEventSourceType } from '../../models/memorypack/RuntimeLayoutEventSourceType';


@Injectable({
  providedIn: 'root'
})
export class LayoutChangeActiveScreenService {

  private readonly timeoutInMs = 5 * 1000;

  constructor(
    private busyService: BusyService,
    private clientAuthService: ClientAuthService,
    private heartbeatService: HeartbeatService,
    private webSocketClientService: WebSocketClientService
  ) { }

  trigger(
    layoutSnapshot: RuntimeLayoutSnapshot,
    activeScreen: bigint,
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      this.busyService.setBusy(true, 'Sending event to server...');

      const msgContent = this.buildRuntimeLayoutChangeActiveScreen(
        layoutSnapshot,
        activeScreen,
      );
      LogUtils.log('Sending event to server:', msgContent);
      const msgContentBuffer = RuntimeLayoutChangeActiveScreen.serialize(msgContent);

      const coreMsg = Object.assign(new LayoutCoreMessage(), {
        messageOrigin: 0n,
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.LayoutChangeActiveScreen,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.serialize(coreMsg);

      const subscription = this.webSocketClientService
      .getMessages$(coreMsg.messageSequenceNr)
      .pipe(delay(10))
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      this.webSocketClientService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription
  ) {
    this.busyService.setBusy(false);
    if (!subscription || subscription.closed) return;

    if (msg?.messageType === LayoutMessageType.CriticalError) {
      const error = LayoutCriticalErrorMessage.deserialize(msg.messageContent.buffer);
      observer.error(error);
    } else {
      observer.next(msg?.messageResult === LayoutMessageResult.Success);
      observer.complete();
    }

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

  private buildRuntimeLayoutChangeActiveScreen(
    layoutSnapshot: RuntimeLayoutSnapshot,
    activeScreen: bigint,
  ): RuntimeLayoutChangeActiveScreen {
    let eventDateTime = Date.now();
    eventDateTime += (this.clientAuthService.deviceDateTimeMillisecondsDiff || 0);
    return Object.assign(new RuntimeLayoutChangeActiveScreen(), {
      snapshotTick: layoutSnapshot.snapshotTick,
      layoutScreenObjectId: activeScreen,
      layoutScreenObjectTick: layoutSnapshot.runtimeLayout.layoutScreens.get(activeScreen)?.tick,

      eventDateTime: (new Date(eventDateTime)).toISOString(),
    } as RuntimeLayoutChangeActiveScreen);
  }

}
