<ion-header>
  <lc-header
    [(layout)]="layout"
    [layoutSnapshot]="layoutSnapshot"
    (layoutChange)="refresh()">
  </lc-header>

  <lc-scanner-drawer *ngIf="showScannerDrawer"
    [layout]="layout"
    [scannerEnabledType]="layoutControl?.scannerEnabledType">
  </lc-scanner-drawer>
</ion-header>

<ion-content>
  <main>
    <div class="main-background">
      <lc-screen-full1 *ngIf="layoutScreen?.layoutScreenCode === FULL1"
        #screenComponent
        [layout]="layout"
        [layoutDesigns]="layout.layoutDesigns"
        [(layoutScreen)]="layoutScreen"
        (layoutScreenChange)="footer.refresh()"
        [layoutSnapshot]="layoutSnapshot"
        [layoutTexts]="layout.texts"
        [smartImages]="layout.smartImages"
        (triggerEvent)="onTriggerEvent($event)">
      </lc-screen-full1>

      <lc-screen-dual1 *ngIf="layoutScreen?.layoutScreenCode === DUAL1"
        #screenComponent
        [layout]="layout"
        [layoutDesigns]="layout.layoutDesigns"
        [(layoutScreen)]="layoutScreen"
        (layoutScreenChange)="footer.refresh()"
        [layoutSnapshot]="layoutSnapshot"
        [layoutTexts]="layout.texts"
        [smartImages]="layout.smartImages">
      </lc-screen-dual1>
    </div>
  </main>
</ion-content>

<ion-footer>
  <lc-keyboard
    id="keyboard-main"
    theme="dark"
    [hidden]="!layoutScreen">
  </lc-keyboard>

  <lc-footer #footer
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event, true)">
  </lc-footer>
</ion-footer>
