import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Notification, Settings } from 'src/app/shared/models';
import { AppService, AudioService, LocalSettingsService, NotificationService, PluginService, PluginType } from 'src/app/shared/services';
import { TranslateService } from '../../services/app';
import { BaseComponent } from '../base/base.component';
import { RuntimeLayoutNotifyType } from '../../models/runtime-layout/runtime-layout-notify-type.enum';


@Component({
  selector: 'lc-notify',
  templateUrl: 'notify.component.html',
  styleUrls: ['./notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyComponent extends BaseComponent {

  private readonly nonBlockingDefaultTimeout = 4 * 1000;

  notifications: Notification[];
  settings: Settings;
  showOverlay: boolean;

  useAssetTypeWav: boolean;

  constructor(
    private appService: AppService,
    private audioService: AudioService,
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private pluginService: PluginService,
    private translateService: TranslateService,
  ) {
    super();

    this.useAssetTypeWav = false;
    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    if (satoPlugin.isPluginAllowed())
    {
      this.useAssetTypeWav = true;
    }

    this.notifications = [];

    this.subscriptions.push(
      this.notificationService.getNotifications()
      .subscribe((notification: Notification) => {
        this.handleNotification(notification);
      })
    );
  }

  private handleNotification(notification: Notification) {
    if (!notification) return;

    if (this.appService.isAppInForeground()) {
      switch (parseInt(notification.type.toString())) {
        case RuntimeLayoutNotifyType.Alert:
          if (!this.useAssetTypeWav) {
            this.audioService.play('alert');
          } else {
            this.audioService.play('alertwav');
          }
          break;
        case RuntimeLayoutNotifyType.Confirmation:
          if (!this.useAssetTypeWav) {
            this.audioService.play('confirmation');
          } else {
            this.audioService.play('confirmationwav');
          }
          break;
        case RuntimeLayoutNotifyType.CriticalAlert:
          if (!this.useAssetTypeWav) {
            this.audioService.play('critical');
          } else {
            this.audioService.play('criticalwav');
          }
          break;
        case RuntimeLayoutNotifyType.VerificationAlert:
          if (!this.useAssetTypeWav) {
            this.audioService.play('verification');
          } else {
            this.audioService.play('verificationwav');
          }
          break;
        case RuntimeLayoutNotifyType.CriticalServerError:
          const settings = this.localSettingsService.get();
          if (!settings.runDeviceDebug) {
            notification.text = this.translateService.instant('Critical Server Error');
          }

          if (!this.useAssetTypeWav) {
            this.audioService.play('critical');
          } else {
            this.audioService.play('criticalwav');
          }
          break;
        default:
          if (!this.useAssetTypeWav) {
            this.audioService.play('notify');
          } else {
            this.audioService.play('notifywav');
          }
      }
    }

    if (notification.text) {
      this.ngZone.run(() => {
        this.notifications.push(notification);
        this.showOrHideBlockingOverlay();

        this.cdr.markForCheck();
      });
    }

    // if notification is NOT blocking....clear it after a specific timeout
    if (!notification.blocking) {
      let timeoutInMs = this.nonBlockingDefaultTimeout;
      if (notification.showTimeInSeconds) {
        timeoutInMs = notification.showTimeInSeconds * 1000;
      }
      setTimeout(() => {
        this.dismissNotification(notification);
      }, timeoutInMs);
    }
  }

  showOrHideBlockingOverlay() {
    this.showOverlay = this.notifications.some((notification: Notification) => {
      return notification.blocking;
    });
  }

  dismissNotification(notification: Notification): void {
    const index = this.notifications.findIndex((n: Notification) => {
      return n.id === notification.id;
    });
    if (index >= 0) {
      this.ngZone.run(() => {
        this.notifications.splice(index, 1);
        this.showOrHideBlockingOverlay();

        this.notificationService.dismissNotification(notification);

        this.cdr.markForCheck();
      });
    }
  }

}

