import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutDesignStyle } from "./RuntimeLayoutDesignStyle";

export class RuntimeLayoutDesign {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    designGuidId: string | null;
    name: string | null;
    originalDesignGuidId: string | null;
    designStyles: (RuntimeLayoutDesignStyle | null)[] | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.designGuidId = null;
        this.name = null;
        this.originalDesignGuidId = null;
        this.designStyles = null;

    }

    static serialize(value: RuntimeLayoutDesign | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutDesign | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(8);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.designGuidId);
        writer.writeString(value.name);
        writer.writeString(value.originalDesignGuidId);
        writer.writeArray(value.designStyles, (writer, x) => RuntimeLayoutDesignStyle.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutDesign | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutDesign | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutDesign.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutDesign | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutDesign | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutDesign();
        if (count == 8) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.designGuidId = reader.readString();
            value.name = reader.readString();
            value.originalDesignGuidId = reader.readString();
            value.designStyles = reader.readArray(reader => RuntimeLayoutDesignStyle.deserializeCore(reader));

        }
        else if (count > 8) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.designGuidId = reader.readString(); if (count == 5) return value;
            value.name = reader.readString(); if (count == 6) return value;
            value.originalDesignGuidId = reader.readString(); if (count == 7) return value;
            value.designStyles = reader.readArray(reader => RuntimeLayoutDesignStyle.deserializeCore(reader)); if (count == 8) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutDesign | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutDesign | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutDesign.deserializeCore(reader));
    }
}
