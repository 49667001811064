import { ungzip } from 'pako';
import encoding from 'text-encoding';
import { GuidUtils } from '../../utils/guid.utils';
import { DesignStyleJsonItem } from './design-style-json-item.model';

export class DesignStyleJson {

  static readonly latestVersion = 3;

  cols?: number; // deprecated with v2 (Gridster)
  rows?: number; // deprecated with v2 (Gridster)

  screenRows: number;
  style: Partial<CSSStyleDeclaration>;

  items: DesignStyleJsonItem[];
  properties: any;

  versionNumber: number;

  constructor(item?: any) {
    Object.assign(this, item);

    this.items = (item.items || []).filter((i: any) => {
      return i.field && i.field.webFieldValueType;
    }).map((i: any) => {
      return new DesignStyleJsonItem(i, this.versionNumber);
    });

    this.upgradeToLatestVersion(); // parse from whatever version and "upgrade" it to the latest
  }

  static parseDesignStyleJson(styleJson: string | Uint8Array | DesignStyleJson): DesignStyleJson {
    let obj = null;
    if (styleJson && typeof styleJson === 'string') {
      obj = JSON.parse(styleJson);
    } else if ((styleJson as Uint8Array).buffer) {
      const styleJsonUint8Array = ungzip(styleJson);
      const decoder = new encoding.TextDecoder('utf8');
      const json = decoder.decode(styleJsonUint8Array);
      obj = JSON.parse(json);
    } else if (styleJson) {
      obj = styleJson;
    }
    obj = obj ? new DesignStyleJson(obj) : null;
    if (!obj) return obj;

    obj.items = (obj.items || [])
    .map((i: any) => {
      if (i.field?.subVariableMemberGuidId) i.field.subVariableMemberGuidId = GuidUtils.clean(i.field.subVariableMemberGuidId);
      return i;
    });
    return obj;
  }

  private upgradeToLatestVersion() {
    if (this.versionNumber == null || this.versionNumber < 2) {
      delete this.cols; // we don't need this anymore with Gridster...
      delete this.rows; // we don't need this anymore with Gridster...
    } else if (this.versionNumber === 2) {
      // Gridster FTW!!!
    } else if (this.versionNumber === 3) {
    }

    this.versionNumber = DesignStyleJson.latestVersion;
  }

}
