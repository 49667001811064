import { ChangeDetectorRef, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DictNumber } from 'src/app/shared/models';
import { NotificationService } from 'src/app/shared/services';
import { AppService, TranslateService } from 'src/app/shared/services/app';
import { LogUtils } from 'src/app/shared/utils';
import { ScreenControlContainerComponent } from '../control-container/screen-control-container.component';
import { RuntimeLayoutScreen } from 'src/app/shared/models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutScreenControlCollection } from 'src/app/shared/models/memorypack/RuntimeLayoutScreenControlCollection';
import { RuntimeLayoutHeadPointer } from 'src/app/shared/models/memorypack/RuntimeLayoutHeadPointer';
import { RuntimeLayoutHead } from 'src/app/shared/models/memorypack/RuntimeLayoutHead';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutControlCode } from 'src/app/shared/models/runtime-layout/runtime-layout-control-code.enum';



@Component({
  template: '<div></div>'
})
export abstract class ScreenBaseComponent extends BaseComponent {

  @ViewChildren(ScreenControlContainerComponent) controlComponents: QueryList<ScreenControlContainerComponent>;

  @Input() set layoutScreen (value: RuntimeLayoutScreen) {
    this._layoutScreen = value;

    this.refresh();
  }
  get layoutScreen(): RuntimeLayoutScreen { return this._layoutScreen; }
  private _layoutScreen: RuntimeLayoutScreen;

  controls: any[];
  forceRefresh: boolean;

  constructor(
    protected appService: AppService,
    protected cdr: ChangeDetectorRef,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
  ) {
    super();
    this.controls = [];
  }

  protected refresh(): void {
    this.forceRefresh = this.controls?.[0]?.[0]?.layoutControlCode === RuntimeLayoutControlCode.InputControl1;

    this.controls = []; // controls is an array of arrays
    this.cdr.markForCheck();

    if (!this.layoutScreen) {
      this.controls.push([]);
      return;
    }

    if (this.forceRefresh) {
      setTimeout(() => {
        this.addControls();
      }, 10);
    } else {
      this.addControls();
    }
  }

  private addControls() {
    for (const sccKey of this.layoutScreen.screenControlCollections.keys() || []) {
      const screenControl = this.layoutScreen.screenControlCollections.get(sccKey);
      const screenControlControls = this.getControlsForScreenControlCollection(this.layoutScreen, screenControl);
      this.controls.push(screenControlControls);
    }

    this.cdr.markForCheck();
  }

  private getControlsForScreenControlCollection(layoutScreen: RuntimeLayoutScreen, screenControlCollection: RuntimeLayoutScreenControlCollection) {
    const controls = [];

    // head controls
    const heads = (screenControlCollection.headPointers || [])
    .map((hp: RuntimeLayoutHeadPointer) => {
      return layoutScreen.heads.get(hp.objectId);
    })
    .filter((h: RuntimeLayoutHead) => {
      return h;
    })
    .sort((a, b) => { return (a.headSequenceNr || 0) - (b.headSequenceNr || 0); });
    if (heads.length) {
      controls.push(...heads);
    }

    const acp = screenControlCollection.activeControlPointer;
    if (!acp) {
      this.handleErrorWithReconnect('Active control pointer is null.');
      return controls;
    }

    const primaryControl = layoutScreen.controls.get(layoutScreen.primaryLayoutControlObjectId);
    if (!primaryControl) {
      this.handleErrorWithReconnect(`Primary layout control (${layoutScreen.primaryLayoutControlObjectId}) is null.`);
      return controls;
    }

    controls.push(primaryControl);

    return controls;
  }

  private handleErrorWithReconnect(msg: string) {
    LogUtils.error(msg);

    setTimeout(() => {
      this.appService.initWsAndAuthenticate(this.appService.getDeviceEnrollment());
    }, 10);
  }

  abstract backButtonOverride(): boolean;

  abstract forwardButtonOverride(): boolean;

  abstract getControlsContext(): Map<bigint, RuntimeLayoutEventContext | null> | null;

  abstract preActionTrigger(): Observable<void>;

}
