import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { VibrationService } from 'src/app/shared/services';




@Component({
  selector: 'lc-action-popover',
  templateUrl: 'action.popover.html',
  styleUrls: ['./action.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionPopover {

  layoutControl: RuntimeLayoutControl;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    this.layoutControl = this.navParams.get('layoutControl');
  }

  actionClick(actionGuidId: string) {
    this.vibrationService.vibrate();

    this.popoverCtrl.dismiss(actionGuidId);
  }

}
