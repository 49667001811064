import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActionPopover } from './action.popover';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        ActionPopover,
    ]
})
export class ActionPopoverModule { }
