
export const environment = {
  name: 'zoey',
  production: true,
  apiUrl: 'https://leorun.logiccenter.com/', // RunManager Production

  clientRunEngineGuidId: 'ea06bf1d-32a0-4db8-8822-82f871cf2fb9',

  sentryDNS: 'https://ecac2265e3ad47d78606c181774af7fc@sentry.io/1399223',

  android: {
    vibratePattern: [50],
  },
  ios: {
    vibratePattern: 10,
  }
};
