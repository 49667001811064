import { NgModule } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { ListPipesModule } from 'src/app/shared/pipes/list/list-pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlRfidInventoryComponent } from './control-rfid-inventory.component';



@NgModule({
  declarations: [
    ControlRfidInventoryComponent,
  ],
  exports: [
    ControlRfidInventoryComponent,
  ],
  imports: [
    SharedModule,

    GridsterModule,
    ListPipesModule,
  ],
  providers: [],
})
export class ControlRfidInventoryModule { }
