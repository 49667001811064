import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DictNumber, DictString, EventObject } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';
import { ScreenBaseComponent } from '../base/screen-base.component';
import { ScreenControlContainerComponent } from '../control-container/screen-control-container.component';
import { Observable, of } from 'rxjs';
import { NotificationService, TranslateService } from 'src/app/shared/services/app';
import { RuntimeLayout } from 'src/app/shared/models/memorypack/RuntimeLayout';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutSnapshot } from 'src/app/shared/models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/memorypack/RuntimeLayoutSmartImage';
import { RuntimeLayoutScreen } from 'src/app/shared/models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';



@Component({
  selector: 'lc-screen-full1',
  templateUrl: 'screen-full1.component.html',
  styleUrls: ['./screen-full1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenFull1Component extends ScreenBaseComponent {

  @Input() layout: RuntimeLayout;
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutSnapshot: RuntimeLayoutSnapshot;
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;
  @Input() smartImages: RuntimeLayoutSmartImage[];
  @Output() layoutScreenChange = new EventEmitter<RuntimeLayoutScreen>();
  @Output() triggerEvent = new EventEmitter<EventObject>();

  isConnectionToServerActive: boolean;

  constructor(
    appService: AppService,
    cdr: ChangeDetectorRef,
    notificationService: NotificationService,
    translateService: TranslateService,
  ) {
    super(appService, cdr, notificationService, translateService);

    this.subscriptions.push(
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      })
    );
  }

  getControlsContext(): Map<bigint, RuntimeLayoutEventContext | null> | null {
    const result = new Map<bigint, RuntimeLayoutEventContext | null>();

    if (!this.controlComponents?.length) return result;

    this.controlComponents.forEach((controlComponent: ScreenControlContainerComponent) => {
      const controlContext = controlComponent.getControlContext();
      if (!controlContext) return;

      result.set(
        controlComponent.layoutControl.objectId,
        Object.assign(new RuntimeLayoutEventContext(), {
          values: controlContext
        })
      );
    });

    return result;
  }

  onTriggerEvent(platformObject: EventObject) {
    this.triggerEvent.emit(platformObject);
  }

  backButtonOverride(): boolean {
    if (!this.controlComponents?.length) return false;

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.backButtonOverride();
  }

  forwardButtonOverride(): boolean {
    if (!this.controlComponents?.length) return false;

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.forwardButtonOverride();
  }

  preActionTrigger(): Observable<void> {
    if (!this.controlComponents?.length) return of(null);

    const activeControlComponent = this.controlComponents?.last;
    return activeControlComponent?.preActionTrigger();
  }

}

