import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { from } from 'rxjs';
import { ChecklistMenuPopover } from 'src/app/popovers/checklist-menu/checklist-menu.popover';
import { ChecklistAnswerCheckResult } from 'src/app/shared/models/checklist/checklist-answer-check-result.model';
import { ChecklistAnswer } from 'src/app/shared/models/checklist/checklist-answer.model';
import { ChecklistCheckValue } from 'src/app/shared/models/checklist/checklist-check-value.model';
import { ChecklistCheck, ChecklistCheckAnswerType } from 'src/app/shared/models/checklist/checklist-check.model';
import { ChecklistGroup } from 'src/app/shared/models/checklist/checklist-group.model';
import { Checklist } from 'src/app/shared/models/checklist/checklist.model';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { LayoutResourceUploadService } from 'src/app/shared/services/protocol/layout-resource-upload.service';
import { CaseUtils, DateUtils } from 'src/app/shared/utils';
import { DictNumber, DictString, Notification } from '../../../models';
import { KeyboardService } from '../../keyboard';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlCamera1Component } from '../camera1/control-camera1.component';
import { ControlInput1Component, TextboxType } from '../input1/control-input1.component';
import { ControlSog1Component } from '../sog1/control-sog1.component';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models/runtime-layout/solution-device-control-scanner-enabled-type.enum';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';


@Component({
  selector: 'lc-control-checklist1',
  templateUrl: 'control-checklist1.component.html',
  styleUrls: ['./control-checklist1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlChecklist1Component extends ControlBaseComponent implements OnInit {

  @ViewChild('controlInputComponent', { static: false }) controlInputComponent: ControlInput1Component;
  @ViewChild('controlCameraComponent', { static: false }) controlCameraComponent: ControlCamera1Component;
  @ViewChild('controlSogComponent', { static: false }) controlSogComponent: ControlSog1Component;

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;

  activeGroup: ChecklistGroup;
  checklist: Checklist;
  checklistAnswer: ChecklistAnswer;
  originalBackButton: boolean;
  originalForwardButton: boolean;
  previousBackButton: boolean;
  previousForwardButton: boolean;
  selectedCheck: ChecklistCheck;
  isFreeTextControlVisible: boolean;
  isPictureControlVisible: boolean;
  isSignatureControlVisible: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private layoutResourceUploadService: LayoutResourceUploadService,
    private keyboardService: KeyboardService,
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.layoutControl || !this.layoutScreen) return;

    this.originalBackButton = this.layoutScreen.backButton;
    this.originalForwardButton = this.layoutScreen.forwardButton;

    this.checklist = this.parseChecklistRV(RuntimeLayoutUtils.parseRV(this.layoutControl, 'ChecklistJson'));
    this.checklistAnswer = new ChecklistAnswer({
      checklistResult: this.checklist.checklistResult,
      startDateTime: DateUtils.nowAsISOString(),
    });

    this.activeGroup = this.checklist.groups[0];

    this.layoutScreen.forwardButton = this.checklist.groups.length === 1 ? this.originalForwardButton : true;
    this.layoutScreenChange.emit(this.layoutScreen);

    this.cdr.markForCheck();
  }

  parseChecklistRV(checklistJson: string) {
    const checklist = new Checklist(CaseUtils.toCamel(JSON.parse(checklistJson)));
    if (checklist.checks?.length) {
      checklist.groups = [new ChecklistGroup({checks: checklist.checks})];
    }

    for (const group of checklist.groups || []) {
      for (const check of group.checks || []) {
        if (check.answerType === ChecklistCheckAnswerType.YesNo) {
          check.checkValues = [
            new ChecklistCheckValue({ value: this.translateService.instant('Yes') }),
            new ChecklistCheckValue({ value: this.translateService.instant('No') }),
          ];
        }

        if (check.defaultValue) {
          check.$checkResult = check.checkValues.filter(x => x.value === check.defaultValue);
        }
      }
    }

    console.log(checklist);
    return checklist;
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    this.checklistAnswer.endDateTime = DateUtils.nowAsISOString();
    this.checklistAnswer.confirmedFreeText = this.checklist.$freeText;
    this.checklistAnswer.signatureResourceGuidId = this.checklist.$signatureResourceGuidId;
    this.checklistAnswer.checkResults = [];
    for (const group of this.checklist.groups || []) {
      for (const check of group.checks || []) {
        this.checklistAnswer.checkResults.push(
          this.buildCheckResultFromChecklistCheck(check)
        );
      }
    }
    console.log(this.checklistAnswer)

    context.set('ChecklistAnswer', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(JSON.stringify(this.checklistAnswer)),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    return context;
  }

  private buildCheckResultFromChecklistCheck(checklistCheck: ChecklistCheck) {
    return {
      answered: !!checklistCheck.$answeredDateTime,
      answeredDateTime: checklistCheck.$answeredDateTime,
      checklistCheckGuidId: checklistCheck.guidId,
      freeText: checklistCheck.$freeText,
      pictureResourceGuidId: checklistCheck.$pictureResourceGuidId,

      isMultiValue: checklistCheck.answerType === ChecklistCheckAnswerType.MultiValues,
      value: checklistCheck.$checkResult?.length && checklistCheck.answerType === ChecklistCheckAnswerType.MultiValues ? checklistCheck.$checkResult[0].value : undefined,
      checkValueGuidId: checklistCheck.$checkResult?.length && checklistCheck.answerType === ChecklistCheckAnswerType.MultiValues ? checklistCheck.$checkResult[0].checklistCheckGuidId : undefined,
      multiValues: checklistCheck.$checkResult?.length && checklistCheck.answerType === ChecklistCheckAnswerType.MultiValues ? checklistCheck.$checkResult.map(x => x.value) : undefined,
      multiCheckValueGuidId: checklistCheck.$checkResult?.length && checklistCheck.answerType === ChecklistCheckAnswerType.MultiValues ? checklistCheck.$checkResult.map(x => x.checklistCheckGuidId) : undefined,
    } as ChecklistAnswerCheckResult;
  }

  backButtonOverride(): boolean {
    if (this.isFreeTextControlVisible || this.isPictureControlVisible || this.isSignatureControlVisible) {
      if (this.isSignatureControlVisible && this.checklist.allowFreeTextAtConfirm) setTimeout(() => { this.showFreeTextControl(); }, 10);
      this.hideExtraControls();

      return true;
    }

    const groupIndex = this.checklist.groups.findIndex(group => group === this.activeGroup);
    if (groupIndex > 0) {
      this.activeGroup = this.checklist.groups[groupIndex - 1];

      this.layoutScreen.backButton = groupIndex === 1 ? this.originalBackButton : true;
      this.layoutScreen.forwardButton = this.originalForwardButton;
      this.layoutScreenChange.emit(this.layoutScreen);
      this.cdr.markForCheck();
      return true;
    }

    return false;
  }

  forwardButtonOverride(): boolean {
    if (this.checklist?.$completed) return false;

    if (this.isFreeTextControlVisible) {
      const freeText = RuntimeLayoutUtils.parseLayoutValue(this.controlInputComponent.getControlContext()?.get('TextBox'));
      if (this.selectedCheck) {
        this.selectedCheck.$freeText = freeText;
        this.hideExtraControls();
        return true;
      }

      this.checklist.$freeText = freeText;
      if (this.checklist.confirmWithSignature) {
        this.hideExtraControls();
        this.showSignatureControl();
        return true;
      }

      this.checklist.$completed = true;
      return false;
    }

    if (this.isPictureControlVisible) {
      this.selectedCheck.$pictureB64 = this.controlCameraComponent.b64Image;

      if (this.selectedCheck.$pictureB64) {
        const b64 = this.selectedCheck.$pictureB64.split(';base64,')[1];
        const mimeType = (this.selectedCheck.$pictureB64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];
        const selectedCheck = this.selectedCheck;
        this.layoutResourceUploadService.upload(
          mimeType,
          this.convertB64ToBinary(b64),
        ).subscribe((resourceGuidId: string) => {
          selectedCheck.$pictureResourceGuidId = resourceGuidId;
          this.cdr.markForCheck();
        });
      }

      this.hideExtraControls();
      return true;
    }

    if (this.isSignatureControlVisible) {
      this.checklist.$signatureB64 = this.controlSogComponent.b64Image;

      if (this.checklist.$signatureB64) {
        const b64 = this.checklist.$signatureB64.split(';base64,')[1];
        const mimeType = (this.checklist.$signatureB64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];
        this.layoutResourceUploadService.upload(
          mimeType,
          this.convertB64ToBinary(b64),
        ).subscribe((resourceGuidId: string) => {
          this.checklist.$signatureResourceGuidId = resourceGuidId;

          this.checklist.$completed = true;
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
        });
      }

      return true;
    }

    if (this.activeGroup.checks.some(x => x.required && x.$checkResult === undefined)) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Verification'),
        text: this.translateService.instant('Some required checks are missing.'),
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
      return true;
    }

    const groupIndex = this.checklist.groups.findIndex(group => group === this.activeGroup);
    if (groupIndex < this.checklist.groups.length - 1) {
      this.activeGroup = this.checklist.groups[groupIndex + 1];

      this.layoutScreen.backButton = true;
      this.layoutScreen.forwardButton = groupIndex === this.checklist.groups.length - 1 ? this.originalForwardButton : true;
      this.layoutScreenChange.emit(this.layoutScreen);
      this.cdr.markForCheck();
      return true;
    } else if (this.checklist.allowFreeTextAtConfirm) {
      this.showFreeTextControl();
      return true;
    } else if (this.checklist.confirmWithSignature) {
      this.showSignatureControl();
      return true;
    }

    // if we passed all other checks, we are finishing the checklist w/out any additional freeText or signature.
    // but before finish, we need to make sure all image resources were propertly uploaded
    let allPicturesFinishedUploading = true;
    for (const group of this.checklist.groups || []) {
      for (const check of group.checks || []) {
        allPicturesFinishedUploading = allPicturesFinishedUploading && (!check.$pictureB64 || !!check.$pictureResourceGuidId);
      }
    }
    if (!allPicturesFinishedUploading) {
      // NOTE: don't think this ever happens, because uploading the resources blocks the UI while uploading...but better be safe
      console.log('Pictures still uploading...');
      setTimeout(() => {
        this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
      }, 100);
      return true;
    }

    return false;
  }

  private hideExtraControls() {
    this.selectedCheck = undefined;
    this.isFreeTextControlVisible = false;
    this.isPictureControlVisible = false;
    this.isSignatureControlVisible = false;
    this.keyboardService.hide();
    this.layoutScreen.backButton = this.previousBackButton;
    this.layoutScreen.forwardButton = this.previousForwardButton;
    this.layoutScreen.renderValues.set(
      'ButtonsType',
      Object.assign(new RuntimeLayoutValue(), { valueJson: '0', valueTypeId: RuntimeLayoutValueType.Int })
    );
    this.layoutScreenChange.emit(this.layoutScreen);
  }

  answerCheck(check: ChecklistCheck, checkValue: ChecklistCheckValue) {
    if (checkValue === null) { // null = N/A
      check.$answeredDateTime = DateUtils.nowAsISOString();
      check.$checkResult = null;
      check.$collapsed = true;
      return;
    }

    if (check.answerType === ChecklistCheckAnswerType.MultiValues) {
      const existingIndexOf = (check.$checkResult || []).indexOf(checkValue);
      if (existingIndexOf >= 0) {
        check.$answeredDateTime = undefined;
        check.$checkResult.splice(existingIndexOf, 1);
      } else {
        check.$answeredDateTime = DateUtils.nowAsISOString();
        check.$checkResult = check.$checkResult || [];
        check.$checkResult.push(checkValue);
      }
    } else {
      if (check.$checkResult?.[0] === checkValue) {
        check.$answeredDateTime = undefined;
        check.$checkResult = [];
      } else {
        check.$answeredDateTime = DateUtils.nowAsISOString();
        check.$checkResult = [checkValue];
        check.$collapsed = true;
      }
    }
  }

  showMenuPopover(ev: Event, check: ChecklistCheck) {
    ev.stopPropagation();

    this.vibrationService.vibrate();
    from(this.popoverCtrl.create({
      component: ChecklistMenuPopover,
      componentProps: {
        check: check,
      },
      cssClass: `popover-checklist-menu`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        const action = result.data;
        switch(action) {
          case 'description':
            this.showCheckDescription(check);
            return;
          case 'free-text':
            this.showFreeTextControl(check);
            return;
          case 'picture':
            this.showPictureControl(check);
            return;
        }
      });

      popover.present();
    });
  }

  showCheckDescription(check: ChecklistCheck) {
    this.notificationService.showAlert(
      this.translateService.instant('Description'),
      check.description,
    ).subscribe();
  }

  showFreeTextControl(check?: ChecklistCheck) {
    this.staticControl = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Free Text'),
      defaultTextBoxValue: (check || this.checklist).$freeText || '',
      keyboardAutoEnable: true,
      keyboardType: KeyboardType.AlphaNumericWithEnter,
      scannerEnabledType: SolutionDeviceControlScannerEnabledFlagType.NotActive,
      textboxType: TextboxType.Multiline,
    };

    this.selectedCheck = check;
    this.isFreeTextControlVisible = true;

    this.previousBackButton = this.layoutScreen.backButton;
    this.previousForwardButton = this.layoutScreen.forwardButton;
    this.layoutScreen.backButton = true;
    this.layoutScreen.forwardButton = true;
    if (check) this.layoutScreen.renderValues.set('ButtonsType', Object.assign(new RuntimeLayoutValue(), { valueJson: '1', valueTypeId: RuntimeLayoutValueType.Int }));
    this.layoutScreenChange.emit(this.layoutScreen);
    this.cdr.markForCheck();
  }

  showPictureControl(check: ChecklistCheck) {
    this.staticControl = {
      autoOpen: !check.$pictureB64,
      b64Image: check.$pictureB64,
      captureImageSize: 0, // CameraDeviceControlCaptureImageSize.MegaPixel1,
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Picture'),
      skipReview: true,
    };

    this.selectedCheck = check;
    this.isPictureControlVisible = true;

    this.previousBackButton = this.layoutScreen.backButton;
    this.previousForwardButton = this.layoutScreen.forwardButton;
    this.layoutScreen.backButton = true;
    this.layoutScreen.forwardButton = true;
    this.layoutScreen.renderValues.set('ButtonsType', Object.assign(new RuntimeLayoutValue(), { valueJson: '1', valueTypeId: RuntimeLayoutValueType.Int }));
    this.layoutScreenChange.emit(this.layoutScreen);
    this.cdr.markForCheck();
  }

  showSignatureControl() {
    this.staticControl = {
      b64Image: this.checklist.$signatureB64,
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Client Signature'),
    };

    this.isSignatureControlVisible = true;

    this.previousBackButton = this.layoutScreen.backButton;
    this.previousForwardButton = this.layoutScreen.forwardButton;
    this.layoutScreen.backButton = true;
    this.layoutScreen.forwardButton = this.originalForwardButton;
    this.layoutScreenChange.emit(this.layoutScreen);
    this.cdr.markForCheck();
  }

  private convertB64ToBinary(b64: string): Uint8Array {
    var raw = window.atob(b64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Uint8Array(array);
  }
}

