import { NgModule } from '@angular/core';
import { GetDesignStylePipe } from './get-design-style.pipe';
import { GetItemDataPipe } from './get-item-data.pipe';
import { GetItemFieldValuePipe } from './get-item-field-value.pipe';
import { ParseDesignStyleJsonPipe } from './parse-design-style-json.pipe';




@NgModule({
  declarations: [
    GetDesignStylePipe,
    GetItemDataPipe,
    GetItemFieldValuePipe,
    ParseDesignStyleJsonPipe,
  ],
  exports: [
    GetDesignStylePipe,
    GetItemDataPipe,
    GetItemFieldValuePipe,
    ParseDesignStyleJsonPipe,
  ],
  imports: [
  ],
})
export class ListPipesModule { }
