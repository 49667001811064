import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DictNumber } from 'src/app/shared/models';
import { AppService, NotificationService } from 'src/app/shared/services';
import { ScreenBaseComponent } from '../base/screen-base.component';
import { Observable, of } from 'rxjs';
import { TranslateService } from 'src/app/shared/services/app';
import { RuntimeLayout } from 'src/app/shared/models/memorypack/RuntimeLayout';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutSnapshot } from 'src/app/shared/models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/memorypack/RuntimeLayoutSmartImage';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';



@Component({
  selector: 'lc-screen-dual1',
  templateUrl: 'screen-dual1.component.html',
  styleUrls: ['./screen-dual1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenDual1Component extends ScreenBaseComponent {

  @Input() layout: RuntimeLayout;
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutSnapshot: RuntimeLayoutSnapshot;
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;
  @Input() smartImages: RuntimeLayoutSmartImage[];

  isConnectionToServerActive: boolean;

  constructor(
    appService: AppService,
    cdr: ChangeDetectorRef,
    notificationService: NotificationService,
    translateService: TranslateService,
  ) {
    super(appService, cdr, notificationService, translateService);

    this.subscriptions.push(
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      })
    );
  }

  preActionTrigger(): Observable<void> {
    return of(null);
  }

  getControlsContext(): Map<bigint, RuntimeLayoutEventContext | null> | null {
    return null;
  }

  backButtonOverride(): boolean {
    return false;
  }

  forwardButtonOverride(): boolean {
    return false;
  }

}

