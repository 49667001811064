import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


export interface Busy {
  show: boolean;
  text?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  private readonly delayInMS = 500;

  private busySubject: BehaviorSubject<Busy>;
  private startTime: number;
  private timeoutHandle: any;
  private lastText: string;

  constructor() {
  }

  listenToBusyChanges(defaultValue?: Busy): Observable<Busy> {
    this.busySubject = new BehaviorSubject(defaultValue || { show: false });
    return this.busySubject as Observable<Busy>;
  }

  setBusy(show: boolean, text?: string) {
    if (!show) {
      this.clearTimeout();
      this.busySubject.next({
        show: false
      });
      return;
    }

    if (!this.startTime) { // if first time, wait 500ms to show busy
      this.startTime = performance.now();
      this.lastText = text;
      this.timeoutHandle = setTimeout(() => {
        // since the text could have been updated in the meantime, use the last text set
        const lastText = this.lastText || '';
        this.clearTimeout();
        this.busySubject.next({
          show: true,
          text: lastText,
        });
      }, this.delayInMS);
    } else {
      // this was another setBusy(true) with a potential text update
      this.lastText = text;
      // if the busy animation is already being shown, emit a new busy event
      // otherwise, just update the lastText which will be displayed once the delay timeout occurs
      if (performance.now() - this.startTime > this.delayInMS) {
        this.busySubject.next({
          show: true,
          text: this.lastText || ''
        });
      }
    }
  }

  private clearTimeout() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = 0;
    }
    this.startTime = undefined;
    this.lastText = '';
  }

}
