import { Injectable } from '@angular/core';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { ClientRunEngineType } from 'src/app/shared/models/protocol/system/client-run-engine-type.enum';
import { environment } from 'src/environments/environment';
import { AuthResponse, DeviceRunStatus } from '../../models';
import { LayoutClientAuthenticationMessage } from '../../models/memorypack/LayoutClientAuthenticationMessage';
import { LayoutCoreMessage } from '../../models/memorypack/LayoutCoreMessage';
import { BrowserUtils, LogUtils } from '../../utils';
import { DateUtils } from '../../utils/date-utils';
import { TranslateService } from '../app';
import { HeartbeatService } from '../app/heartbeat.service';
import { NetworkService } from '../app/network.service';
import { StorageService } from '../app/storage.service';
import { BusyService } from '../busy/busy.service';
import { LocalSettingsService } from '../local-settings/local-settings.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';
import { WebSocketDataService } from '../web-socket/web-socket-data.service';
import { LayoutClientAuthenticationResponseMessage } from '../../models/memorypack/LayoutClientAuthenticationResponseMessage';
import { LayoutMessageResult } from '../../models/memorypack/LayoutMessageResult';
import { LayoutMessageType } from '../../models/memorypack/LayoutMessageType';
import { DeviceEnrollment3 } from '../../models/memorypack/DeviceEnrollment3';
const { version } = require('../../../../../package.json');


@Injectable({
  providedIn: 'root'
})
export class ClientAuthService {

  private readonly storageKeyDateTimeDiff = 'lc_deviceDateTimeMillisecondsDiff';
  private readonly storageKeyDeviceId = 'lc_deviceId';
  private readonly timeoutInMs = 5 * 1000;

  deviceDateTimeMillisecondsDiff: number;

  deviceId: string;
  private deviceIdSubject = new Subject<string>();

  constructor(
    private busyService: BusyService,
    private heartbeatService: HeartbeatService,
    private localSettingsService: LocalSettingsService,
    private networkService: NetworkService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private webSocketClientService: WebSocketClientService,
    private webSocketDataService: WebSocketDataService,
  ) {
    this.storageService.get(this.storageKeyDateTimeDiff)
      .subscribe((diff: number) => {
        this.deviceDateTimeMillisecondsDiff = diff;
      });
    this.storageService.get(this.storageKeyDeviceId)
      .subscribe((deviceId: string) => {
        this.deviceId = deviceId;
      });
  }

  deviceIdChanges(): Observable<string> {
    return this.deviceIdSubject.asObservable();
  }

  auth(
    languageGuidId: string,
    de: DeviceEnrollment3,
    dataSocket: boolean,
    deviceReload?: boolean,
    fromTick?: bigint,
    newEnrollmentGuidId?: string,
    sourceEnrollmentGuidId?: string,
  ): Observable<AuthResponse> {
    return new Observable((observer: Observer<any>) => {
      if (!dataSocket) this.busyService.setBusy(true, this.translateService.instant('Authenticating...'));

      const webSocketService = dataSocket ? this.webSocketDataService : this.webSocketClientService;

      LogUtils.log('Authenticating ' + (dataSocket ? 'Data' : 'Client') + '...');
      if (!dataSocket) this.networkService.logNetworkAndSystemStatus();

      const msgContent = Object.assign(new LayoutClientAuthenticationMessage(), {
        // messageType: LayoutMessageType.ClientAuthentication,
        enrollmentGuidId: newEnrollmentGuidId || de.enrollmentGuidId,
        fromTick: fromTick || 0n,
        noSnapshot: false,
        clientVersion: version,
        deviceDateTime: DateUtils.nowAsISOString(),
        runDeviceDebug: this.localSettingsService.get().runDeviceDebug,
        sourceEnrollmentGuidId: sourceEnrollmentGuidId,
        enrollmentEnvironmentGuidId: de.environmentGuidId,
        runCodeToken: de.$runCode,
        deviceSolutionSetGuidId: de.deviceSolutionSetGuidId,
        deviceSolutionSetTick: BigInt(de.deviceSolutionSetSysVersion),
        deviceSolutionGuidId: de.deviceSolutionGuidId,
        deviceSolutionTick: BigInt(de.deviceSolutionSysVersion),
        deviceEngineGuidId: environment.clientRunEngineGuidId,
        deviceReload: deviceReload,
        clientRunEngineType: BrowserUtils.isDeviceApp() ? ClientRunEngineType.App : ClientRunEngineType.Web,
        layoutClientDataType: (dataSocket ? 'Data' : undefined),
      } as LayoutClientAuthenticationMessage);
      console.log(msgContent);
      const msgContentBuffer = LayoutClientAuthenticationMessage.serialize(msgContent);

      const coreMsg = new LayoutCoreMessage();
      Object.assign(coreMsg, {
        messageOrigin: 0n,
        messageSequenceNr: webSocketService.getSequenceNumber(),
        messageType: LayoutMessageType.ClientAuthentication,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer = LayoutCoreMessage.serialize(coreMsg);

      const subscription = webSocketService
      .getMessages$(coreMsg.messageSequenceNr)
      .subscribe((msg: LayoutCoreMessage) => {
        this.handleIncomingMessage(msg, observer, subscription, dataSocket);
      }, (error: any) => {
        this.handleError(subscription, observer, error);
      });

      this.heartbeatService.scheduleNextHeartbeat(this.timeoutInMs);
      webSocketService.send(coreMsgBuffer);
    });
  }

  private handleIncomingMessage(
    msg: LayoutCoreMessage, observer: Observer<any>, subscription: Subscription, dataSocket: boolean
  ) {
    this.busyService.setBusy(false);
    if (!subscription || subscription.closed) return;

    if (!msg || msg.messageResult === LayoutMessageResult.Error) {
      observer.error(new Error(msg.errorMessage || this.translateService.instant('Unknown error')));

      subscription.unsubscribe();
      subscription = null;
      return;
    }

    const carp = LayoutClientAuthenticationResponseMessage.deserialize(msg.messageContent.buffer);
    if (!carp) {
      observer.error(new Error(this.translateService.instant('Empty authentication response message')));

      subscription.unsubscribe();
      subscription = null;
      return;
    }

    if (!dataSocket) {
      this.deviceDateTimeMillisecondsDiff = ~~(carp.deviceDateTimeMillisecondsDiff || 0);
      LogUtils.log(`Client / Server Time difference: ${this.deviceDateTimeMillisecondsDiff}ms`);
      this.storageService.set(this.storageKeyDateTimeDiff, this.deviceDateTimeMillisecondsDiff).subscribe();

      this.deviceId = carp.deviceId;
      this.storageService.set(this.storageKeyDeviceId, this.deviceId).subscribe();
      LogUtils.log(`DeviceId: ${this.deviceId || 'N/A'}`);
      this.deviceIdSubject.next(this.deviceId);
    }

    if (carp.notAllowedClientVersion) {
      // TODO: NEED TO HANDLE THIS BETTER! OR MAYBE NOT BEING USED AT ALL...
      // if BROWSER, show popup for the user to hit CTRL + F5
      // if APP, tell the user he needs to install the latest version
      LogUtils.error('CLIENT VERSION NOT ALLOWED');
    }

    if ((carp.status || DeviceRunStatus.Alive) !== DeviceRunStatus.Alive) {
      carp.readOnly = true;
    }

    observer.next({
      result: msg.messageResult === LayoutMessageResult.Success,
      readOnly: carp.readOnly,
      deviceRunStatus: carp.status,
    });
    observer.complete();

    subscription.unsubscribe();
    subscription = null;
  }

  private handleError(subscription: Subscription, observer: Observer<any>, error: any) {
    if (!subscription || subscription.closed) return;

    this.busyService.setBusy(false);

    subscription.unsubscribe();
    subscription = null;

    observer.error(error);
  }

}
