import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutScreen } from "./RuntimeLayoutScreen";
import { RuntimeLayoutObjectPointer } from "./RuntimeLayoutObjectPointer";
import { RuntimeLayoutDesign } from "./RuntimeLayoutDesign";
import { RuntimeLayoutData } from "./RuntimeLayoutData";
import { RuntimeLayoutText } from "./RuntimeLayoutText";
import { RuntimeLayoutSmartImage } from "./RuntimeLayoutSmartImage";
import { RuntimeLayoutDeviceLicense } from "./RuntimeLayoutDeviceLicense";

export class RuntimeLayout {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    solutionLayoutGuidId: string | null;
    startedTick: bigint;
    activeScreen: bigint;
    layoutScreens: Map<bigint, RuntimeLayoutScreen | null> | null;
    removedLayoutScreens: (RuntimeLayoutObjectPointer | null)[] | null;
    layoutDesigns: (RuntimeLayoutDesign | null)[] | null;
    datas: Map<bigint, RuntimeLayoutData | null> | null;
    removedDatas: (RuntimeLayoutObjectPointer | null)[] | null;
    texts: Map<number, RuntimeLayoutText | null> | null;
    runtimeSolutionStartedTick: bigint;
    menuSettings: number;
    uIAttributes: number;
    smartImages: (RuntimeLayoutSmartImage | null)[] | null;
    deviceLocation: boolean;
    deviceLocationScheduler: string | null;
    deviceLicenses: (RuntimeLayoutDeviceLicense | null)[] | null;
    privacyLevel: number;
    privacyLevelTimeoutDateTime: string | null;
    defaultKeyboard: number;
    useLanguageKeyboard: boolean;
    customSymbologies: boolean;
    customSymbologiesJson: string | null;
    hybridSupport: boolean;
    hybridMode: number;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.solutionLayoutGuidId = null;
        this.startedTick = 0n;
        this.activeScreen = 0n;
        this.layoutScreens = null;
        this.removedLayoutScreens = null;
        this.layoutDesigns = null;
        this.datas = null;
        this.removedDatas = null;
        this.texts = null;
        this.runtimeSolutionStartedTick = 0n;
        this.menuSettings = 0;
        this.uIAttributes = 0;
        this.smartImages = null;
        this.deviceLocation = false;
        this.deviceLocationScheduler = null;
        this.deviceLicenses = null;
        this.privacyLevel = 0;
        this.privacyLevelTimeoutDateTime = null;
        this.defaultKeyboard = 0;
        this.useLanguageKeyboard = false;
        this.customSymbologies = false;
        this.customSymbologiesJson = null;
        this.hybridSupport = false;
        this.hybridMode = 0;

    }

    static serialize(value: RuntimeLayout | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayout | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(28);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.solutionLayoutGuidId);
        writer.writeInt64(value.startedTick);
        writer.writeInt64(value.activeScreen);
        writer.writeMap(value.layoutScreens, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutScreen.serializeCore(writer, x));
        writer.writeArray(value.removedLayoutScreens, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeArray(value.layoutDesigns, (writer, x) => RuntimeLayoutDesign.serializeCore(writer, x));
        writer.writeMap(value.datas, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutData.serializeCore(writer, x));
        writer.writeArray(value.removedDatas, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeMap(value.texts, (writer, x) => writer.writeInt32(x), (writer, x) => RuntimeLayoutText.serializeCore(writer, x));
        writer.writeInt64(value.runtimeSolutionStartedTick);
        writer.writeInt32(value.menuSettings);
        writer.writeInt32(value.uIAttributes);
        writer.writeArray(value.smartImages, (writer, x) => RuntimeLayoutSmartImage.serializeCore(writer, x));
        writer.writeBoolean(value.deviceLocation);
        writer.writeString(value.deviceLocationScheduler);
        writer.writeArray(value.deviceLicenses, (writer, x) => RuntimeLayoutDeviceLicense.serializeCore(writer, x));
        writer.writeInt32(value.privacyLevel);
        writer.writeString(value.privacyLevelTimeoutDateTime);
        writer.writeInt32(value.defaultKeyboard);
        writer.writeBoolean(value.useLanguageKeyboard);
        writer.writeBoolean(value.customSymbologies);
        writer.writeString(value.customSymbologiesJson);
        writer.writeBoolean(value.hybridSupport);
        writer.writeInt32(value.hybridMode);

    }

    static serializeArray(value: (RuntimeLayout | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayout | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayout.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayout | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayout | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayout();
        if (count == 28) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.solutionLayoutGuidId = reader.readString();
            value.startedTick = reader.readInt64();
            value.activeScreen = reader.readInt64();
            value.layoutScreens = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutScreen.deserializeCore(reader));
            value.removedLayoutScreens = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.layoutDesigns = reader.readArray(reader => RuntimeLayoutDesign.deserializeCore(reader));
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutData.deserializeCore(reader));
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.texts = reader.readMap(reader => reader.readInt32(), reader => RuntimeLayoutText.deserializeCore(reader));
            value.runtimeSolutionStartedTick = reader.readInt64();
            value.menuSettings = reader.readInt32();
            value.uIAttributes = reader.readInt32();
            value.smartImages = reader.readArray(reader => RuntimeLayoutSmartImage.deserializeCore(reader));
            value.deviceLocation = reader.readBoolean();
            value.deviceLocationScheduler = reader.readString();
            value.deviceLicenses = reader.readArray(reader => RuntimeLayoutDeviceLicense.deserializeCore(reader));
            value.privacyLevel = reader.readInt32();
            value.privacyLevelTimeoutDateTime = reader.readString();
            value.defaultKeyboard = reader.readInt32();
            value.useLanguageKeyboard = reader.readBoolean();
            value.customSymbologies = reader.readBoolean();
            value.customSymbologiesJson = reader.readString();
            value.hybridSupport = reader.readBoolean();
            value.hybridMode = reader.readInt32();

        }
        else if (count > 28) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.solutionLayoutGuidId = reader.readString(); if (count == 5) return value;
            value.startedTick = reader.readInt64(); if (count == 6) return value;
            value.activeScreen = reader.readInt64(); if (count == 7) return value;
            value.layoutScreens = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutScreen.deserializeCore(reader)); if (count == 8) return value;
            value.removedLayoutScreens = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 9) return value;
            value.layoutDesigns = reader.readArray(reader => RuntimeLayoutDesign.deserializeCore(reader)); if (count == 10) return value;
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutData.deserializeCore(reader)); if (count == 11) return value;
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 12) return value;
            value.texts = reader.readMap(reader => reader.readInt32(), reader => RuntimeLayoutText.deserializeCore(reader)); if (count == 13) return value;
            value.runtimeSolutionStartedTick = reader.readInt64(); if (count == 14) return value;
            value.menuSettings = reader.readInt32(); if (count == 15) return value;
            value.uIAttributes = reader.readInt32(); if (count == 16) return value;
            value.smartImages = reader.readArray(reader => RuntimeLayoutSmartImage.deserializeCore(reader)); if (count == 17) return value;
            value.deviceLocation = reader.readBoolean(); if (count == 18) return value;
            value.deviceLocationScheduler = reader.readString(); if (count == 19) return value;
            value.deviceLicenses = reader.readArray(reader => RuntimeLayoutDeviceLicense.deserializeCore(reader)); if (count == 20) return value;
            value.privacyLevel = reader.readInt32(); if (count == 21) return value;
            value.privacyLevelTimeoutDateTime = reader.readString(); if (count == 22) return value;
            value.defaultKeyboard = reader.readInt32(); if (count == 23) return value;
            value.useLanguageKeyboard = reader.readBoolean(); if (count == 24) return value;
            value.customSymbologies = reader.readBoolean(); if (count == 25) return value;
            value.customSymbologiesJson = reader.readString(); if (count == 26) return value;
            value.hybridSupport = reader.readBoolean(); if (count == 27) return value;
            value.hybridMode = reader.readInt32(); if (count == 28) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayout | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayout | null)[] | null {
        return reader.readArray(reader => RuntimeLayout.deserializeCore(reader));
    }
}
