import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DictString } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import * as swishQrcodeSvg from 'swish-qrcode-svg';
import { from } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';

@Component({
  selector: 'lc-control-swish-qr1',
  templateUrl: 'control-swish-qr1.component.html',
  styleUrls: ['./control-swish-qr1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSwishQr1Component extends ControlBaseComponent implements OnInit {

  theme: string;

  swishAmount: number;
  swishAmountCurrency: string;
  swishMessage: string;
  swishNumber: string;
  swishBase64: string;
  swishSvg: SafeHtml;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.swishAmount = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Amount');
    this.swishAmountCurrency = RuntimeLayoutUtils.parseRV(this.layoutControl, 'AmountCurrency', '');
    this.swishMessage = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Message', '');
    const messageEditable = RuntimeLayoutUtils.parseRV(this.layoutControl, 'MessageEditable', false);
    this.swishNumber = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SwishNumber', '');

    const barcodePreRendered = RuntimeLayoutUtils.parseRV(this.layoutControl, 'BarcodePreRendered');
    if (barcodePreRendered) {
      this.swishBase64 = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SwishBase64');
    } else {
      const swishString = this.generateString({
        amount: this.swishAmount,
        lock: ['amount', 'number', !messageEditable ? 'message' : undefined].filter(x => x),
        message: this.swishMessage,
        number: this.swishNumber,
      });

      this.swishSvg = this.domSanitizer.bypassSecurityTrustHtml(
        swishQrcodeSvg(
          swishString,
          { backgroundColor: 'white', margin: 4, size: undefined },
        )
      );
      this.cdr.markForCheck();
    }
  }

  forwardButtonOverride(): boolean {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify('Payed'),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
    return true;
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  private generateString(options: any) {
    options = Object.assign({
      amount: 0,
      lock: [],
      message: '',
      number: ''
    }, options);

    return `C${options.number};${options.amount};${options.message};${this.mapLock(options.lock)}`;
  }

  private mapLock(lockArray: string[]) {
    let ret = 7;

    if (!Array.isArray(lockArray) || lockArray.length === 0) {
      return ret;
    }

    if (lockArray.indexOf('amount') !== -1) {
      ret -= 2;
    }

    if (lockArray.indexOf('message') !== -1) {
      ret -= 4;
    }

    if (lockArray.indexOf('number') !== -1) {
      ret -= 1;
    }

    return ret;
  }

}

