import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutVariable {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    runtimeVariableObjectId: bigint;
    variableGuidId: string | null;
    originalVariableGuidId: string | null;
    value: string | null;
    valueType: number;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.runtimeVariableObjectId = 0n;
        this.variableGuidId = null;
        this.originalVariableGuidId = null;
        this.value = null;
        this.valueType = 0;

    }

    static serialize(value: RuntimeLayoutVariable | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutVariable | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(9);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.runtimeVariableObjectId);
        writer.writeString(value.variableGuidId);
        writer.writeString(value.originalVariableGuidId);
        writer.writeString(value.value);
        writer.writeInt32(value.valueType);

    }

    static serializeArray(value: (RuntimeLayoutVariable | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutVariable | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutVariable.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutVariable | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutVariable | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutVariable();
        if (count == 9) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.runtimeVariableObjectId = reader.readInt64();
            value.variableGuidId = reader.readString();
            value.originalVariableGuidId = reader.readString();
            value.value = reader.readString();
            value.valueType = reader.readInt32();

        }
        else if (count > 9) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.runtimeVariableObjectId = reader.readInt64(); if (count == 5) return value;
            value.variableGuidId = reader.readString(); if (count == 6) return value;
            value.originalVariableGuidId = reader.readString(); if (count == 7) return value;
            value.value = reader.readString(); if (count == 8) return value;
            value.valueType = reader.readInt32(); if (count == 9) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutVariable | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutVariable | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutVariable.deserializeCore(reader));
    }
}
