<div class="popover-grid-container">
  <h2>
    {{ 'Mobile Engine Debug' | translate }}
  </h2>

  <div>
    <button *ngIf="mobileEngineOperationalMode === 'hybrid'"
      class="button"
      (click)="mobileEngineOperationalModeChange(true)">
      {{ 'Ionic > Cloud' | translate }}
    </button>
    <button *ngIf="mobileEngineOperationalMode === 'online'"
      class="button"
      (click)="mobileEngineOperationalModeChange(false)">
      {{ 'Ionic > WASM' | translate }}
    </button>
    <button *ngIf="mobileEngineLayoutState?.online"
      class="button"
      (click)="mobileEngineLayoutStateChange(false)">
      {{ 'Toggle Offline' | translate }}
    </button>
    <button *ngIf="!mobileEngineLayoutState?.online"
      class="button"
      (click)="mobileEngineLayoutStateChange(true)">
      {{ 'Toggle Online' | translate }}
    </button>

    <button class="button"
      (click)="mobileEngineRebootCloud()">
      {{ 'Reboot Cloud' | translate }}
    </button>
  </div>
</div>