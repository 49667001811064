<ion-content *ngIf="checklist"
  class="checklist-control">

  <div *ngIf="!isFreeTextControlVisible && !isPictureControlVisible && !isSignatureControlVisible"
    class="flex-col" style="row-gap: 2px;">
    <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'"
      class="headline">
      {{ layoutControl | parseRV:'ControlHeadlineText' }}
    </h2>

    <div *ngIf="activeGroup"
      class="flex-col gap-05">
      <h3 *ngIf="activeGroup.name"
        class="headline">
        {{ activeGroup.name }}
      </h3>

      <div *ngFor="let check of activeGroup.checks; let j = index;"
        class="check flex-col gap-1"
        [class.collapsed]="check.$collapsed">
        <div class="flex-row gap-1">
          <div class="check-name flex-1"
            [class.no-wrap]="check.$collapsed"
            (click)="check.$checkResult === null || (check.$checkResult || []).length ? check.$collapsed = !check.$collapsed : null">
            {{ check.name }}<sup *ngIf="check.required">*</sup>
          </div>

          <div *ngIf="check.$collapsed"
            class="check-result">
            {{ ((check.$checkResult || []) | pluck:'value').join(', ') || ('N/A' | translate) }}
          </div>

          <i *ngIf="check.$freeText"
            class="fat fa-comment-dots"
            style="font-size: 1.6rem;"
            (click)="showFreeTextControl(check)">
          </i>
          <i *ngIf="check.$pictureB64"
            class="fat fa-camera-retro"
            style="font-size: 1.6rem;"
            (click)="showPictureControl(check)">
          </i>
          <ion-icon *ngIf="check.description || check.allowFreeText || check.allowPicture"
            id="button-more"
            style="font-size: 1.6rem;"
            name="more"
            (click)="showMenuPopover($event, check)">
          </ion-icon>
        </div>

        <div class="flex-row gap-05 wrap">
          <ion-button *ngFor="let checkValue of check.checkValues || []"
            class="check-button"
            [class.active]="(check.$checkResult || []).indexOf(checkValue) >= 0"
            [style.width]="'calc(' + ((check.checkValues.length + (check.allowNotApplicable ? 1 : 0)) > 4 ? 25 : 100 / (check.checkValues.length + (check.allowNotApplicable ? 1 : 0)))  + '% - .5rem)'"
            shadowCss
            shadowCustomCss=".button-inner { display: block !important; height: auto !important; overflow: inherit; text-overflow: ellipsis; }"
            (click)="answerCheck(check, checkValue)"
            [fill]="(check.$checkResult || []).indexOf(checkValue) >= 0 ? 'solid' : 'outline'">
            {{ checkValue.value }}
          </ion-button>

          <ion-button *ngIf="check.allowNotApplicable"
            class="check-button"
            [class.active]="check.$checkResult === null"
            [style.width]="'calc(' + ((check.checkValues.length + (check.allowNotApplicable ? 1 : 0)) > 4 ? 25 : 100 / (check.checkValues.length + (check.allowNotApplicable ? 1 : 0)))  + '% - .5rem)'"
            (click)="answerCheck(check, null)"
            [fill]="(check.$checkResult || []).indexOf(checkValue) >= 0 ? 'solid' : 'outline'">
            {{ 'N/A' | translate }}
          </ion-button>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="isFreeTextControlVisible"
    class="flex-col center middle"
    style="height: 100%;">
    <lc-control-input1 #controlInputComponent
      class="flex-1"
      [staticControl]="staticControl">
    </lc-control-input1>
  </div>

  <div *ngIf="isPictureControlVisible"
    class="flex-col center middle"
    style="height: 100%;">
    <lc-control-camera1 #controlCameraComponent
      class="flex-1"
      [staticControl]="staticControl">
    </lc-control-camera1>
  </div>

  <div *ngIf="isSignatureControlVisible"
    class="flex-col center middle"
    style="height: 100%;">
    <lc-control-sog1 #controlSogComponent
      class="flex-1"
      [staticControl]="staticControl">
    </lc-control-sog1>
  </div>

</ion-content>
