import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BarcodeScannerLivestreamComponent } from '../barcode-scanner-livestream/barcode-scanner-livestream.component';
import { RuntimeLayout } from 'src/app/shared/models/memorypack/RuntimeLayout';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';

@Component({
    selector: 'lc-barcode-scanner-livestream-overlay',
    templateUrl: 'barcode-scanner-livestream-overlay.component.html',
    styleUrls: ['./barcode-scanner-livestream-overlay.component.scss'],
})
export class BarcodeScannerLivestreamOverlayComponent implements OnDestroy {

    @Input() layout: RuntimeLayout;
    @Input() layoutControl: RuntimeLayoutControl;
    @Input() types: string[];

    @Output() valueChanges = new EventEmitter();

    @ViewChild(BarcodeScannerLivestreamComponent, { static: true }) scanner: BarcodeScannerLivestreamComponent;

    private _showScanner = false;

    get showScanner(){
        return this._showScanner;
    }

    ngOnDestroy(){
        this.scanner.stop();
    }

    show() {
        this._showScanner = true;
        this.scanner.start();
    }

    hide() {
        this._showScanner = false;
        this.scanner.stop();
    }

    onValueChanges(result) {
        if (this.showScanner) {
            this.valueChanges.next(result);
        }
    }

}
