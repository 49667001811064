import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogUtils } from '../../utils/log-utils';
import { AppService } from '../app/app.service';
import { OpsStatsService } from '../app/ops-stats.service';
import { LocalSettingsService } from '../local-settings/local-settings.service';



@Injectable({
  providedIn: 'root'
})
export class DebugService {

  static alreadyChecked = false;

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private localSettingsService: LocalSettingsService,
    private opsStatsService: OpsStatsService,
  ) { }

  sendAppStatus(
    btStatus?: any,
    cipherLabStatus?: any,
    nfcStatus?: any,
    satoStatus?: any,
    zebraStatus?: any,
  ): Observable<any> {
    const headers = new HttpHeaders()
    .set('Content-Type', 'text/plain');

    return this.http.post(
      'https://hooks.zapier.com/hooks/catch/3674958/b7emz13/',
      {
        deviceEnrollment: 'deviceEnrollment: ' + JSON.stringify(this.appService.getDeviceEnrollment() || null, null, 1),
        // layoutSnapshot: 'layoutSnapshot: ' + JSON.stringify(this.appService.getLayoutSnapshot() || null, null, 1),
        opsStats: 'opsStats: ' + JSON.stringify(this.opsStatsService.getStatsForAllTypes() || null, null, 1),
        settings: 'settings: ' + JSON.stringify(this.localSettingsService.get(), null, 1),
        log: 'log: ' + JSON.stringify(LogUtils.getLogArray(), null, 1),
        btStatus: btStatus ? 'btStatus: ' + JSON.stringify(btStatus, null, 1) : undefined,
        cipherLabStatus: cipherLabStatus ? 'cipherLabStatus: ' + JSON.stringify(cipherLabStatus, null, 1) : undefined,
        nfcStatus: nfcStatus ? 'nfcStatus: ' + JSON.stringify(nfcStatus, null, 1) : undefined,
        satoStatus: satoStatus ? 'satoStatus: ' + JSON.stringify(satoStatus, null, 1) : undefined,
        zebraStatus: zebraStatus ? 'zebraStatus: ' + JSON.stringify(zebraStatus, null, 1) : undefined,
      },
      { headers: headers }
    );
  }

}
