import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";
import { LayoutDebugLog } from "./LayoutDebugLog";

export class LayoutDebugLogMessage {
    messageType: LayoutMessageType;
    logs: (LayoutDebugLog | null)[] | null;

    constructor() {
        this.messageType = 0;
        this.logs = null;

    }

    static serialize(value: LayoutDebugLogMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutDebugLogMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(2);
        writer.writeInt32(value.messageType);
        writer.writeArray(value.logs, (writer, x) => LayoutDebugLog.serializeCore(writer, x));

    }

    static serializeArray(value: (LayoutDebugLogMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutDebugLogMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutDebugLogMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutDebugLogMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutDebugLogMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutDebugLogMessage();
        if (count == 2) {
            value.messageType = reader.readInt32();
            value.logs = reader.readArray(reader => LayoutDebugLog.deserializeCore(reader));

        }
        else if (count > 2) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageType = reader.readInt32(); if (count == 1) return value;
            value.logs = reader.readArray(reader => LayoutDebugLog.deserializeCore(reader)); if (count == 2) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutDebugLogMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutDebugLogMessage | null)[] | null {
        return reader.readArray(reader => LayoutDebugLogMessage.deserializeCore(reader));
    }
}
