import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LinkingModal } from './linking.modal';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        LinkingModal,
    ]
})
export class LinkingModalModule { }
