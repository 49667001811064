import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutControlPointer } from "./RuntimeLayoutControlPointer";
import { RuntimeLayoutHeadPointer } from "./RuntimeLayoutHeadPointer";

export class RuntimeLayoutScreenControlCollection {
    activeControlPointer: RuntimeLayoutControlPointer | null;
    headPointers: (RuntimeLayoutHeadPointer | null)[] | null;

    constructor() {
        this.activeControlPointer = null;
        this.headPointers = null;

    }

    static serialize(value: RuntimeLayoutScreenControlCollection | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutScreenControlCollection | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(2);
        RuntimeLayoutControlPointer.serializeCore(writer, value.activeControlPointer);
        writer.writeArray(value.headPointers, (writer, x) => RuntimeLayoutHeadPointer.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutScreenControlCollection | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutScreenControlCollection | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutScreenControlCollection.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutScreenControlCollection | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutScreenControlCollection | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutScreenControlCollection();
        if (count == 2) {
            value.activeControlPointer = RuntimeLayoutControlPointer.deserializeCore(reader);
            value.headPointers = reader.readArray(reader => RuntimeLayoutHeadPointer.deserializeCore(reader));

        }
        else if (count > 2) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.activeControlPointer = RuntimeLayoutControlPointer.deserializeCore(reader); if (count == 1) return value;
            value.headPointers = reader.readArray(reader => RuntimeLayoutHeadPointer.deserializeCore(reader)); if (count == 2) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutScreenControlCollection | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutScreenControlCollection | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutScreenControlCollection.deserializeCore(reader));
    }
}
