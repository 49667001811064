import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { BluetoothDevice, BluetoothDeviceMode } from '../../../models/bluetooth-device.model';
import { BasePlugin } from '../base-plugin';
import { BluetoothLEPlugin } from './bluetooth-le.plugin';
import { BluetoothSerialPlugin } from './bluetooth-serial.plugin';


@Injectable({
  providedIn: 'root'
})
export class BluetoothPlugin extends BasePlugin {

  private isPluginAllowedChecked: boolean;

  constructor(
    private bluetoothLEPlugin: BluetoothLEPlugin,
    private bluetoothSerialPlugin: BluetoothSerialPlugin,
    injector: Injector,
    private platform: Platform,
  ) {
    super(injector);

    this.pluginName = 'BluetoothPlugin';
  }

  isPluginAllowed(): boolean {
    return this.bluetoothSerialPlugin.isPluginAllowed() || this.bluetoothLEPlugin.isPluginAllowed();
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    return zip(
      this.bluetoothSerialPlugin.initialize(options),
      this.bluetoothLEPlugin.initialize(options),
    ).pipe(
      map(() => {
        return null;
      })
    );
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'list':
      case 'discover':
        return zip(
          this.bluetoothSerialPlugin.action(options),
          this.bluetoothLEPlugin.action(options)
        ).pipe(
          map((devices: [BluetoothDevice[], BluetoothDevice[]]) => {
            return (devices[0] || []).concat((devices[1] || []))
            .sort((a, b) => { return (a.mode + a.id).localeCompare((b.mode + b.id)); })
            .filter((current, index, array) => {
              return index === 0 || (current.mode + current.id) !== (array[index - 1].mode + array[index - 1].id);
            });
          })
        );
      case 'connect':
      case 'disconnect':
      case 'disconnectOnInactivity':
      case 'read':
      case 'requestMtu':
      case 'start':
      case 'stop':
      case 'write':
        if (options.device?.mode === BluetoothDeviceMode.BLE) {
          return this.bluetoothLEPlugin.action(options);
        } else if (options.device?.mode === BluetoothDeviceMode.Classic) {
          return this.bluetoothSerialPlugin.action(options);
        } else {
          return zip(
            this.bluetoothLEPlugin.action(options),
            this.bluetoothSerialPlugin.action(options),
          );
        }
      default:
        this.log(`Action '${options.command}' not found for bluetooth plugin...`);
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of('Cordova not available...');
    }

    return zip(
      this.bluetoothSerialPlugin.status(),
      this.bluetoothLEPlugin.status()
    ).pipe(
      map((status: [any, any]) => {
        return {
          btClassic: status[0],
          ble: status[1]
        }
      })
    );
  }

}
