import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable, Observer, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BluetoothTypePopover, ConfirmPopover } from 'src/app/popovers';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ControlPrint1Component } from 'src/app/shared/components/control/print1/control-print1.component';
import { Notification } from 'src/app/shared/models';
import { BluetoothDevice, BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { BasePlugin, LocalSettingsService, NotificationService, PluginService, PluginType, VibrationService } from 'src/app/shared/services';
import { AppService, TranslateService } from 'src/app/shared/services/app';
import { SetSettingsService } from 'src/app/shared/services/protocol/set-settings.service';



@Component({
  selector: 'lc-bluetooth-modal',
  templateUrl: 'bluetooth.modal.html',
  styleUrls: ['./bluetooth.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BluetoothModal extends BaseComponent implements OnInit, OnDestroy {

  private readonly alertTitle = this.translateService.instant('Notification');

  private btPlugin: BasePlugin;
  availableDevices: BluetoothDevice[];
  connectedDevices: BluetoothDevice[];
  isActive: boolean;
  isBusy: boolean;
  isScanning: boolean;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private modalCtrl: ModalController,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private pluginService: PluginService,
    private popoverCtrl: PopoverController,
    private setSettingsService: SetSettingsService,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    super();

    this.subscriptions.push(
      this.appService.listenToBackButtonClick()
      .subscribe(() => {
        this.dismiss();
      })
    );
  }

  ngOnInit() {
    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    if (!btPlugin.isPluginAllowed()) {
      setTimeout(() => {
        this.dismiss();
        this.notificationService.showNotification(new Notification({
          title: this.alertTitle,
          text: this.translateService.instant('Bluetooth is only available on device apps.'),
          type: RuntimeLayoutNotifyType.Alert
        }));
      }, 250);
      return;
    }

    this.isActive = true;
    this.btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    this.refresh();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.isActive = false;
  }

  private refresh() {
    if (!this.isActive) return;
    if (this.isScanning) return;

    this.isScanning = true;
    this.cdr.markForCheck();

    this.btPlugin.action({ command: 'list' })
    .pipe(
      mergeMap((pairedDevices: any[]) => {
        this.ngZone.run(() => {
          this.addToListsIfNotAlreadyThere(pairedDevices);
          this.sortLists();

          this.cdr.markForCheck();
        });

        return this.btPlugin.action({ command: 'discover' });
      }),
      catchError((error: any) => {
        this.ngZone.run(() => {
          this.notificationService.showAlert(
            this.translateService.instant('Error'),
            this.translateService.instant(error)
          ).subscribe();
        });

        return of(null);
      }),
    )
    .subscribe((unpairedDevices: any[]) => {
      this.ngZone.run(() => {
        this.addToListsIfNotAlreadyThere(unpairedDevices);
        this.sortLists();

        this.isScanning = false;
        this.cdr.markForCheck();
      });
    });
  }

  private addToListsIfNotAlreadyThere(newDevices: BluetoothDevice[], clearConnectedDevices = false) {
    this.connectedDevices = clearConnectedDevices ? [] : this.connectedDevices || [];
    this.availableDevices = this.availableDevices || [];

    for (const device of newDevices || []) {
      if (device.shouldBeConnected) {
        const existingDevice = this.connectedDevices.find((d: BluetoothDevice) => {
          return device.id === d.id && device.mode === d.mode;
        });
        if (!existingDevice) {
          this.connectedDevices.push(device);
        } else {
          existingDevice.isConnected = device.isConnected;
        }
      } else {
        const existingDevice = this.availableDevices.find((d: BluetoothDevice) => {
          return device.id === d.id && device.mode === d.mode;
        });
        if (!existingDevice) {
          this.availableDevices.push(device);
        }
      }
    }
  }

  private sortLists() {
    this.connectedDevices = this.connectedDevices.sort((a, b) => {
      return (a.name || a.id).localeCompare((b.name || b.id));
    });

    this.availableDevices = this.availableDevices.sort((a, b) => {
      return (a.name || a.id).localeCompare((b.name || b.id));
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  disconnectFrom(device: BluetoothDevice) {
    this.vibrationService.vibrate();

    this.isBusy = true;
    this.btPlugin.action({
      command: 'disconnect',
      device: device,
      unpair: true,
    })
    .pipe(
      mergeMap(() => {
        return this.btPlugin.action({ command: 'list' });
      })
    )
    .subscribe((pairedDevices: BluetoothDevice[]) => {
      this.ngZone.run(() => {
        this.addToListsIfNotAlreadyThere(pairedDevices, true);
        this.isBusy = false;

        this.cdr.markForCheck();

        this.notificationService.showAlert(
          this.alertTitle,
          this.translateService.instant('Disconnected from device'),
        ).subscribe();
      });
    }, (error: any) => {
      this.ngZone.run(() => {
        this.isBusy = false;

        this.cdr.markForCheck();

        this.notificationService.showAlert(
          this.alertTitle,
          this.translateService.instant('Failed to disconnect from device.')
        ).subscribe();
      });
    });
  }

  connectTo(device: BluetoothDevice) {
    this.vibrationService.vibrate();

    this.showConfirmPopover(device)
    .pipe(
      mergeMap((result: boolean) => {
        if (!result) return of(null);

        if (device.type === BluetoothDeviceType.Unknown) {
          return this.showBluetoothTypeSelector(device);
        } else {
          return of(device);
        }
      }),
      mergeMap((device: BluetoothDevice) => {
        if (!device) return of(null);

        this.isBusy = true;
        this.cdr.markForCheck();
        return this.btPlugin.action({
          command: 'connect',
          device: device
        });
      }),
    )
    .subscribe({
      next: () => {
        if (device.type === BluetoothDeviceType.Scanner) {
          this.btPlugin.action({ command: 'start' }).subscribe(); // this shouldn't be done here...should be called by the base active control
        }

        this.ngZone.run(() => {
          this.isBusy = false;
          this.cdr.markForCheck();

          this.dismiss();

          this.notificationService.showNotification(new Notification({
            title: this.alertTitle,
            text: this.translateService.instant('Successfuly connected to device.'),
            type: RuntimeLayoutNotifyType.Confirmation,
            blocking: false,
          }));
        });
      },
      error: (error: any) => {
        this.ngZone.run(() => {
          this.isBusy = false;

          this.cdr.markForCheck();

          this.notificationService.showAlert(this.alertTitle, error).subscribe();
        });
      }
    });
  }

  connectedDeviceActionClick(device: BluetoothDevice) {
    if (device.type === BluetoothDeviceType.Printer || device.type === BluetoothDeviceType.PrinterSato) {
      this.printTestLabel(device);
    } else if (device.type === BluetoothDeviceType.Scanner || device.type === BluetoothDeviceType.Thermometer) {
      this.connectTo(device);
    } else {
      this.showBluetoothTypeSelector(device)
      .subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  private showBluetoothTypeSelector(device: BluetoothDevice): Observable<BluetoothDevice> {
    return from(this.popoverCtrl.create({
      component: BluetoothTypePopover,
      componentProps: {
        device: device
      },
      cssClass: `popover-bluetooth-type`,
      backdropDismiss: false,
      showBackdrop: true
    }))
    .pipe(
      mergeMap((popover: HTMLIonPopoverElement) => {
        popover.present();
        return from(popover.onDidDismiss())
        .pipe(
          map((result: OverlayEventDetail<BluetoothDevice>) => {
            if (result?.data) {
              Object.assign(device, result.data);

              this.localSettingsService.addOrUpdateBtDevice(device);
              this.setSettingsService.setBluetoothDeviceSettingRemotely(device);

              if (device.type === BluetoothDeviceType.Scanner) {
                this.btPlugin.action({ command: 'start' }); // this shouldn't be done here...should be called by the base active control
              } else if (device.type === BluetoothDeviceType.PrinterSato) {
                this.btPlugin.action({ command: 'connect', device: device }).subscribe(); // PrinterSato requires some follow-up after connect...
              } else if (device.settings?.disconnectOnInactivityTimeoutMS) {
                this.btPlugin.action({ command: 'disconnectOnInactivity', device: device }).subscribe();
              }

              return result.data;
            } else {
              return device;
            }
          })
        );
      })
    );
  }

  private showConfirmPopover(device: BluetoothDevice): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      from(this.popoverCtrl.create({
        component: ConfirmPopover,
        componentProps: {
          title: this.translateService.instant('Connect to'),
          text: `${device.name || device.id} (${device.mode})?`,
        },
        cssClass: `popover-confirm`,
        backdropDismiss: false,
        showBackdrop: true,
      }))
      .subscribe((confirmPopover: HTMLIonPopoverElement) => {
        from(confirmPopover.onDidDismiss())
        .subscribe((result: OverlayEventDetail<boolean>) => {
          observer.next(result.data);
          observer.complete();
        });
        confirmPopover.present();
      });
    });
  }

  printTestLabel(device: BluetoothDevice) {
    this.isBusy = true;
    this.vibrationService.vibrate();

    this.btPlugin.action({
      command: 'connect',
      device: device,
      skipSaveSettingRemotely: true,
    })
    .pipe(
      mergeMap(() => {
        this.cdr.markForCheck();

        return this.btPlugin.action({
          command: 'write',
          device: device,
          writeData: device.type === BluetoothDeviceType.PrinterSato
          ? ControlPrint1Component.testSatoPayload
          : ControlPrint1Component.testZebraPayload,
        });
      }),
    )
    .subscribe((response: any) => {
      this.ngZone.run(() => {
        this.isBusy = false;
        this.cdr.markForCheck();
      });
    }, (error: any) => {
      this.ngZone.run(() => {
        this.isBusy = false;
        this.cdr.markForCheck();
      });
      alert('Print error: ' + error);
      if (this.isScanning) alert('Try again after a while (scanning interferes with the connect / write to printer).');
    });
  }

}
