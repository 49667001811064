import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DebugModal } from './debug.modal';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        DebugModal,
    ]
})
export class DebugModalModule { }
