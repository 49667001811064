import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutEventSourceType } from "./RuntimeLayoutEventSourceType";
import { RuntimeLayoutEventPlatformObjectType } from "./RuntimeLayoutEventPlatformObjectType";
import { RuntimeLayoutEventContext } from "./RuntimeLayoutEventContext";

export class RuntimeLayoutEvent {
    snapshotTick: bigint;
    sourceType: RuntimeLayoutEventSourceType;
    layoutObjectId: bigint;
    layoutScreenObjectId: bigint;
    layoutScreenObjectTick: bigint;
    layoutControlObjectId: bigint;
    layoutControlObjectTick: bigint;
    objectId: bigint;
    eventObjectId: bigint;
    platformObject: RuntimeLayoutEventPlatformObjectType;
    platformObjectGuidId: string | null;
    eventContext: RuntimeLayoutEventContext | null;
    controlContexts: Map<bigint, RuntimeLayoutEventContext | null> | null;
    eventDateTime: string | null;

    constructor() {
        this.snapshotTick = 0n;
        this.sourceType = 0;
        this.layoutObjectId = 0n;
        this.layoutScreenObjectId = 0n;
        this.layoutScreenObjectTick = 0n;
        this.layoutControlObjectId = 0n;
        this.layoutControlObjectTick = 0n;
        this.objectId = 0n;
        this.eventObjectId = 0n;
        this.platformObject = 0;
        this.platformObjectGuidId = null;
        this.eventContext = null;
        this.controlContexts = null;
        this.eventDateTime = null;

    }

    static serialize(value: RuntimeLayoutEvent | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutEvent | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(14);
        writer.writeInt64(value.snapshotTick);
        writer.writeInt32(value.sourceType);
        writer.writeInt64(value.layoutObjectId);
        writer.writeInt64(value.layoutScreenObjectId);
        writer.writeInt64(value.layoutScreenObjectTick);
        writer.writeInt64(value.layoutControlObjectId);
        writer.writeInt64(value.layoutControlObjectTick);
        writer.writeInt64(value.objectId);
        writer.writeInt64(value.eventObjectId);
        writer.writeInt32(value.platformObject);
        writer.writeString(value.platformObjectGuidId);
        RuntimeLayoutEventContext.serializeCore(writer, value.eventContext);
        writer.writeMap(value.controlContexts, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutEventContext.serializeCore(writer, x));
        writer.writeString(value.eventDateTime);

    }

    static serializeArray(value: (RuntimeLayoutEvent | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutEvent | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutEvent.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutEvent | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutEvent | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutEvent();
        if (count == 14) {
            value.snapshotTick = reader.readInt64();
            value.sourceType = reader.readInt32();
            value.layoutObjectId = reader.readInt64();
            value.layoutScreenObjectId = reader.readInt64();
            value.layoutScreenObjectTick = reader.readInt64();
            value.layoutControlObjectId = reader.readInt64();
            value.layoutControlObjectTick = reader.readInt64();
            value.objectId = reader.readInt64();
            value.eventObjectId = reader.readInt64();
            value.platformObject = reader.readInt32();
            value.platformObjectGuidId = reader.readString();
            value.eventContext = RuntimeLayoutEventContext.deserializeCore(reader);
            value.controlContexts = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutEventContext.deserializeCore(reader));
            value.eventDateTime = reader.readString();

        }
        else if (count > 14) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotTick = reader.readInt64(); if (count == 1) return value;
            value.sourceType = reader.readInt32(); if (count == 2) return value;
            value.layoutObjectId = reader.readInt64(); if (count == 3) return value;
            value.layoutScreenObjectId = reader.readInt64(); if (count == 4) return value;
            value.layoutScreenObjectTick = reader.readInt64(); if (count == 5) return value;
            value.layoutControlObjectId = reader.readInt64(); if (count == 6) return value;
            value.layoutControlObjectTick = reader.readInt64(); if (count == 7) return value;
            value.objectId = reader.readInt64(); if (count == 8) return value;
            value.eventObjectId = reader.readInt64(); if (count == 9) return value;
            value.platformObject = reader.readInt32(); if (count == 10) return value;
            value.platformObjectGuidId = reader.readString(); if (count == 11) return value;
            value.eventContext = RuntimeLayoutEventContext.deserializeCore(reader); if (count == 12) return value;
            value.controlContexts = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutEventContext.deserializeCore(reader)); if (count == 13) return value;
            value.eventDateTime = reader.readString(); if (count == 14) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutEvent | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutEvent | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutEvent.deserializeCore(reader));
    }
}
