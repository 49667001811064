import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutSetData } from "./RuntimeLayoutSetData";
import { RuntimeLayoutObjectPointer } from "./RuntimeLayoutObjectPointer";

export class RuntimeLayoutSet {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    runtimeSetObjectId: bigint;
    setGuidId: string | null;
    parentObjectId: bigint;
    complexSet: boolean;
    datas: Map<bigint, RuntimeLayoutSetData | null> | null;
    removedDatas: (RuntimeLayoutObjectPointer | null)[] | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.runtimeSetObjectId = 0n;
        this.setGuidId = null;
        this.parentObjectId = 0n;
        this.complexSet = false;
        this.datas = null;
        this.removedDatas = null;

    }

    static serialize(value: RuntimeLayoutSet | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutSet | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(10);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.runtimeSetObjectId);
        writer.writeString(value.setGuidId);
        writer.writeInt64(value.parentObjectId);
        writer.writeBoolean(value.complexSet);
        writer.writeMap(value.datas, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutSetData.serializeCore(writer, x));
        writer.writeArray(value.removedDatas, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutSet | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutSet | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutSet.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutSet | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutSet | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutSet();
        if (count == 10) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.runtimeSetObjectId = reader.readInt64();
            value.setGuidId = reader.readString();
            value.parentObjectId = reader.readInt64();
            value.complexSet = reader.readBoolean();
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSetData.deserializeCore(reader));
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));

        }
        else if (count > 10) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.runtimeSetObjectId = reader.readInt64(); if (count == 5) return value;
            value.setGuidId = reader.readString(); if (count == 6) return value;
            value.parentObjectId = reader.readInt64(); if (count == 7) return value;
            value.complexSet = reader.readBoolean(); if (count == 8) return value;
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSetData.deserializeCore(reader)); if (count == 9) return value;
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 10) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutSet | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutSet | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutSet.deserializeCore(reader));
    }
}
