import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as QRCode from 'qrcode';
import { AppService, VibrationService } from 'src/app/shared/services';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';


@Component({
  selector: 'lc-linking-modal',
  templateUrl: 'linking.modal.html',
  styleUrls: ['./linking.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkingModal implements OnInit {

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private vibrationService: VibrationService,
  ) {

  }

  ngOnInit() {
    this.refresh()
  }

  private refresh() {
    const deviceEnrollment = this.appService.getDeviceEnrollment();
    this.generateQRcode(
      btoa(GuidUtils.clean(deviceEnrollment.deviceGuidId))
    );
    this.cdr.markForCheck();
  }

  dismiss() {
    this.vibrationService.vibrate();

    this.modalCtrl.dismiss();
  }

  private generateQRcode(text: string) {
    const canvas = document.querySelector('#qrcode-canvas');
    canvas.setAttribute('title', text);
    QRCode.toCanvas(
      canvas,
      text,
      {
        width: 256,
      },
      (error) => {
        if (error) console.error(error)
      },
    );
  }

}
