import { environment } from 'src/environments/environment';
import { AppService, LocalSettingsService } from '../services';
import { StorageService } from '../services/app/storage.service';
import { DateUtils } from './date-utils';
import { JsonUtils } from './json.utils';
import { RingBuffer } from './ring-buffer';
import { LayoutDebugLogService } from '../services/protocol/layout-debug-log.service';
import { Observable, map } from 'rxjs';
import { LayoutDebugLog } from '../models/memorypack/LayoutDebugLog';


export class LogUtils {

  private static readonly storageKey = 'lc_logs';
  private static readonly storageEntryMaxLength = 1000;
  private static readonly ringBufferSize = 100;
  private static logRingBuffer: RingBuffer<string>;
  private static appService: AppService;
  private static layoutDebugLogService: LayoutDebugLogService;
  private static settingsService: LocalSettingsService;
  private static storageService: StorageService;

  static initialize(
    appService: AppService,
    layoutDebugLogService: LayoutDebugLogService,
    settingsService: LocalSettingsService,
    storageService: StorageService,
  ): Observable<void> {
    this.appService = appService;
    this.layoutDebugLogService = layoutDebugLogService;
    this.settingsService = settingsService;
    this.storageService = storageService;

    return this.storageService.get(this.storageKey)
    .pipe(
      map((logs: string[]) => {
        this.logRingBuffer = RingBuffer.fromPlain(logs || [], this.ringBufferSize);
      })
    );
  }

  static getLogArray(): string[] {
    return Array.from(this.logRingBuffer || []);
  }

  static clear(): void {
    this.logRingBuffer.length = 0;
    this.writeToBufferAndStorage(undefined);
  }

  static log(...msg: any[]) {
    const logMsg = this.buildLogMessage('INFO', msg);
    if (!environment.production) {
      console.log(logMsg);
    }
    this.writeToBufferAndStorage(logMsg);
  }

  static warn(...msg: any[]) {
    const logMsg = this.buildLogMessage('WARN', msg);
    if (!environment.production) {
      console.warn(logMsg);
    }
    this.writeToBufferAndStorage(logMsg);
  }

  static error(...msg: any[]) {
    const logMsg = this.buildLogMessage('ERROR', msg);

    console.error(logMsg);

    this.writeToBufferAndStorage(logMsg);
  }

  private static buildLogMessage(type: 'INFO' | 'WARN' | 'ERROR', msgs: any[]) {
    let logMsg = '';
    for (let msg of msgs || []) {
      if (logMsg) {
        logMsg += '\r\n';
      }

      if (msg == null || msg === '') continue;

      if (typeof msg !== 'string') {
        if (msg.rejection) { // If the msg is error and it's an IPromiseError, the error is inside rejection
          msg = msg.rejection;
        };
        if (msg instanceof Error) {
          msg = msg.stack || msg.message;
        }

        logMsg += JSON.stringify(JsonUtils.decycle(msg), null, 1);
      } else {
        logMsg += msg;
      }
    }

    if (logMsg.length > this.storageEntryMaxLength) {
      logMsg = logMsg.substring(0, this.storageEntryMaxLength) + '\r\n(...)';
    }
    return `[${DateUtils.nowAsLogString()}] ${type} - ${logMsg}`;
  }

  private static writeToBufferAndStorage(msg: string): void {
    if (!this.logRingBuffer) return;
    if (!msg) return;

    this.logRingBuffer.push(msg);

    if (!this.appService.isAppInForeground()) return;

    this.storageService.set(this.storageKey, this.getLogArray()).subscribe();

    if (!this.appService.isAppOnline) return;
    if (!this.appService.isAuthenticated) return;
    if (!this.settingsService.get().runDeviceDebug) return;

    this.logRingBuffer.maxSize = this.ringBufferSize * 2;

    this.layoutDebugLogService.trigger([
      Object.assign(new LayoutDebugLog(), {
        logDateTime: DateUtils.nowAsLogString(),
        method: '',
        message: msg,
      })
    ]).subscribe();
  }

}
