import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventObject } from '../../models';
import { LocalSettingsService, VibrationService } from '../../services';
import { RuntimeLayoutScreen } from '../../models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutEventPlatformObjectType } from '../../models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from '../../models/runtime-layout/runtime-layout.utils';




@Component({
  selector: 'lc-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  private readonly delayInMS = 250;

  @Input() set layoutScreen(value: RuntimeLayoutScreen) {
    this._layoutScreen = value;

    this.refresh();
  }
  get layoutScreen(): RuntimeLayoutScreen {
    return this._layoutScreen;
  }
  private _layoutScreen: RuntimeLayoutScreen;

  @Output() triggerEvent = new EventEmitter<EventObject>();

  disableButtons: boolean;
  showConfirmButtons: boolean;
  showBottomBorder: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private platform: Platform,
    private vibrationService: VibrationService,
  ) {
  }

  refresh() {
    this.showConfirmButtons = RuntimeLayoutUtils.parseRV(this.layoutScreen, 'ButtonsType', 0) == 1;
    this.showBottomBorder = !this.platform.is('ios') && !this.localSettingsService.get().fullScreen;

    if (this.layoutScreen) {
      const layoutControl = this.layoutScreen.controls?.get(this.layoutScreen.primaryLayoutControlObjectId);
      this.layoutScreen.actionButton = this.layoutScreen.actionButton && layoutControl?.actions?.some(x => !x.shortAction);
    }

    this.cdr.markForCheck();
  }

  back() {
    this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
    this.vibrate();
    this.disableButtonsForAWhile();
  }

  action() {
    this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.Action });
    this.vibrate();
    this.disableButtonsForAWhile();
  }

  forward() {
    this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
    this.vibrate();
    this.disableButtonsForAWhile();
  }

  private vibrate() {
    this.vibrationService.vibrate();
  }

  private disableButtonsForAWhile() {
    this.disableButtons = true;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.disableButtons = false;
      this.cdr.markForCheck();
    }, this.delayInMS);
  }

}

