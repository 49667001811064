import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { BluetoothModalModule } from './bluetooth/bluetooth.modal.module';
import { DebugModalModule } from './debug/debug.modal.module';
import { SettingsModalModule } from './settings/settings.modal.module';
import { SystemInfoModalModule } from './system-info/system-info.modal.module';
import { LinkingModalModule } from './linking/linking.modal.module';


@NgModule({
  exports: [
    BluetoothModalModule,
    DebugModalModule,
    LinkingModalModule,
    SettingsModalModule,
    SystemInfoModalModule,
  ],
  imports: [
    BluetoothModalModule,
    DebugModalModule,
    LinkingModalModule,
    SettingsModalModule,
    SystemInfoModalModule,
    SharedModule,
  ],
})
export class ModalsModule { }
