import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BluetoothTypePopover } from './bluetooth-type.popover';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        BluetoothTypePopover,
    ]
})
export class BluetoothTypePopoverModule { }
