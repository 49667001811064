<div class="height-100 flex-row center middle"
  (click)="onClick($event)"
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd($event)">
  <div class="control-input1">
    <h2 *ngIf="staticControl?.controlHeadlineEnabled || (layoutControl | parseRV:'ControlHeadlineEnabled')">
      {{ staticControl?.controlHeadlineText || (layoutControl | parseRV:'ControlHeadlineText') }}
    </h2>

    <!-- date-time, date, time, month-year, month, year -->
    <lc-control-input1-datetime *ngIf="[3, 4, 5].indexOf(textboxType) >= 0"
      [layoutControl]="layoutControl"
      [staticControl]="staticControl"
      [(value)]="value"
      [presentation]="textboxType === 3 ? 'date' : textboxType === 4 ? 'time' : 'date-time'">
    </lc-control-input1-datetime>

    <div *ngIf="[0, 1, 2].indexOf(textboxType) >= 0"
      class="flex-row"
      style="position: relative;">

      <div class="flex-1"
        style="position: relative;">
        <div #placeholder
          class="placeholder"
          [class.barcode]="hasBarcodeEnabled()"
          [class.nfc]="hasNfcEnabled()"
          [class.textarea]="textboxType === 2">
          <span *ngIf="rawValueMask">
            {{ (value || '') + (rawValueMask?.slice((value || '').length) || '') }}
          </span>
        </div>

        <input *ngIf="textboxType !== 2"
          #input
          class="input"
          [class.barcode]="hasBarcodeEnabled()"
          [class.keyboard]="readonly && ((staticControl && (staticControl.keyboardType || 'None') !== 'None') || (layoutControl && (layoutControl | parseRV:'KeyboardType':'None') !== 'None'))"
          [class.nfc]="hasNfcEnabled()"
          [class.uppercase]="uppercase"
          [attr.inputmode]="keyboardType === 'Numeric' ? 'numeric' : 'text'"
          [angularFormsMask]="valueMask"
          autocomplete="off"
          [id]="inputAutoCompleteBustingId"
          [(ngModel)]="value"
          (ngModelChange)="valueChanged()"
          [readonly]="readonly"
          [type]="textboxType === 1 ? 'password' : 'text'"
          [validateMaskInput]="true" />

        <textarea *ngIf="textboxType === 2"
          #input
          class="input textarea"
          [class.barcode]="hasBarcodeEnabled()"
          [class.keyboard]="readonly && ((staticControl && (staticControl.keyboardType || 'None') !== 'None') || (layoutControl && (layoutControl | parseRV:'KeyboardType':'None') !== 'None'))"
          [class.nfc]="hasNfcEnabled()"
          [class.uppercase]="uppercase"
          [angularFormsMask]="valueMask"
          [(ngModel)]="value"
          (ngModelChange)="valueChanged()"
          (scroll)="multilineScrollTop = $event.target.scrollTop"
          [readonly]="readonly"
          [rows]="textAreaRows || 3"
          [validateMaskInput]="true">
        </textarea>

        <div *ngIf="readonly"
          #overlayValue
          class="overlay-value"
          [class.barcode]="hasBarcodeEnabled()"
          [class.blinking-cursor]="textboxType !== 1 && inputHasFocus()"
          [class.nfc]="hasNfcEnabled()"
          [class.textarea]="textboxType === 2"
          [class.uppercase]="uppercase"
          [style.top]="(-multilineScrollTop || 0) + 'px'"
          [style.left]="(-multilineScrollLeft || 0) + 'px'"
          [innerText]="valueUpToCursor">
        </div>

        <ion-button *ngIf="dropdownItems?.length"
          id="open-action-sheet"
          style="position: absolute; top: 8px;"
          [style.right]="(readonly && ((staticControl && (staticControl.keyboardType || 'None') !== 'None') || (layoutControl && (layoutControl | parseRV:'KeyboardType':'None') !== 'None')) ? 50 : 0) + 'px'"
          (click)="actionSheet?.present()"
          fill="clear">
          <ion-icon name="chevron-down"></ion-icon>
        </ion-button>
      </div>

      <p *ngIf="layoutControl | parseRV:'InputBuffering'"
        class="buffer-text">
        ({{ bufferValues?.length || 0 }})
      </p>
    </div>

    <p class="output-text">
      {{ staticControl?.outputText || (layoutControl | parseRV:'OutputText') || '&nbsp;' }}
    </p>
  </div>
</div>

<ion-action-sheet #actionSheet
  [header]="'Options' | translate"
  [buttons]="dropdownItems || []"
  (didDismiss)="dropdownItemSelected($event)">
</ion-action-sheet>

<div class="barcode-scanner-container">
  <lc-barcode-scanner-livestream-overlay
    [layout]="layout"
    [layoutControl]="layoutControl"
    [types]="barcodeTypes"
    (valueChanges)="onCameraScan($event)">
  </lc-barcode-scanner-livestream-overlay>
</div>