<div class="menu-wrapper flex-col">
  <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
    {{ layoutControl | parseRV:'ControlHeadlineText' }}
  </h2>

  <div class="flex-row wrap">
    <button *ngFor="let item of menuData || []"
      class="button"
      [ngClass]="'col-' + menuDefinition?.cols"
      [ngStyle]="{ height: menuDefinition?.numOfScreenRows ? 'calc(calc(calc(100vh - 8rem) / ' + menuDefinition?.numOfScreenRows + ') - 1rem)' : '8rem' }"
      (click)="buttonClick(item.dataGuidId)">

      <div class="button-inner">
        <div *ngIf="item.icon" class="flex-1"></div>

        <i *ngIf="item.icon" class="icon {{ item.icon }}"></i>

        <div class="flex-1"></div>

        <span class="label"
          [style.white-space]="item.icon ? 'nowrap': 'normal'">
          {{ item | parseRV:LABEL_GUID }}
        </span>

        <div class="flex-1"></div>
      </div>

    </button>
  </div>
</div>
