import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { from, Observable, throwError, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DeviceEnrollment3 } from 'src/app/shared/models/memorypack/DeviceEnrollment3';
import { environment } from 'src/environments/environment';
import { HostRestartResponse } from '../../../models';
import { TranslateService } from '../../app';
import { StorageService } from '../../app/storage.service';
import { LocalSettingsService } from '../../local-settings/local-settings.service';
import { TextService } from '../../text/text.service';
import { ApiService } from '../api.service';




@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private readonly urlSuffixPlaceholder = 'device/{what}';
  private readonly storageKey = 'lc_deviceEnrollment';
  private readonly storageKeyResources = 'lc_resources';

  constructor(
    private apiService: ApiService,
    private localSettingsService: LocalSettingsService,
    private storageService: StorageService,
    private textService: TextService,
    private translateService: TranslateService,
  ) { }

  web(currentEnrollmentGuidId: string, runCode?: string, runSet?: string, runSetSubKey?: string): Observable<DeviceEnrollment3> {
    this.apiService.setApiUrl(environment.apiUrl, true);

    return from(FingerprintJS.load())
    .pipe(
      mergeMap((agent: any) => {
        return from(agent.get())
      }),
      mergeMap((result: any) => {
        const fingerprint = result.visitorId;
        return this.apiService.post(
          this.urlSuffixPlaceholder.replace('{what}', 'web'),
          {
            // clientType: ClientType.Heavy,
            clientRunEngineGuidId: environment.clientRunEngineGuidId,
            currentEnrollmentGuidId: currentEnrollmentGuidId,
            // deviceEngineClientSpecialVersion: undefined,
            // deviceEngineClientSysVersion: undefined,
            fingerprint: fingerprint,
            // layoutClientSpecialVersion: undefined,
            // layoutClientSysVersion: undefined,
            runCode: runCode,
            runSet: runSet,
            runSetSubKey: runSetSubKey,
          }
        )
      }),
      mergeMap((response: any) => {
        if (response?.noDeviceEngine) {
          return throwError(() => new Error(response?.systemExceptionText || this.translateService.instant('No Device Engine')));
        } else if (response?.systemException) {
          return throwError(() => new Error(response?.systemExceptionText || this.translateService.instant('Unknown error')));
        }

        const deviceEnrollment: DeviceEnrollment3 = response?.deviceEnrollment3 || {};
        deviceEnrollment.$deviceRunStatus = response.deviceRunStatus;
        deviceEnrollment.$deviceOemGuidId = undefined;
        deviceEnrollment.$enrollmentKey = undefined;
        deviceEnrollment.$runCode = runCode;
        deviceEnrollment.$runSet = runSet;
        deviceEnrollment.$runSetSubKey = runSetSubKey;

        this.textService.languageGuidId = response.devicePlatformTexts3.languageGuidId;
        this.textService.texts = response.devicePlatformTexts3.texts as any;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment3) => {
        return this.storageService.set(this.storageKey, deviceEnrollment)
        .pipe(
          map(() => {
            return deviceEnrollment;
          })
        );
      })
    );
  }

  restart(environmentGuidId: string, enrollmentGuidId: string, factoryReset?: boolean): Observable<HostRestartResponse> {
    return this.apiService.post<HostRestartResponse>(
      this.urlSuffixPlaceholder.replace('{what}', 'restart'),
      {
        environmentGuidId: environmentGuidId,
        enrollmentGuidId: enrollmentGuidId,
        clientRunEngineGuidId: environment.clientRunEngineGuidId,
        runDeviceDebug: this.localSettingsService.get().runDeviceDebug,
        factoryReset: factoryReset,
      }
    );
  }

}
