import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GridsterComponent, GridsterConfig } from 'angular-gridster2';
import { Observable, Observer, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/resource.model';
import { DesignStyleJson, DesignStyleJsonItem } from 'src/app/shared/models/studio';
import { AudioService, BasePlugin, PluginType } from 'src/app/shared/services';
import { LayoutResourceService } from 'src/app/shared/services/protocol/layout-resource.service';
import { CaseUtils, LogUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictNumber, DictString, Notification, Scan } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutDesignStyle } from 'src/app/shared/models/memorypack/RuntimeLayoutDesignStyle';
import { RuntimeLayoutData } from 'src/app/shared/models/memorypack/RuntimeLayoutData';
import { RuntimeLayoutSetData } from 'src/app/shared/models/memorypack/RuntimeLayoutSetData';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';

class RfidSetTag {
  tid: string;
  epc?: string;
  unitGuidId?: string;
  articleGuidId?: string;
  locationGuidId?: string;
  inventoryCheck?: boolean;
  lastInventoryTick?: bigint;
  lastInventorySequence?: number;
  tick: bigint;
}

class RfidSet {
  created: number;
  jsonSetGuidId: string;
  tags: RfidSetTag[];
  unknownTags: RfidSetTag[];
  tick: number;
  dirty: boolean;
  sourceTick: number;
}


@Component({
  selector: 'lc-control-rfid-inventory',
  templateUrl: 'control-rfid-inventory.component.html',
  styleUrls: ['./control-rfid-inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRfidInventoryComponent extends ControlBaseComponent implements OnInit {

  readonly progressColors = [
    '#ff0000',
    '#fe4400',
    '#f86600',
    '#ee8200',
    '#df9b00',
    '#cdb200',
    '#b6c700',
    '#98db00',
    '#6fed00',
    '#00ff00',
  ];
  readonly defaultScreenCols = 1; // only really used for old designJsons who didn't have .screenCols
  readonly defaultScreenRows = 5; // only really used for old designJsons who didn't have .screenRows

  @ViewChildren(GridsterComponent) gridsterComponents: QueryList<GridsterComponent>;

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;

  get knownBufferValues() {
    return this.appService.bufferingSetValues?.[this.layoutControl?.objectId?.toString()] || [];
  }

  get unknownBufferValues() {
    return this.appService.uiBufferingSetValues?.[this.layoutControl?.objectId?.toString()] || [];
  }

  articleScanCountMap: DictString<number> = {};
  articleTotalCountMap: DictString<number> = {};
  cipherLabPlugin: BasePlugin;
  designStyleMappings: any[];
  deviationBufferValues: string[] = [];
  headerDefinition: RuntimeLayoutDesignStyle;
  headerAddToIndex: number;

  gridsterOptions1: GridsterConfig;
  gridsterOptions2: GridsterConfig;
  ignoreButtonOverride: boolean;
  layoutResourceSubscriptionMap: { [key: string]: Subscription } = {};
  listDefinition: any;
  listData: RuntimeLayoutData[];
  listenRfidSubscription: Subscription;
  listReady: boolean;
  locationGuidId: string;
  locationOptions: any[];
  private numOfVisibleItems: number;
  private originalRfidSet: RfidSet
  pipePureValueBusting: number = 0;
  resourceMap: DictString<SafeUrl> = {};
  private rfidSet: RfidSet;
  private rfidTagTimeoutInSeconds: number;
  rowDefinition: RuntimeLayoutDesignStyle[];
  rowMinHeight: string;;

  private setDatasObjectPointers: RuntimeLayoutSetData[];
  thisComponent: ControlRfidInventoryComponent;
  totalInventoryTags: number;
  useItem: boolean;
  useInventoryOrderUpdate: boolean;
  useLocations: boolean;
  useUnknownRfidScanned: boolean;

  constructor(
    private audioService: AudioService,
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    injector: Injector,
    private el: ElementRef,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.gridsterOptions1 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 0,
      mobileBreakpoint: 0,
    };
    this.gridsterOptions2 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 2,
      mobileBreakpoint: 0,
    };

    this.thisComponent = this;
  }

  ngOnInit() {
    this.headerDefinition = undefined;

    if (!this.layoutControl || !this.layoutScreen) {
      this.showMissingFieldNotification('LayoutScreen/LayoutControl');
      return;
    }

    this.initListStuff();

    this.initRfidStuff();

    this.cdr.markForCheck();

    this.refresh();
  }

  private initListStuff() {
    // Get list definition (for the GRID CSS)
    this.listDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'ListJson', null));
    if (!this.listDefinition) {
      this.showMissingFieldNotification('ListJson');
      return;
    }

    if (this.listDefinition.style?.minHeight) {
      // FX3 printer old chrome webview doesn't support CSS max()...so we have to try to calculate it in javascript...
      // `max(${this.listDefinition.style.minHeight}, ${minOuterRowHeight}rem)`;
      this.rowMinHeight = this.getMinRowHeight(this.listDefinition.style.minHeight, 0);
      delete this.listDefinition.style?.minHeight;
    }

    // Get the designStyleMapping
    const designStyleMappingEnabled = RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMapping', false);
    if (designStyleMappingEnabled) {
      this.designStyleMappings = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMappingFilterStringJson', '[]'));
    }

    this.headerDefinition = this.getItemDesignStyle(this.listDefinition.header);
    this.headerAddToIndex = !!this.headerDefinition ? 1 : 0;

    this.rowDefinition = (this.listDefinition.items || [])
    .map(x => this.getItemDesignStyle(x))
    .filter(x => x);
  }

  private initRfidStuff() {
    // Get RfidInventory specific render values
    this.rfidSet = CaseUtils.toCamel(JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'RfidSet', '{}'))); console.log(this.rfidSet);
    this.originalRfidSet = JSON.parse(JSON.stringify(this.rfidSet));

    this.totalInventoryTags = 0;
    for (const tag of this.rfidSet.tags || []) {
      if (tag.inventoryCheck) {
        this.articleTotalCountMap[GuidUtils.clean(tag.articleGuidId)] = this.articleTotalCountMap[GuidUtils.clean(tag.articleGuidId)] || 0;
        this.articleTotalCountMap[GuidUtils.clean(tag.articleGuidId)]++;
        this.totalInventoryTags++;
      }
    }

    this.rfidTagTimeoutInSeconds = RuntimeLayoutUtils.parseRV(this.layoutControl, 'RfidTagTimeout', 0) * 1000;
    this.useInventoryOrderUpdate = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseInventoryOrderUpdate', false);
    this.useLocations = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseLocations', false);
    this.useUnknownRfidScanned = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseUnknownRfidScanned', false);
    this.locationOptions = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Locations', [])
    .map((location: any) => {
      return { label: location.name, value: location.guidId };
    });
    if (this.locationOptions?.length) this.locationGuidId = this.locationOptions[0].value;

    this.cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    if (!this.cipherLabPlugin.isPluginAllowed()) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Not running on CipherLab device...'),
        type: RuntimeLayoutNotifyType.Alert,
      }));
      return;
    }

    this.cipherLabPlugin.action({ command: 'has_rfid' })
    .subscribe((result: boolean) => {
      if (!result) {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('CipherLab RFID Pistol not available.'),
          type: RuntimeLayoutNotifyType.Alert,
        }));
        return;
      }

      if (!this.listenRfidSubscription || this.listenRfidSubscription.closed) {
        this.listenRfidSubscription = this.scannerService.listenRfid()
        .subscribe((scan: Scan) => {
          this.handleRfidScan(scan);
        });
        this.subscriptions.push(this.listenRfidSubscription);
      }
    });
  }

  refresh() {
    // Get list data
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    this.setDatasObjectPointers = Array.from(this.layoutScreen.sets.get(setId)?.datas?.values() || []);

    this.sortData();

    this.listData = [];
    this.numOfVisibleItems = this.populateListDataFromTo(
      0,
      ((this.listDefinition.screenRows || this.defaultScreenRows) * (this.listDefinition.screenCols || this.defaultScreenCols)) - 1,
    );
    // this.doSummaryCalculationIfEnabled();
    this.cdr.markForCheck();

    setTimeout(() => {
      this.listReady = true;
      this.cdr.markForCheck();
    }, 10);
  }

  private handleRfidScan(scan: Scan) {
    this.rfidSet.tags = this.rfidSet.tags || [];
    this.rfidSet.unknownTags = this.rfidSet.unknownTags || [];

    const scanTid = (scan.tagId || '').toUpperCase();
    const scanEpc = (scan.tagEpc || '').toUpperCase();
    const existingTag = this.rfidSet.tags.find(x => (x.tid || '').toUpperCase() === scanTid || (x.epc || '').toUpperCase() === scanEpc);
    if (existingTag) {
      this.rfidSet.dirty = true;
      existingTag.tid = scanTid;
      existingTag.epc = scanEpc;
      existingTag.tick = this.layout.tick;
      existingTag.lastInventoryTick = this.layout.tick;
      if (this.useLocations) existingTag.locationGuidId = this.locationGuidId;

      if (existingTag.inventoryCheck) {
        const addToBufferResult = this.addToBuffer(scanTid || scanEpc);
        if (addToBufferResult) {
          LogUtils.warn('addToKnownBuffer(' + (scanTid || scanEpc) + ')');
          this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)] = this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)] || 0;
          this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)]++;

          if (this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)] === this.articleTotalCountMap[GuidUtils.clean(existingTag.articleGuidId)]) {
            this.audioService.play('confirmation');
          }
          if (this.knownBufferValues?.length === this.totalInventoryTags) {
            setTimeout(() => {
              this.audioService.play('confirmation');
            }, 50);
          }

          this.pipePureValueBusting++;
          this.refresh();
        }
      } else {
        const addToBufferResult = this.addToDeviationBuffer(scanTid || scanEpc);
        if (addToBufferResult) {
          LogUtils.warn('addToKnownBuffer(' + (scanTid || scanEpc) + ')');
          this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)] = this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)] || 0;
          this.articleScanCountMap[GuidUtils.clean(existingTag.articleGuidId)]++;

          this.pipePureValueBusting++;
          this.refresh();
        }
      }
    } else {
      const addToBufferResult = this.addToUnknownBuffer(scanTid || scanEpc);
      if (!addToBufferResult) return;

      LogUtils.warn('addToUnknownBuffer(' + (scanTid || scanEpc) + ')');
      this.rfidSet.unknownTags.push({
        tid: scanTid,
        epc: scanEpc,
        lastInventoryTick: this.layout.tick,
        tick: this.layout.tick,
      });
    }
  }

  private showMissingFieldNotification(missingFieldName: string): void {
    this.notificationService.showNotification(new Notification({
      blocking: true,
      text: `${this.translateService.instant('Missing')} ${missingFieldName}!`,
      type: RuntimeLayoutNotifyType.Unknown
    }));
    this.cdr.markForCheck();
  }

  private sortData() {
    this.setDatasObjectPointers = (this.setDatasObjectPointers || []).sort((a, b) => {
      const dataA = this.layoutScreen.datas.get(a.objectId);
      const dataB = this.layoutScreen.datas.get(b.objectId);
      const progressA = (this.articleScanCountMap[dataA.dataGuidId] || 0) / (this.articleTotalCountMap[dataA.dataGuidId] || 1);
      const progressB = (this.articleScanCountMap[dataB.dataGuidId] || 0) / (this.articleTotalCountMap[dataB.dataGuidId] || 1);

      if (progressA >= 1 && progressB >= 1) return 0;
      if (progressA < 1 && progressB >= 1) return -1;
      if (progressA >= 1 && progressB < 1) return 1;

      return progressB - progressA;
    });
  }

  private populateListDataFromTo(startIndex: number, endIndex: number): number {
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= this.setDatasObjectPointers.length) break;

      let data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: this.setDatasObjectPointers[i].objectId });

      if (this.listData.indexOf(data) < 0) {
        this.listData.push(data);
      } else {
        endIndex++; // if one row doesn't pass the filter, we allow to check for 1 more
      }
    }

    return this.listData.length;
  }

  private getItemDesignStyle(item: any): RuntimeLayoutDesignStyle {
    if (!item) return undefined;

    const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
      return GuidUtils.isEqual((ld.originalDesignGuidId), (item.design?.guidId || item.designGuidId))
      || GuidUtils.isEqual((ld.designGuidId), (item.design?.guidId || item.designGuidId));
    });
    if (!design) return undefined;

    const designStyle = Object.assign(
      new RuntimeLayoutDesignStyle(),
      design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
        return GuidUtils.isEqual((lds.originalDesignStyleGuidId || lds.designStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId));
      })
    );
    if (designStyle && !DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary)) {
      this.notificationService.showNotification(new Notification({
        blocking: true,
        title: this.translateService.instant('Empty DesignStyle!'),
        text: designStyle.designStyleGuidId,
        type: RuntimeLayoutNotifyType.Unknown
      }));
    }
    return designStyle;
  }

  getDesignStyleWithMapping(i: number, j: number): RuntimeLayoutDesignStyle | null {
    const itemData = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    if (!this.designStyleMappings?.length) return this.rowDefinition[j % this.listDefinition.rows];

    for (const designStyleMapping of this.designStyleMappings || []) {
      let groupResult = null;
      for (const filterGroup of designStyleMapping.filterStringFilter?.filterGroups || []) {
        let stepResult = this.evalFilterGroupResult(filterGroup, itemData);
        groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
      }
      if (groupResult) return this.getItemDesignStyle(designStyleMapping);
    }
    return this.rowDefinition[j % this.listDefinition.rows];
  }

  getItemData(item: DesignStyleJsonItem, i: number, j: number): RuntimeLayoutData {
    if (!this.listData?.length) return null;

    if (!item.field?.originalVariableGuidId) return this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    let data = null;
    if (item.field?.subVariableMemberGuidId) {
      const valueRaw = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)].values[item.field.subVariableMemberGuidId];
      if (valueRaw?.extendedValueType === 'Resource') {
        data = Array.from(this.layoutScreen.datas.values()).find(x => x.dataGuidId === JSON.parse(valueRaw.valueJson));
      }
    }
    if (!data) {
      const variable = Array.from(this.layoutScreen.variables.values()).find(x => x.originalVariableGuidId === item.field.originalVariableGuidId);
      if (variable) {
        data = this.layoutScreen.datas.get(BigInt(variable.value));
      }
    }

    if (data?.isResource && !this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick]) {
      // make sure we only request it once per resourceGuidId_resourceTick
      this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick] = this.layoutResourceService.get(data.resourceGuidId, data.resourceTick)
      .pipe(
        mergeMap((resource: Resource) => {
          const blob: Blob = new Blob([resource.content], { type: resource.contentType });
          return BlobUtils.blobToDataURL(blob);
        })
      )
      .subscribe((dataUrl: string) => {
        this.resourceMap[data.resourceGuidId] = this.domSanitizer.bypassSecurityTrustUrl(dataUrl);
        this.cdr.markForCheck();
      });
    }

    return data;
  }

  getItemFieldValue(item: DesignStyleJsonItem, i: number, j: number): string {
    if (!item.field) return item.valueIfNull || '';

    const data = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!data?.values?.size) return `[CORRUPT DATA ID: ${data.objectId}]`;

    let result = item.field.staticValue != null ? item.field.staticValue
    : item.field.textId ? this.layoutTexts.get(item.field.textId)?.text
    : RuntimeLayoutUtils.parseRV(data, item.field.subVariableMemberGuidId || item.field.originalVariableGuidId);

    result = result != null ? result : (item.valueIfNull || '');

    if (item.cellSyntax) return item.cellSyntax.replace(/{{value}}/g, result);
    else return result;
  }

  private evalFilterGroupResult(outterfilterGroup: any, itemData: RuntimeLayoutData): boolean {
    if (!outterfilterGroup.filter?.filterGroups?.length) {
      return this.evalFilterResult(outterfilterGroup.filter, itemData);
    }

    let groupResult = null;
    for (const filterGroup of outterfilterGroup.filter?.filterGroups || []) {
      let stepResult = this.evalFilterResult(filterGroup.filter, itemData);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private evalFilterResult(filter: any, itemData: RuntimeLayoutData) {
    if (!filter || !itemData?.values) return null;

    let stepResult = null;
    const filterValue = RuntimeLayoutUtils.parseRV(itemData, GuidUtils.clean(filter.member.guidId));
    switch (filter.operator) {
      case '=':
        stepResult = filter.value == filterValue;
        break;
      case '!=':
        stepResult = filter.value != filterValue;
        break;
      case '<':
        stepResult = filter.value < filterValue;
        break;
      case '<=':
        stepResult = filter.value <= filterValue;
        break;
      case '>':
        stepResult = filter.value > filterValue;
        break;
      case '>=':
        stepResult = filter.value >= filterValue;
        break;
    }
    return stepResult;
  }

  backButtonOverride(): boolean {
    if (this.rfidSet?.dirty && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'InventoryUpdate',
        () => {
          this.rfidSet.dirty = false;
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  forwardButtonOverride(): boolean {
    if (this.rfidSet?.dirty && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'InventoryUpdate',
        () => {
          this.rfidSet.dirty = false;
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  preActionTrigger(): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      if (this.rfidSet?.dirty) {
        this.triggerPortEvent(
          'InventoryUpdate',
          () => {
            this.rfidSet.dirty = false;
            observer.next(null);
            observer.complete();
          }
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  private triggerPortEvent(portName: 'InventoryUpdate' | 'UnknownRfidScanned', callback?: () => void) {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    if (portName === 'InventoryUpdate' && !this.useInventoryOrderUpdate) {
      this.ignoreButtonOverride = true;
      if (callback) callback();
      return;
    }

    this.triggerEvent.emit({
      callback: (result: boolean) => {
        if (!result) {
          return;
        }

        if (callback) callback();
      },
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    context.set('UpdateRfidSetObject', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(JSON.stringify(CaseUtils.toPascal(this.rfidSet || {}))),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    if (this.useUnknownRfidScanned) {
      context.set('UnknownRfidBufferTids', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.unknownBufferValues || [])),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  addToBuffer(value: string, dryRun?: boolean): boolean {
    this.appService.bufferingSetValues[this.layoutControl.objectId.toString()] = this.appService.bufferingSetValues[this.layoutControl.objectId.toString()] || [];

    const now = Date.now();
    if (!value) {
      return false;
    } else if (this.appService.bufferingSetValues[this.layoutControl.objectId.toString()].indexOf(value) >= 0) {
      return false;
    }

    this.appService.bufferingValuesTickMap[value] = now;
    this.appService.bufferingSetValues[this.layoutControl.objectId.toString()].push(value);
    this.blinkCounter('counterKnown');
    this.cdr.markForCheck();

    return true;
  }

  addToDeviationBuffer(value: string, dryRun?: boolean): boolean {
    this.deviationBufferValues = this.deviationBufferValues || [];

    const now = Date.now();
    if (!value) {
      return false;
    } else if (this.deviationBufferValues.indexOf(value) >= 0) {
      return false;
    }

    this.appService.bufferingValuesTickMap[value] = now;
    this.deviationBufferValues.push(value);
    this.blinkCounter('counterDeviation');
    this.cdr.markForCheck();

    return true;
  }

  addToUnknownBuffer(value: string): boolean {
    this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()] = this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()] || [];

    const now = Date.now();
    if (!value) {
      return false;
    } else if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingUnknownItemRegEx') && !(new RegExp(RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingUnknownItemRegEx'))).test(value)) {
      return false;
    } else if (
      this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()].indexOf(value) >= 0
      && (!this.rfidTagTimeoutInSeconds || (now - (this.appService.uiBufferingValuesTickMap[value] || 0)) <= this.rfidTagTimeoutInSeconds)
    ) {
      return false;
    }

    this.appService.uiBufferingValuesTickMap[value] = now;
    this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()].push(value);
    this.blinkCounter('counterUnknown');
    this.cdr.markForCheck();
    return true;
  }

  private blinkCounter(counterId: string) {
    let counterEl = this.el.nativeElement.querySelector('#' + counterId) as HTMLElement;
    if (!counterEl) return;

    counterEl.className += ' blink'
    this.cdr.markForCheck();

    setTimeout(() => {
      counterEl.className = counterEl.className.replace(/blink/g, '');
      this.cdr.markForCheck();
    }, 500);
  }

  getNgForArray(repetitions: number) {
    if (repetitions > ~~repetitions) {
      repetitions = ~~repetitions + 1; // round up if required...
    }
    return Array(repetitions).fill(1).map((x,i) => i + 1);
  }

  doInfinite(event: any) {
    setTimeout(() => {
      this.numOfVisibleItems = this.populateListDataFromTo(
        this.numOfVisibleItems,
        this.numOfVisibleItems + (this.listDefinition.screenRows || this.defaultScreenRows) - 1
      );

      event.target.complete();
      this.cdr.markForCheck();
    }, 10);
  }

  getMinRowHeight(rowHeightPercentage: number | string, minRem: number | string) {
    return Math.max(
      this.convertRowHeightPercentageToPixels(rowHeightPercentage),
      this.convertRemToPixels(minRem),
    ) + 'px'
  }

  private convertRowHeightPercentageToPixels(rowHeightPercentage: number | string) {
    return (parseFloat(rowHeightPercentage.toString()) / 100) * parseFloat(getComputedStyle(this.el.nativeElement).height);
  }

  private convertRemToPixels(rem: number | string) {
    return parseFloat(rem.toString()) * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

}

