import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutDataSourceType } from "./RuntimeLayoutDataSourceType";
import { RuntimeLayoutValue } from "./RuntimeLayoutValue";

export class RuntimeLayoutData {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    runtimeDataObjectId: bigint;
    dataGuidId: string | null;
    typeGuidId: string | null;
    sourceType: RuntimeLayoutDataSourceType;
    dataGuidIds: boolean;
    environmentGuidId: string | null;
    values: Map<string | null, RuntimeLayoutValue | null> | null;
    isResource: boolean;
    presistantResource: boolean;
    resourceGuidId: string | null;
    resourceTick: bigint;
    parentRuntimeDataObjectId: bigint;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.runtimeDataObjectId = 0n;
        this.dataGuidId = null;
        this.typeGuidId = null;
        this.sourceType = 0;
        this.dataGuidIds = false;
        this.environmentGuidId = null;
        this.values = null;
        this.isResource = false;
        this.presistantResource = false;
        this.resourceGuidId = null;
        this.resourceTick = 0n;
        this.parentRuntimeDataObjectId = 0n;

    }

    static serialize(value: RuntimeLayoutData | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutData | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(16);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.runtimeDataObjectId);
        writer.writeString(value.dataGuidId);
        writer.writeString(value.typeGuidId);
        writer.writeInt32(value.sourceType);
        writer.writeBoolean(value.dataGuidIds);
        writer.writeString(value.environmentGuidId);
        writer.writeMap(value.values, (writer, x) => writer.writeString(x), (writer, x) => RuntimeLayoutValue.serializeCore(writer, x));
        writer.writeBoolean(value.isResource);
        writer.writeBoolean(value.presistantResource);
        writer.writeString(value.resourceGuidId);
        writer.writeInt64(value.resourceTick);
        writer.writeInt64(value.parentRuntimeDataObjectId);

    }

    static serializeArray(value: (RuntimeLayoutData | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutData | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutData.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutData | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutData | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutData();
        if (count == 16) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.runtimeDataObjectId = reader.readInt64();
            value.dataGuidId = reader.readString();
            value.typeGuidId = reader.readString();
            value.sourceType = reader.readInt32();
            value.dataGuidIds = reader.readBoolean();
            value.environmentGuidId = reader.readString();
            value.values = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader));
            value.isResource = reader.readBoolean();
            value.presistantResource = reader.readBoolean();
            value.resourceGuidId = reader.readString();
            value.resourceTick = reader.readInt64();
            value.parentRuntimeDataObjectId = reader.readInt64();

        }
        else if (count > 16) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.runtimeDataObjectId = reader.readInt64(); if (count == 5) return value;
            value.dataGuidId = reader.readString(); if (count == 6) return value;
            value.typeGuidId = reader.readString(); if (count == 7) return value;
            value.sourceType = reader.readInt32(); if (count == 8) return value;
            value.dataGuidIds = reader.readBoolean(); if (count == 9) return value;
            value.environmentGuidId = reader.readString(); if (count == 10) return value;
            value.values = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader)); if (count == 11) return value;
            value.isResource = reader.readBoolean(); if (count == 12) return value;
            value.presistantResource = reader.readBoolean(); if (count == 13) return value;
            value.resourceGuidId = reader.readString(); if (count == 14) return value;
            value.resourceTick = reader.readInt64(); if (count == 15) return value;
            value.parentRuntimeDataObjectId = reader.readInt64(); if (count == 16) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutData | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutData | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutData.deserializeCore(reader));
    }
}
