<div class="grid-container">
  <div class="control-camera1">
    <h2 *ngIf="staticControl?.controlHeadlineEnabled || layoutControl | parseRV:'ControlHeadlineEnabled'"
      style="padding-left: 4px;">
      {{ staticControl?.controlHeadlineText || layoutControl | parseRV:'ControlHeadlineText' }}
    </h2>

    <div class="photo">
      <img alt="photo"
        [src]="b64Image ?  b64Image : 'assets/img/' + theme + '/camera.png'"
        (click)="takePicture()" />

      <div *ngIf="b64Image"
        class="delete"
        (click)="deletePicture()">
        <ion-icon name="trash"></ion-icon>
      </div>
    </div>
  </div>
</div>
