import structuredClone from '@ungap/structured-clone';
import { ungzip } from 'pako';
import encoding from 'text-encoding';
import { RuntimeLayout } from '../memorypack/RuntimeLayout';
import { RuntimeLayoutData } from '../memorypack/RuntimeLayoutData';
import { RuntimeLayoutDesign } from '../memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutDesignStyle } from '../memorypack/RuntimeLayoutDesignStyle';
import { RuntimeLayoutHead } from '../memorypack/RuntimeLayoutHead';
import { RuntimeLayoutScreen } from '../memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutSession } from '../memorypack/RuntimeLayoutSession';
import { RuntimeLayoutSet } from '../memorypack/RuntimeLayoutSet';
import { RuntimeLayoutSettingGroup } from '../memorypack/RuntimeLayoutSettingGroup';
import { RuntimeLayoutSmartImage } from '../memorypack/RuntimeLayoutSmartImage';
import { RuntimeLayoutSmartImageRegion } from '../memorypack/RuntimeLayoutSmartImageRegion';
import { RuntimeLayoutSnapshot } from '../memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutText } from '../memorypack/RuntimeLayoutText';
import { RuntimeLayoutValue } from '../memorypack/RuntimeLayoutValue';
import { RuntimeLayoutVariable } from '../memorypack/RuntimeLayoutVariable';
import { RuntimeLayoutValueType } from './runtime-layout-value-type.enum';

export class RuntimeLayoutUtils {

  static mergeLayoutSnapshotUpdate(currentLayoutSnapshot: RuntimeLayoutSnapshot, updatedLayoutSnapshot: RuntimeLayoutSnapshot) {
    currentLayoutSnapshot.requestTick = updatedLayoutSnapshot.requestTick;
    currentLayoutSnapshot.snapshotTick = updatedLayoutSnapshot.snapshotTick;
    currentLayoutSnapshot.startedTick = updatedLayoutSnapshot.startedTick;

    RuntimeLayoutUtils.mergeLayout(currentLayoutSnapshot, updatedLayoutSnapshot);
    RuntimeLayoutUtils.mergeLayoutSession(currentLayoutSnapshot, updatedLayoutSnapshot);
  }

  private static mergeLayout(currentLayoutSnapshot: RuntimeLayoutSnapshot, updatedLayoutSnapshot: RuntimeLayoutSnapshot) {
    if (!currentLayoutSnapshot.runtimeLayout) return;
    if (!updatedLayoutSnapshot.runtimeLayout) return;

    // Use the UPDATED Snapshot as base (as all the properties get updated there)
    // but keep the current screens (which will get merged)
    const mergedLayout: RuntimeLayout = Object.assign(new RuntimeLayout(), updatedLayoutSnapshot.runtimeLayout);
    mergedLayout.layoutScreens = new Map<bigint, RuntimeLayoutScreen>(structuredClone(currentLayoutSnapshot.runtimeLayout.layoutScreens));

    // the texts never get deleted, they keep getting added to the stack...
    mergedLayout.texts = new Map<number, RuntimeLayoutText>(structuredClone(currentLayoutSnapshot.runtimeLayout.texts));
    for (const objectId of updatedLayoutSnapshot.runtimeLayout.texts?.keys() || []) {
      mergedLayout.texts.set(objectId, Object.assign(new RuntimeLayoutText(), updatedLayoutSnapshot.runtimeLayout.texts.get(objectId)));
    }

    // the smartImages never get deleted, they keep getting added to the stack...
    const mergedSmartImages = JSON.parse(JSON.stringify(currentLayoutSnapshot.runtimeLayout.smartImages || []), RuntimeLayoutUtils.layoutDesignsReviver);
    mergedLayout.smartImages = (mergedSmartImages || []).map(x => Object.assign(new RuntimeLayoutSmartImage(), x));

    // check for existing and update or add if new
    for (const smartImage of updatedLayoutSnapshot.runtimeLayout.smartImages || []) {
      const existingSI = mergedLayout.smartImages.find((rlsi: RuntimeLayoutSmartImage) => {
        return rlsi.smartImageGuidId === smartImage.smartImageGuidId;
      });
      if (existingSI) {
        for (const region of smartImage.regions || []) {
          const existingR = existingSI.regions.find((r: RuntimeLayoutSmartImageRegion) => {
            return r.regionGuidId === region.regionGuidId;
          })
          if (existingR) {
            Object.assign(existingR, region);
          } else {
            existingSI.regions.push(region);
          }
        }
        // Object.assign(existingLD, layoutDesign);
      } else {
        mergedLayout.smartImages.push(smartImage);
      }
    }

    // the layoutDesigns never get deleted, they keep getting added to the stack...
    const mergedLayoutDesigns = JSON.parse(JSON.stringify(currentLayoutSnapshot.runtimeLayout.layoutDesigns || []), RuntimeLayoutUtils.layoutDesignsReviver);
    mergedLayout.layoutDesigns = (mergedLayoutDesigns || []).map(x => Object.assign(new RuntimeLayoutDesign(), x));

    // check for existing and update or add if new
    for (const layoutDesign of updatedLayoutSnapshot.runtimeLayout.layoutDesigns || []) {
      const existingLD = mergedLayout.layoutDesigns.find((ld: RuntimeLayoutDesign) => {
        return ld.designGuidId === layoutDesign.designGuidId;
      });
      if (existingLD) {
        for (const designStyle of layoutDesign.designStyles || []) {
          const existingDS = existingLD.designStyles.find((ds: RuntimeLayoutDesignStyle) => {
            return ds.designStyleGuidId === designStyle.designStyleGuidId;
          })
          if (existingDS) {
            Object.assign(existingDS, designStyle);
          } else {
            existingLD.designStyles.push(designStyle);
          }
        }
        // Object.assign(existingLD, layoutDesign);
      } else {
        mergedLayout.layoutDesigns.push(layoutDesign);
      }
    }

    // Start the merge by removing deleted screens...
    const removedLayoutScreens = updatedLayoutSnapshot.runtimeLayout.removedLayoutScreens;
    for (const removedLayoutScreen of removedLayoutScreens || []) {
      mergedLayout.layoutScreens.delete(removedLayoutScreen.objectId);
    }

    // then check for new or updated screens
    const layoutScreens = new Map<bigint, RuntimeLayoutScreen>(structuredClone(updatedLayoutSnapshot.runtimeLayout.layoutScreens));
    for (const layoutScreenId of layoutScreens?.keys() || []) {
      if (!mergedLayout.layoutScreens.has(layoutScreenId)) {
        // new screen -> add entire object
        mergedLayout.layoutScreens.set(layoutScreenId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId));
        continue;
      }

      // updated screen
      // Use the updated screen as base (as all the properties get updated there)
      // but keep the current screen controls, sets and datas (which will get merged)
      mergedLayout.layoutScreens.set(layoutScreenId, Object.assign(new RuntimeLayoutScreen(), structuredClone(layoutScreens.get(layoutScreenId))));
      mergedLayout.layoutScreens.get(layoutScreenId).controls = currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).controls;
      if (currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).heads && Object.keys(currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).heads).length) {
        mergedLayout.layoutScreens.get(layoutScreenId).heads = currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).heads;
      } else {
        mergedLayout.layoutScreens.get(layoutScreenId).heads = new Map<bigint, RuntimeLayoutHead>();
      }

      // use the current layoutScreen sets/datas/variables, and merge on top of that below
      mergedLayout.layoutScreens.get(layoutScreenId).sets = new Map<bigint, RuntimeLayoutSet>(structuredClone(currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).sets));
      mergedLayout.layoutScreens.get(layoutScreenId).datas = new Map<bigint, RuntimeLayoutData>(structuredClone(currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).datas));
      mergedLayout.layoutScreens.get(layoutScreenId).variables = new Map<bigint, RuntimeLayoutVariable>(structuredClone(currentLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).variables));

      // REMOVE deleted inner stuff...
      for (const removedControl of layoutScreens.get(layoutScreenId).removedControls || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).controls.delete(removedControl.objectId);
      }
      for (const removedHead of layoutScreens.get(layoutScreenId).removedHeads || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).heads.delete(removedHead.objectId);
      }
      for (const removedSet of layoutScreens.get(layoutScreenId).removedSets || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).sets.delete(removedSet.objectId);
      }
      for (const removedData of layoutScreens.get(layoutScreenId).removedDatas || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).datas.delete(removedData.objectId);
      }
      // for (const removedVariable of layoutScreens.get(layoutScreenId).removedVariables || []) {
      //   mergedLayout.layoutScreens.get(layoutScreenId).variables.delete(removedVariable.objectId);
      // }

      // and ADD new inner stuff.
      for (const controlId of layoutScreens.get(layoutScreenId).controls?.keys() || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).controls.set(controlId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).controls.get(controlId));
      }
      for (const controlId of layoutScreens.get(layoutScreenId).heads?.keys() || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).heads.set(controlId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).heads.get(controlId));
      }
      for (const setId of layoutScreens.get(layoutScreenId).sets?.keys() || []) {
        if (mergedLayout.layoutScreens.get(layoutScreenId).sets.get(setId)?.datas) {
          mergedLayout.layoutScreens.get(layoutScreenId).sets.get(setId).tick = updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).sets.get(setId).tick;
          // if the set exists, we go one level deeper and merge the datas...
          // first REMOVE
          const removedDatas = layoutScreens.get(layoutScreenId).sets.get(setId).removedDatas;
          for (const removedData of removedDatas || []) {
            mergedLayout.layoutScreens.get(layoutScreenId).sets.get(setId).datas.delete(removedData.objectId);
          }
          // then ADD
          const datas = layoutScreens.get(layoutScreenId).sets.get(setId).datas;
          for (const dataId of datas?.keys() || []) {
            mergedLayout.layoutScreens.get(layoutScreenId).sets.get(setId).datas.set(dataId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).sets.get(setId).datas.get(dataId));
          }
        } else {
          mergedLayout.layoutScreens.get(layoutScreenId).sets.set(setId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).sets.get(setId));
        }
      }
      for (const dataId of layoutScreens.get(layoutScreenId).datas?.keys() || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).datas.set(dataId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).datas.get(dataId));
      }
      for (const variableId of layoutScreens.get(layoutScreenId).variables?.keys() || []) {
        mergedLayout.layoutScreens.get(layoutScreenId).variables.set(variableId, updatedLayoutSnapshot.runtimeLayout.layoutScreens.get(layoutScreenId).variables.get(variableId));
      }
    }

    Object.assign(currentLayoutSnapshot.runtimeLayout, mergedLayout);
  }

  private static mergeLayoutSession(currentLayoutSnapshot: RuntimeLayoutSnapshot, updatedLayoutSnapshot: RuntimeLayoutSnapshot) {
    currentLayoutSnapshot.runtimeLayoutSession = currentLayoutSnapshot.runtimeLayoutSession || new RuntimeLayoutSession();
    if (!updatedLayoutSnapshot.runtimeLayoutSession) return;

    // Use the UPDATED Snapshot as base (as all the properties get updated there)
    // but keep the current settingGroups (which will get merged)
    const mergedLayoutSession = Object.assign(new RuntimeLayoutSession(), updatedLayoutSnapshot.runtimeLayoutSession);
    mergedLayoutSession.settingGroups = new Map<bigint, RuntimeLayoutSettingGroup>(structuredClone(currentLayoutSnapshot.runtimeLayoutSession.settingGroups));

    // then check for new or updated settingGroups
    const updatedSettingGroups = new Map<bigint, RuntimeLayoutSettingGroup>(structuredClone(updatedLayoutSnapshot.runtimeLayoutSession.settingGroups));
    for (const settingGroupId of updatedSettingGroups?.keys()) {
      mergedLayoutSession.settingGroups.set(settingGroupId, updatedSettingGroups.get(settingGroupId));
    }

    Object.assign(currentLayoutSnapshot.runtimeLayoutSession, mergedLayoutSession);
  }

  private static layoutDesignsReviver(key: string, value: any) {
    if (key === 'objectId' || key === 'tick') return BigInt(value);
    else if (key.indexOf('Binary') < 0) return value;

    return new Uint8Array(Object.values(value));
  }

  static parseLayoutValue(obj: RuntimeLayoutValue, defaultValue?: any): any {
    const valueJson = (obj as any)?.ValueJson || obj?.valueJson;
    if (valueJson == null || valueJson === '') return defaultValue;

    return JSON.parse(valueJson);
  }

  static parseRV(obj: any, key: string, defaultValue?: any): any {
    if (!obj) return defaultValue;

    let value = null;
    const renderValues: Map<string, RuntimeLayoutValue> = obj.setComplexDataValues || obj.renderValues || obj.values;
    if (renderValues?.has(key)) {
      const rv = renderValues.get(key);
      value = rv.valueJson != null ? JSON.parse(rv.valueJson) : null;
      if (value != null && rv.valueTypeId === RuntimeLayoutValueType.Long) {
        value = BigInt(value);
      }
    }
    return value != null ? value : defaultValue;
  }

  static geoJsonFromBinary(geoJsonBinary: Uint8Array): any {
    const uint8Array = ungzip(geoJsonBinary);
    const decoder = new encoding.TextDecoder('utf8');
    return decoder.decode(uint8Array);
  }

}