<div class="height-100 flex-col">
  <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
    {{ layoutControl | parseRV:'ControlHeadlineText' }}
  </h2>

  <!-- the "dimension > 0 ? x : y" is an hack for Edge... -->
  <div
    [id]="mapId"
    class="smart-image flex-1"
    style="width: 100%">
  </div>
</div>
