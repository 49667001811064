import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Scan } from '../../models';
import { LogUtils } from '../../utils';
import { RingBuffer } from '../../utils/ring-buffer';



@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  ignoreEnterKeyInPrimaryLayoutControl: boolean;
  ignoreScanInPrimaryLayoutControl: boolean;
  private scannerSubject = new Subject<Scan>();
  private scannerRfidSubject = new Subject<Scan>();
  private scanQueue: RingBuffer<Scan>;

  constructor(
    private ngZone: NgZone,
  ) {
    this.scanQueue = new RingBuffer(50);
  }

  clear(): void {
    if (!this.scanQueue?.length) return;

    LogUtils.log(`Clearing ${this.scanQueue.length} items from the scan queue...`);
    this.scanQueue.length = 0;
  }

  getPendingScanCount(): number {
    return this.scanQueue?.length || 0;
  }

  getPendingScan(): Scan {
    if (!this.scanQueue?.length) return null;

    const pendingScan = this.scanQueue.splice(0, 1)[0];
    return pendingScan;
  }

  listen(): Observable<Scan> {
    return this.scannerSubject as Observable<Scan>;
  }

  listenRfid(): Observable<Scan> {
    return this.scannerRfidSubject as Observable<Scan>;
  }

  emitScan(scan: Scan) {
    if (!scan?.value) return;

    scan.value = scan.value.trim();

    scan.value = scan.value.replace(/(\r\n|\n|\r)/gm, '');

    this.ngZone.run(() => {
      if (this.scannerRfidSubject.observers.length && scan.source === 'RFID') {
        this.scannerRfidSubject.next(scan);
      }

      if (this.scannerSubject.observers.length) {
        this.scannerSubject.next(scan);
      } else if (this.scanQueue.indexOf(scan) < 0) {
        this.scanQueue.push(scan);
      }
    });
  }


}
