import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutSmartImageRegion {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    regionGuidId: string | null;
    name: string | null;
    geoJsonBinary: Uint8Array | null;
    hasChildSmartImage: boolean;
    childSmartImageGuidId: string | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.regionGuidId = null;
        this.name = null;
        this.geoJsonBinary = null;
        this.hasChildSmartImage = false;
        this.childSmartImageGuidId = null;

    }

    static serialize(value: RuntimeLayoutSmartImageRegion | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutSmartImageRegion | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(9);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.regionGuidId);
        writer.writeString(value.name);
        writer.writeUint8Array(value.geoJsonBinary);
        writer.writeBoolean(value.hasChildSmartImage);
        writer.writeString(value.childSmartImageGuidId);

    }

    static serializeArray(value: (RuntimeLayoutSmartImageRegion | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutSmartImageRegion | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutSmartImageRegion.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutSmartImageRegion | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutSmartImageRegion | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutSmartImageRegion();
        if (count == 9) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.regionGuidId = reader.readString();
            value.name = reader.readString();
            value.geoJsonBinary = reader.readUint8Array();
            value.hasChildSmartImage = reader.readBoolean();
            value.childSmartImageGuidId = reader.readString();

        }
        else if (count > 9) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.regionGuidId = reader.readString(); if (count == 5) return value;
            value.name = reader.readString(); if (count == 6) return value;
            value.geoJsonBinary = reader.readUint8Array(); if (count == 7) return value;
            value.hasChildSmartImage = reader.readBoolean(); if (count == 8) return value;
            value.childSmartImageGuidId = reader.readString(); if (count == 9) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutSmartImageRegion | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutSmartImageRegion | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutSmartImageRegion.deserializeCore(reader));
    }
}
