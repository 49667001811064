import { NgModule } from '@angular/core';
import { CurrencyV2Pipe, DecimalV2Pipe, PercentV2Pipe } from './number.pipe';
import { ParseRVPipe } from './parse-rv.pipe';
import { PluckPipe } from './pluck.pipe';
import { TranslatePipe } from './translate.pipe';




@NgModule({
  exports: [
    CurrencyV2Pipe,
    DecimalV2Pipe,
    PercentV2Pipe,

    ParseRVPipe,
    PluckPipe,
    TranslatePipe,
  ],
  imports: [
    CurrencyV2Pipe,
    DecimalV2Pipe,
    PercentV2Pipe,

    ParseRVPipe,
    PluckPipe,
    TranslatePipe,
  ],
})
export class SharedPipesModule { }
