<ion-header>
  <ion-toolbar>
    <ion-title>
        {{ 'Bluetooth' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button *ngIf="!isScanning"
        (click)="refresh()">
        {{ 'Scan' | translate }}
      </ion-button>
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="wrapper">
    <ion-grid class="grid flex-1">
      <ion-row class="row">
        <ion-col>
          <h2>
            {{ 'Connected Devices' | translate }}
            <ion-spinner *ngIf="isActive && !availableDevices"></ion-spinner>
          </h2>
          <ion-list>
            <ion-item *ngFor="let device of connectedDevices || []"
              [class.connected]="device.isConnected">

              <ion-icon slot="start"
                [name]="device.type === 'printer' || device.type === 'printer-sato' ? 'print' : device.type === 'scanner' ? 'scan' : device.type === 'thermometer' ? 'thermometer-outline' : 'help'"
                (click)="connectedDeviceActionClick(device)">
              </ion-icon>

              <div>
                <div>
                  {{ device.name || device.id }}
                </div>

                <small>
                  {{ device.id }} ({{ device.mode }})
                </small>
              </div>

              <ion-icon slot="end"
                name="close"
                (click)="disconnectFrom(device)">
              </ion-icon>

            </ion-item>

            <ion-item *ngIf="!connectedDevices || !connectedDevices.length"
              class="no-devices">
              <span>
                {{ '-- No devices connected --' | translate }}
              </span>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row class="row">
        <ion-col>
          <h2>
            {{ 'Available Devices' | translate }}
            <ion-spinner *ngIf="isActive && isScanning"></ion-spinner>
          </h2>
          <ion-list>
            <ion-item  *ngFor="let device of availableDevices || []"
              (click)="connectTo(device)">

              <div>
                <div>
                  {{ device.name || device.id || device.address }}
                </div>

                <small>
                  {{ device.id || device.address }} ({{ device.mode }})
                </small>
              </div>

              <ion-icon slot="end"
                name="bluetooth">
              </ion-icon>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

</ion-content>

<ngx-loading [show]="isBusy"></ngx-loading>
