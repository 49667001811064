import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DictNumber } from 'src/app/shared/models';
import { DesignStyleJson } from 'src/app/shared/models/studio/design-style-json.model';
import { VibrationService } from 'src/app/shared/services';
import { GeolocationService, TranslateService } from 'src/app/shared/services/app';
import { JsPluginLoaderService } from 'src/app/shared/services/js-plugin-loader/js-plugin-loader.service';
import { CaseUtils } from 'src/app/shared/utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { BaseComponent } from '../../../base/base.component';
import { GeoJSON } from 'src/app/shared/models/geojson.model';
import { Subscription } from 'rxjs';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutData } from 'src/app/shared/models/memorypack/RuntimeLayoutData';
import { RuntimeLayoutDesignStyle } from 'src/app/shared/models/memorypack/RuntimeLayoutDesignStyle';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
declare var L: any;
declare var turf: any;


@Component({
  selector: 'lc-control-list1-map',
  templateUrl: './control-list1-map.component.html',
  styleUrls: ['./control-list1-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlList1MapComponent extends BaseComponent {

  readonly mapId = GuidUtils.new();

  @Input() layoutControl: RuntimeLayoutControl;
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;
  @Input() listDefinition: any;
  @Input() listData: RuntimeLayoutData[];
  @Input() rowDefinition: any[];

  @Output() itemClick = new EventEmitter<any>();
  @Output() selectedTasksChange = new EventEmitter<any[]>();

  layerGroupMarkers: any;
  map: any;

  mapDesignStyleGuidId: string;
  mapDesignStyleOriginalGuidId: string;
  mapViewLocationFieldSolutionType: string;
  mapViewLocationFieldSubMemberGuidId: string;

  private popupButtonClick: Function;

  private watchPositionSubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private geolocationService: GeolocationService,
    private jsPluginLoaderService: JsPluginLoaderService,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    super();
  }

  ngOnInit() {
    this.jsPluginLoaderService.load('leaflet')
    .subscribe((isPluginLoaded: boolean) => {
      if (isPluginLoaded) {
        this.initMap();

        this.refresh();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.clearWatchSubscription();
  }

  refresh(options?: any) {
    if (!this.layoutControl) return;

    this.mapDesignStyleGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapDesignStylesGuidIds', '').split(',')[0];
    this.mapDesignStyleOriginalGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapDesignStylesOriginalGuidIds', '').split(',')[0];
    this.mapViewLocationFieldSolutionType = RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapViewLocationFieldSolutionType');
    this.mapViewLocationFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapViewLocationFieldSubMemberGuidId');

    this.addMarkers();

    if (Object.keys(this.layerGroupMarkers._layers).length) this.map.fitBounds(this.layerGroupMarkers.getBounds());

    this.cdr.markForCheck();
  }

  private initMap() {
    try {
      const swedenCoords = [58.0082, 16.4435];
      this.map = L.map(this.mapId).setView(swedenCoords, 6);

      this.map.on('popupopen', (e) => {
        setTimeout(() => {
          const button = document.querySelector('.button-select');
          if (!button) return;

          button.addEventListener('click', (ev: Event) => {
            (ev.target as HTMLButtonElement).disabled = true;
            if (this.popupButtonClick) this.popupButtonClick(e);
          });
        }, 250);
      });

      L.control.locateEx({
        position: 'bottomright',
        flyTo: true,
        icon: 'locate',
        iconLoading: 'locate spin',
        iconElementTag: 'div',
        locateOptions: {
          maxZoom: 15,
        },
        startOnInit: RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapShowCurrentLocation', false),
        watchLocation: (locationCallback) => {
          this.geolocationService.start();
          this.watchPositionSubscription = this.geolocationService.watchPosition()
          .subscribe((geoJSON: GeoJSON) => {
            if (!geoJSON) return;

            const latlng = geoJSON.geometry.coordinates.slice(0).reverse();
            locationCallback({
              accuracy: geoJSON.properties.accuracy,
              bounds: L.latLngBounds([latlng]),
              latlng: {
                lat: latlng[0],
                lng: latlng[1],
              },
            });
          });
        },
        clearWatchLocation: () => {
          this.clearWatchSubscription();
        },
      }).addTo(this.map);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18 }).addTo(this.map);
      this.initLayers();
    } catch(ex) {
      console.warn(`Trying to initialize map but couldn't find container with ID: '${this.mapId}'...it may have been disposed.`);
    }
  }

  initLayers() {
    this.layerGroupMarkers = new L.FeatureGroup();
    this.map.addLayer(this.layerGroupMarkers);
  }

  private clearWatchSubscription() {
    if (this.watchPositionSubscription) {
      this.watchPositionSubscription.unsubscribe();
      this.watchPositionSubscription = null;
    }
    this.geolocationService.stop();
  }

  private addMarkers() {
    for (const layoutData of this.listData || []) {
      if (!layoutData) continue;

      const value = RuntimeLayoutUtils.parseRV(layoutData, this.mapViewLocationFieldSubMemberGuidId, '{}');
      const coordinates = JSON.parse(value)?.coordinates?.reverse();
      if (!coordinates) continue;

      const color = 'gray';
      const iconOptions = {
        color: 'gray',
        fillColor: color,
        fillOpacity: 0.8,
        fontSize: 12,
        circleColor: color,
        circleFillColor: 'white',
        circleRatio: 0.65,
        circleText: '',
        weight: 1,
      };
      const latlng = L.latLng(coordinates);
      const marker = L.marker.svgMarker(latlng, { iconOptions: iconOptions });
      marker.data = layoutData;

      const designStyle = this.getDesignStyle(this.mapDesignStyleOriginalGuidId || this.mapDesignStyleGuidId);
      let designStyleItemHtml = '';
      for (const item of designStyle?.items || []) {
        designStyleItemHtml +=
`<div class="grid-item pointer padding" style="${this.convertStyleObjectToHtmlString(item.itemStyle)}">
  <span class="label" style="${this.convertStyleObjectToHtmlString(item.labelStyle)}">
    ${RuntimeLayoutUtils.parseRV(layoutData, GuidUtils.clean(item.field.subVariableMemberGuidId || item.field.originalVariableGuidId), '')}
  </span>
</div>`;
      }

      this.addClickEventToMarker(
        marker,
`<div class="grid-container background"
  style="${this.convertStyleObjectToHtmlString(designStyle?.style)}">
  ${designStyleItemHtml}
</div>`);

      marker.addTo(this.layerGroupMarkers);
    }
  }

  private getDesignStyle(designStyleGuidId: string): any {
    if (!designStyleGuidId) return undefined;

    for (const design of this.layoutDesigns || []) {
      const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
        return GuidUtils.clean(lds.originalDesignStyleGuidId || lds.designStyleGuidId) === GuidUtils.clean(designStyleGuidId);
      });
      if (designStyle) return DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary);
    }

    return undefined;
  }

  private convertStyleObjectToHtmlString(style: CSSStyleDeclaration): string {
    if (!style) return '';

    let result = '';
    const toSnakeStyle = CaseUtils.toSnake(style);
    for (const key of Object.keys(toSnakeStyle)) {
      result += key.replace(/_/g, '-') + ': ' + toSnakeStyle[key] + '; ';
    }
    return result;
  }

  private addClickEventToMarker(layer: any, content: string) {
    layer.on('click', (e) => {
      L.DomEvent.stopPropagation(e);
      this.vibrationService.vibrate();

      const popup = new L.Popup({ offset: new L.Point(0,-28) });
      popup.setLatLng(e.latlng);
      popup.setContent(
        `${content}
        <button class="button-select">${this.translateService.instant('Select')}</button>`
      );

      this.popupButtonClick = (e) => {
        this.itemClick.emit(layer.data);
      };

      this.map.panTo(e.latlng);
      this.map.openPopup(popup);

      this.cdr.markForCheck();
    });
  }

}
