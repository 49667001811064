import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutDesignStyle {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    designStyleGuidId: string | null;
    name: string | null;
    originalDesignStyleGuidId: string | null;
    notClickable: boolean;
    designStyleBehaviourVersion: number;
    fillArea: boolean;
    styleJsonBinary: Uint8Array | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.designStyleGuidId = null;
        this.name = null;
        this.originalDesignStyleGuidId = null;
        this.notClickable = false;
        this.designStyleBehaviourVersion = 0;
        this.fillArea = false;
        this.styleJsonBinary = null;

    }

    static serialize(value: RuntimeLayoutDesignStyle | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutDesignStyle | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(11);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.designStyleGuidId);
        writer.writeString(value.name);
        writer.writeString(value.originalDesignStyleGuidId);
        writer.writeBoolean(value.notClickable);
        writer.writeInt32(value.designStyleBehaviourVersion);
        writer.writeBoolean(value.fillArea);
        writer.writeUint8Array(value.styleJsonBinary);

    }

    static serializeArray(value: (RuntimeLayoutDesignStyle | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutDesignStyle | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutDesignStyle.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutDesignStyle | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutDesignStyle | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutDesignStyle();
        if (count == 11) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.designStyleGuidId = reader.readString();
            value.name = reader.readString();
            value.originalDesignStyleGuidId = reader.readString();
            value.notClickable = reader.readBoolean();
            value.designStyleBehaviourVersion = reader.readInt32();
            value.fillArea = reader.readBoolean();
            value.styleJsonBinary = reader.readUint8Array();

        }
        else if (count > 11) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.designStyleGuidId = reader.readString(); if (count == 5) return value;
            value.name = reader.readString(); if (count == 6) return value;
            value.originalDesignStyleGuidId = reader.readString(); if (count == 7) return value;
            value.notClickable = reader.readBoolean(); if (count == 8) return value;
            value.designStyleBehaviourVersion = reader.readInt32(); if (count == 9) return value;
            value.fillArea = reader.readBoolean(); if (count == 10) return value;
            value.styleJsonBinary = reader.readUint8Array(); if (count == 11) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutDesignStyle | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutDesignStyle | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutDesignStyle.deserializeCore(reader));
    }
}
