import { Pipe, PipeTransform } from '@angular/core';
import { RuntimeLayoutUtils } from '../models/runtime-layout/runtime-layout.utils';


@Pipe({
  name: 'parseRV',
  standalone: true,
})
export class ParseRVPipe implements PipeTransform {
  transform (obj: any, key: string, defaultValue?: any): any {
    return RuntimeLayoutUtils.parseRV(obj, key, defaultValue);
  }
}