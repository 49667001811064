import { Injectable, Injector } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { Observable, Observer, of } from 'rxjs';
import { BrowserUtils, LogUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';


export interface PointMobilePluginSettings {
}


@Injectable({
  providedIn: 'root'
})
export class PointMobilePlugin extends BasePlugin {

  private readonly intentAction = 'device.scanner.EVENT';

  private isPluginAllowedChecked: boolean;
  private settings: PointMobilePluginSettings;

  constructor(
    private device: Device,
    injector: Injector,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'PointMobilePlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    const manufacturer = this.platform.is('cordova') ? (this.device.manufacturer || '').toLowerCase() : '';
    return BrowserUtils.isDeviceApp() && manufacturer.indexOf('pointmobile') >= 0;
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on PointMobile device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.registerScannerCallback();

    return of(null);
  }

  private registerScannerCallback(): void {
    this.log('Registering to receive scanner data...');
    (window as any).plugins.intentShim.unregisterBroadcastReceiver();
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        this.intentAction,
      ],
      filterCategories: [
        'android.intent.category.DEFAULT',
      ]},
      this.handleScannerIntent.bind(this)
    );
  }

  private handleScannerIntent(intent: any): void {
    intent.extras = intent.extras || {};
    // LogUtils.log('PointMobile Intent: ', intent);
    if (intent.action !== this.intentAction) return;

    const scanResult = intent.extras['EXTRA_EVENT_DECODE_RESULT'];
    if (!scanResult) return;

    const value = intent.extras['EXTRA_EVENT_DECODE_STRING_VALUE'];
    const valueType = intent.extras['EXTRA_EVENT_SYMBOL_NAME'];
    this.scannerService.emitScan({
      source: 'SCANNER',
      value: value,
      valueType: valueType,
    });
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on PointMobile device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'enable_scanner':
        this.log('Enabling scanner...');
        this.sendBroadcast('EXTRA_ENABLED_SCANNER', 1);
        return of(null);
      case 'disable_scanner':
        this.log('Disabling scanner...');
        this.sendBroadcast('EXTRA_ENABLED_SCANNER', 0);
        return of(null);
      case 'toggle_soft_scan':
        // this.log('Toggle soft scanner state...');
        // this.sendBroadcast('com.symbol.datawedge.api.SOFT_SCAN_TRIGGER', 'TOGGLE_SCANNING');
        return of(null);
      default:
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on PointMobile device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on PointMobile device...');
    }

    return of({
      enabled: this.isPluginAllowed(),
      log: Array.from(this.logRingBuffer),
    });
  }

  private sendBroadcast(extraName: string, extraValue: any,) {
    (window as any).plugins.intentShim.sendBroadcast({
      action: 'device.common.ENABLED_SCANNER',
      extras: {
        [extraName]: extraValue,
        'SEND_RESULT': 'false'
      }
    },
      () => { }, // Success in sending the intent, not success of DW to process the intent.
      () => { }, // Failure in sending the intent, not failure of DW to process the intent.
    );
  }

}
