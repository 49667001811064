import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models/runtime-layout/solution-device-control-scanner-enabled-type.enum';
import { AudioService, BasePlugin, PluginType } from 'src/app/shared/services';
import { JsPluginLoaderService } from 'src/app/shared/services/js-plugin-loader/js-plugin-loader.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { LogUtils, RingBuffer } from 'src/app/shared/utils';
import { Notification, Scan } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
declare var Gauge: any;

// https://github.com/martinr1000/AliensMotionTracker/tree/master/resources

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-control-rfid-locate',
  templateUrl: 'control-rfid-locate.component.html',
  styleUrls: ['./control-rfid-locate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRfidLocateComponent extends ControlBaseComponent implements OnInit, OnDestroy {

  private readonly ringBufferSize = 5;

  theme: string;

  cipherLabPlugin: BasePlugin;
  clearTimeout: any;
  epc: string;
  listenRfidSubscription: Subscription;
  rssi: number;
  private rssiRingBuffer: RingBuffer<number>;
  tagBottomPosition: number;
  tid: string;
  trackingSoundEnabled: boolean = true;

  constructor(
    private audioService: AudioService,
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private jsPluginLoaderService: JsPluginLoaderService,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
    this.rssiRingBuffer = RingBuffer.fromPlain([], this.ringBufferSize);
  }

  ngOnInit() {
    this.tagBottomPosition = 1000;

    // setTimeout(() => {
    //   this.rssi = -99;
    //   this.updateGauge();
    //   setTimeout(() => {
    //     this.rssi = -30;
    //     this.updateGauge();
    //     setTimeout(() => {
    //       this.rssi = -51;
    //       this.updateGauge();
    //       setTimeout(() => {
    //         this.rssi = -48;
    //         this.updateGauge();
    //         setTimeout(() => {
    //           this.rssi = -32;
    //           this.updateGauge();
    //         }, 1000)
    //       }, 1000)
    //     }, 1000)
    //   }, 1000)
    // }, 1000);

    this.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (!this.cipherLabPlugin.isPluginAllowed()) return;

    this.cipherLabPlugin.action({ command: 'enable_notification' }).subscribe();
  }

  refresh() {
    this.epc = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Epc');
    this.tid = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Tid');
    if (!this.epc && !this.tid) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Tag ID not specified (nor EPC).'),
        type: RuntimeLayoutNotifyType.Alert,
      }));
      return;
    }

    this.cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    if (!this.cipherLabPlugin.isPluginAllowed()) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Not running on CipherLab device...'),
        type: RuntimeLayoutNotifyType.Alert,
      }));
      return;
    }

    this.playTrackingSound();

    this.cipherLabPlugin.action({ command: 'has_rfid' })
    .subscribe((result: boolean) => {
      if (!result) {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('CipherLab RFID Pistol not available.'),
          type: RuntimeLayoutNotifyType.Alert,
        }));
        return;
      }

      LogUtils.log('Searching for tagId: ' + this.tid);
      this.cipherLabPlugin.action({ command: 'disable_notification' }).subscribe();
      // this.cipherLabPlugin.action({ command: 'toggle_soft_scan' }).subscribe();

      if (this.listenRfidSubscription && !this.listenRfidSubscription.closed) return;

      this.listenRfidSubscription = this.scannerService.listenRfid()
      .subscribe((scan: Scan) => {
        if (!scan || scan.tagId.toLowerCase() !== (this.tid || '').toLowerCase()) return;
        this.rssiRingBuffer.push(scan.rssi || -99);

        this.rssi = 0;
        for (const previousRssi of this.rssiRingBuffer) {
          this.rssi += previousRssi;
        }
        this.rssi = this.rssi / this.rssiRingBuffer.length;

        this.updateGauge();
      });
      this.subscriptions.push(this.listenRfidSubscription);
    });
  }

  private playTrackingSound() {
    if (this.isDestroyed) return;

    setTimeout(() => {
      if (this.trackingSoundEnabled) this.audioService.play('rfidlocate');
      this.playTrackingSound();
    }, 1000);
  }

  private updateGauge() {
    if (this.clearTimeout) {
      clearTimeout(this.clearTimeout);
      this.clearTimeout = null;
    }

    this.tagBottomPosition = this.translateRssi(this.rssi);
    // this.audioService.play('rfidlocatehit');
    this.cdr.markForCheck();

    this.clearTimeout = setTimeout(() => {
      this.tagBottomPosition = 1000;
      this.cdr.markForCheck();
    }, 5 * 1000);
  }

  private translateRssi(rssi: number): number {
    // typical range from -30 to -85dBm
    const minRssi = -85;
    const maxRssi = -35;
    const minTarget = 0;
    const maxTarget = 210;
    const clampedRssi = Math.max(Math.min(rssi, maxRssi), minRssi);

    // Calculate scaling factor based on valid range
    const scalingFactor = (maxTarget - minTarget) / (Math.abs(minRssi) - Math.abs(maxRssi));

    // Apply linear transformation
    const translatedValue = scalingFactor * (clampedRssi + Math.abs(maxRssi));

    return 60 + Math.abs(~~translatedValue);
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

}

