import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";

export class LayoutResourceResponseMessage {
    messageType: LayoutMessageType;
    resourceId: number;
    guidId: string | null;
    tick: bigint;
    contentType: string | null;
    totalSize: bigint;
    success: boolean;
    notExists: boolean;
    notSupported: boolean;
    chunkId: bigint;
    chunkSize: bigint;
    chunkBinary: Uint8Array | null;

    constructor() {
        this.messageType = 0;
        this.resourceId = 0;
        this.guidId = null;
        this.tick = 0n;
        this.contentType = null;
        this.totalSize = 0n;
        this.success = false;
        this.notExists = false;
        this.notSupported = false;
        this.chunkId = 0n;
        this.chunkSize = 0n;
        this.chunkBinary = null;

    }

    static serialize(value: LayoutResourceResponseMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutResourceResponseMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(12);
        writer.writeInt32(value.messageType);
        writer.writeInt32(value.resourceId);
        writer.writeString(value.guidId);
        writer.writeInt64(value.tick);
        writer.writeString(value.contentType);
        writer.writeInt64(value.totalSize);
        writer.writeBoolean(value.success);
        writer.writeBoolean(value.notExists);
        writer.writeBoolean(value.notSupported);
        writer.writeInt64(value.chunkId);
        writer.writeInt64(value.chunkSize);
        writer.writeUint8Array(value.chunkBinary);

    }

    static serializeArray(value: (LayoutResourceResponseMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutResourceResponseMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutResourceResponseMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutResourceResponseMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutResourceResponseMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutResourceResponseMessage();
        if (count == 12) {
            value.messageType = reader.readInt32();
            value.resourceId = reader.readInt32();
            value.guidId = reader.readString();
            value.tick = reader.readInt64();
            value.contentType = reader.readString();
            value.totalSize = reader.readInt64();
            value.success = reader.readBoolean();
            value.notExists = reader.readBoolean();
            value.notSupported = reader.readBoolean();
            value.chunkId = reader.readInt64();
            value.chunkSize = reader.readInt64();
            value.chunkBinary = reader.readUint8Array();

        }
        else if (count > 12) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageType = reader.readInt32(); if (count == 1) return value;
            value.resourceId = reader.readInt32(); if (count == 2) return value;
            value.guidId = reader.readString(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.contentType = reader.readString(); if (count == 5) return value;
            value.totalSize = reader.readInt64(); if (count == 6) return value;
            value.success = reader.readBoolean(); if (count == 7) return value;
            value.notExists = reader.readBoolean(); if (count == 8) return value;
            value.notSupported = reader.readBoolean(); if (count == 9) return value;
            value.chunkId = reader.readInt64(); if (count == 10) return value;
            value.chunkSize = reader.readInt64(); if (count == 11) return value;
            value.chunkBinary = reader.readUint8Array(); if (count == 12) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutResourceResponseMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutResourceResponseMessage | null)[] | null {
        return reader.readArray(reader => LayoutResourceResponseMessage.deserializeCore(reader));
    }
}
