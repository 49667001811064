import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";

export class LayoutResourceUploadResponseMessage {
    messageType: LayoutMessageType;
    guidId: string | null;
    tick: bigint;
    success: boolean;
    successComplete: boolean;
    failedReupload: boolean;

    constructor() {
        this.messageType = 0;
        this.guidId = null;
        this.tick = 0n;
        this.success = false;
        this.successComplete = false;
        this.failedReupload = false;

    }

    static serialize(value: LayoutResourceUploadResponseMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutResourceUploadResponseMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(6);
        writer.writeInt32(value.messageType);
        writer.writeString(value.guidId);
        writer.writeInt64(value.tick);
        writer.writeBoolean(value.success);
        writer.writeBoolean(value.successComplete);
        writer.writeBoolean(value.failedReupload);

    }

    static serializeArray(value: (LayoutResourceUploadResponseMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutResourceUploadResponseMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutResourceUploadResponseMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutResourceUploadResponseMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutResourceUploadResponseMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutResourceUploadResponseMessage();
        if (count == 6) {
            value.messageType = reader.readInt32();
            value.guidId = reader.readString();
            value.tick = reader.readInt64();
            value.success = reader.readBoolean();
            value.successComplete = reader.readBoolean();
            value.failedReupload = reader.readBoolean();

        }
        else if (count > 6) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageType = reader.readInt32(); if (count == 1) return value;
            value.guidId = reader.readString(); if (count == 2) return value;
            value.tick = reader.readInt64(); if (count == 3) return value;
            value.success = reader.readBoolean(); if (count == 4) return value;
            value.successComplete = reader.readBoolean(); if (count == 5) return value;
            value.failedReupload = reader.readBoolean(); if (count == 6) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutResourceUploadResponseMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutResourceUploadResponseMessage | null)[] | null {
        return reader.readArray(reader => LayoutResourceUploadResponseMessage.deserializeCore(reader));
    }
}
