<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'Linking' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="height-100 flex-col center middle">
    <h2>
      {{ 'Use this to link device' | translate }}:
    </h2>

    <canvas id="qrcode-canvas">
    </canvas>
  </div>
</ion-content>
