import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DictString } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';


@Component({
  selector: 'lc-control-location1',
  templateUrl: 'control-location1.component.html',
  styleUrls: ['./control-location1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlLocation1Component extends ControlBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly defaultTimeoutInSec = 30;

  private previousNow: number;
  theme: string;
  private timeout: number;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.timeout = RuntimeLayoutUtils.parseRV(this.layoutControl, 'TimeoutInSeconds', this.defaultTimeoutInSec) * 1000;
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.start();

    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  start() {
    this.previousNow = Date.now();
    this.checkForLocation();
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    context.set('GeoJson', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  private checkForLocation() {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    const location = this.geolocationService.getLastKnownPosition();
    if (this.geolocationService.noAccess) {
      eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify('NoAccess'),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    } else if (location) {
      eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify('Location'),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    } else if (Date.now() - this.previousNow > this.timeout) {
      eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify('NoFix'),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    } else {
      setTimeout(() => {
        this.checkForLocation();
      }, 100);
      return;
    }

    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

}

