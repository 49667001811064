import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { GestureController } from '@ionic/core/dist/collection/utils/gesture/gesture-controller';
import { KeyboardType } from '../../models/keyboard-type.enum';
import { ScannerService } from '../../services';
import { TranslateService } from '../../services/app';
import { KeyboardService } from '../keyboard';
import { RuntimeLayout } from '../../models/memorypack/RuntimeLayout';
import { SolutionDeviceControlScannerEnabledFlagType } from '../../models/runtime-layout/solution-device-control-scanner-enabled-type.enum';
declare var Hammer: any;



@Component({
  selector: 'lc-scanner-drawer',
  templateUrl: 'scanner-drawer.component.html',
  styleUrls: ['scanner-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerDrawerComponent implements OnInit, OnDestroy {

  @Input() layout: RuntimeLayout;
  @Input() set scannerEnabledType(value: SolutionDeviceControlScannerEnabledFlagType) {
    this._scannerEnabledType = value;
    this.refresh();
  }
  get scannerEnabledType(): SolutionDeviceControlScannerEnabledFlagType {
    return this._scannerEnabledType;
  }
  private _scannerEnabledType: SolutionDeviceControlScannerEnabledFlagType;

  isDrawerOpen: boolean;
  staticControl: any;

  private swipeGesture: GestureController;
  private onSwipeDownBind = this.onSwipeDown.bind(this);
  private onSwipeUpBind = this.onSwipeUp.bind(this);

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private keyboardService: KeyboardService,
    private translateService: TranslateService,
    private scannerService: ScannerService,
  ) {

  }

  ngOnInit() {
    this.initSwipeGestures();

    this.refresh();
  }

  ngOnDestroy() {
    this.swipeGesture.off('swipedown', this.onSwipeDownBind);
    this.swipeGesture.off('swipeup', this.onSwipeUpBind);
  }

  refresh() {
    let drawerScannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.NotActive;
    if (
      (this.scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.Simple) === SolutionDeviceControlScannerEnabledFlagType.Simple ||
      this.scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacySimpleBarcode // Legacy
    ) {
      drawerScannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Simple | SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner | SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner;
    } else if (
      (this.scannerEnabledType & SolutionDeviceControlScannerEnabledFlagType.Advanced) === SolutionDeviceControlScannerEnabledFlagType.Advanced ||
      this.scannerEnabledType === SolutionDeviceControlScannerEnabledFlagType.LegacyAdvancedBarcode // Legacy
    ) {
      drawerScannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Advanced | SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner | SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner;
    }

    this.staticControl = {
      controlHeadlineEnabled: false,
      controlHeadlineText: this.translateService.instant('Barcode Scan') + ':',
      scannerEnabledType: drawerScannerEnabledType,
      keyboardType: KeyboardType.AlphaNumericWithEnter,
      outputText: this.translateService.instant('(scan a barcode or manually enter a value)'),
    };

    this.cdr.markForCheck();
  }

  private initSwipeGestures(): void {
    this.swipeGesture = new Hammer.Manager(this.el.nativeElement, {
      recognizers: [
        [Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }]
      ]
    });
    // this.swipeGesture.listen();
    this.swipeGesture.on('swipedown', this.onSwipeDownBind);
    this.swipeGesture.on('swipeup', this.onSwipeUpBind);
  }

  private onSwipeDown() {
    this.toggleDrawer(true);
  }

  private onSwipeUp() {
    this.toggleDrawer(false);
  }

  toggleDrawer(forceState?: boolean) {
    setTimeout(() => {
      if (forceState != null) {
        this.isDrawerOpen = forceState;
      } else {
        this.isDrawerOpen = !this.isDrawerOpen;
      }
      this.scannerService.ignoreEnterKeyInPrimaryLayoutControl = this.isDrawerOpen;
      this.keyboardService.hide();
      this.cdr.markForCheck();
    }, 10);
  }

}
