import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EnrollMenuPopover } from './enroll-menu.popover';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        EnrollMenuPopover,
    ]
})
export class EnrollMenuPopoverModule { }
