import { NgModule } from '@angular/core';
import { ConfirmPopoverModule } from 'src/app/popovers/confirm/confirm.popover.module';
import { LoadingModule } from 'src/app/shared/components';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsModal } from './settings.modal';



@NgModule({
    imports: [
        ConfirmPopoverModule,
        LoadingModule,
        SharedModule,
    ],
    declarations: [
        SettingsModal,
    ]
})
export class SettingsModalModule { }
