import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutLoad {
    tick: bigint;
    controlObjectId: bigint;
    loadGuidId: string | null;
    loadType: number;
    value: number;
    text: string | null;

    constructor() {
        this.tick = 0n;
        this.controlObjectId = 0n;
        this.loadGuidId = null;
        this.loadType = 0;
        this.value = 0;
        this.text = null;

    }

    static serialize(value: RuntimeLayoutLoad | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutLoad | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(6);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.controlObjectId);
        writer.writeString(value.loadGuidId);
        writer.writeInt32(value.loadType);
        writer.writeFloat32(value.value);
        writer.writeString(value.text);

    }

    static serializeArray(value: (RuntimeLayoutLoad | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutLoad | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutLoad.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutLoad | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutLoad | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutLoad();
        if (count == 6) {
            value.tick = reader.readInt64();
            value.controlObjectId = reader.readInt64();
            value.loadGuidId = reader.readString();
            value.loadType = reader.readInt32();
            value.value = reader.readFloat32();
            value.text = reader.readString();

        }
        else if (count > 6) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.tick = reader.readInt64(); if (count == 1) return value;
            value.controlObjectId = reader.readInt64(); if (count == 2) return value;
            value.loadGuidId = reader.readString(); if (count == 3) return value;
            value.loadType = reader.readInt32(); if (count == 4) return value;
            value.value = reader.readFloat32(); if (count == 5) return value;
            value.text = reader.readString(); if (count == 6) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutLoad | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutLoad | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutLoad.deserializeCore(reader));
    }
}
