import { Injectable, NgZone } from '@angular/core';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { BrowserUtils } from '../../utils';
import { LocalSettingsService } from '../local-settings/local-settings.service';



@Injectable({
  providedIn: 'root'
})
export class VibrationService {

  constructor(
    private localSettingsService: LocalSettingsService,
    private ngZone: NgZone,
    private platform: Platform,
    private vibration: Vibration,
  ) { }

  vibrate(skipIOS?: boolean) {
    if (!BrowserUtils.isDeviceApp()) return;
    if (!this.localSettingsService.get().vibration) return;
    if (skipIOS && this.platform.is('ios')) return;

    this.ngZone.runOutsideAngular(() => {
      this.vibration.vibrate(
        this.platform.is('android') ? environment.android.vibratePattern :
        this.platform.is('ios') ? environment.ios.vibratePattern :
        50
      );
    });
  }

}
