export enum RuntimeLayoutValueType {

  String = 1,
  Bool = 2,
  Int = 3,
  Long = 4,
  Double = 5,
  Float = 6

}
