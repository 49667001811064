import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutValue } from "./RuntimeLayoutValue";
import { RuntimeLayoutControlAction } from "./RuntimeLayoutControlAction";
import { RuntimeLayoutControlTrigger } from "./RuntimeLayoutControlTrigger";

export class RuntimeLayoutControl {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    runtimeContainerObjectId: bigint;
    runtimeControlObjectId: bigint;
    solutionDeviceControlTypeGuidId: string | null;
    layoutControlCode: string | null;
    renderValues: Map<string | null, RuntimeLayoutValue | null> | null;
    actions: (RuntimeLayoutControlAction | null)[] | null;
    triggers: (RuntimeLayoutControlTrigger | null)[] | null;
    backButton: boolean;
    forwardButton: boolean;
    scannerEnabledType: number;
    scannerTypes: number;
    buttonsType: number;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.runtimeContainerObjectId = 0n;
        this.runtimeControlObjectId = 0n;
        this.solutionDeviceControlTypeGuidId = null;
        this.layoutControlCode = null;
        this.renderValues = null;
        this.actions = null;
        this.triggers = null;
        this.backButton = false;
        this.forwardButton = false;
        this.scannerEnabledType = 0;
        this.scannerTypes = 0;
        this.buttonsType = 0;

    }

    static serialize(value: RuntimeLayoutControl | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutControl | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(16);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.runtimeContainerObjectId);
        writer.writeInt64(value.runtimeControlObjectId);
        writer.writeString(value.solutionDeviceControlTypeGuidId);
        writer.writeString(value.layoutControlCode);
        writer.writeMap(value.renderValues, (writer, x) => writer.writeString(x), (writer, x) => RuntimeLayoutValue.serializeCore(writer, x));
        writer.writeArray(value.actions, (writer, x) => RuntimeLayoutControlAction.serializeCore(writer, x));
        writer.writeArray(value.triggers, (writer, x) => RuntimeLayoutControlTrigger.serializeCore(writer, x));
        writer.writeBoolean(value.backButton);
        writer.writeBoolean(value.forwardButton);
        writer.writeInt32(value.scannerEnabledType);
        writer.writeInt32(value.scannerTypes);
        writer.writeInt32(value.buttonsType);

    }

    static serializeArray(value: (RuntimeLayoutControl | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutControl | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutControl.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutControl | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutControl | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutControl();
        if (count == 16) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.runtimeContainerObjectId = reader.readInt64();
            value.runtimeControlObjectId = reader.readInt64();
            value.solutionDeviceControlTypeGuidId = reader.readString();
            value.layoutControlCode = reader.readString();
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader));
            value.actions = reader.readArray(reader => RuntimeLayoutControlAction.deserializeCore(reader));
            value.triggers = reader.readArray(reader => RuntimeLayoutControlTrigger.deserializeCore(reader));
            value.backButton = reader.readBoolean();
            value.forwardButton = reader.readBoolean();
            value.scannerEnabledType = reader.readInt32();
            value.scannerTypes = reader.readInt32();
            value.buttonsType = reader.readInt32();

        }
        else if (count > 16) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.runtimeContainerObjectId = reader.readInt64(); if (count == 5) return value;
            value.runtimeControlObjectId = reader.readInt64(); if (count == 6) return value;
            value.solutionDeviceControlTypeGuidId = reader.readString(); if (count == 7) return value;
            value.layoutControlCode = reader.readString(); if (count == 8) return value;
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader)); if (count == 9) return value;
            value.actions = reader.readArray(reader => RuntimeLayoutControlAction.deserializeCore(reader)); if (count == 10) return value;
            value.triggers = reader.readArray(reader => RuntimeLayoutControlTrigger.deserializeCore(reader)); if (count == 11) return value;
            value.backButton = reader.readBoolean(); if (count == 12) return value;
            value.forwardButton = reader.readBoolean(); if (count == 13) return value;
            value.scannerEnabledType = reader.readInt32(); if (count == 14) return value;
            value.scannerTypes = reader.readInt32(); if (count == 15) return value;
            value.buttonsType = reader.readInt32(); if (count == 16) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutControl | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutControl | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutControl.deserializeCore(reader));
    }
}
