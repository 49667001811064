import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { LogUtils } from '../../utils';
import { EnrollService } from '../api';
import { OpsStatsService, OpsStatsType } from './ops-stats.service';
import { PingService } from '../protocol/ping.service';
import { DeviceEnrollment3 } from '../../models/memorypack/DeviceEnrollment3';
import { LayoutState } from '../../models/layout-state.model';
const { name, version } = require('../../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  private defaultHeartbeatIntervalInMs: number;

  private appService: AppService;
  private heartbeatTimeout: any;

  constructor(
    private enrollService: EnrollService,
    private opsStatsService: OpsStatsService,
    private pingService: PingService,
  ) {
  }

  init(appService: AppService) {
    this.appService = appService;

    const windowAny: any = window;
    this.defaultHeartbeatIntervalInMs = windowAny.mobileEngine?.operationalMode === 'hybrid' ? 5 * 1000 : 30 * 1000;

    if (windowAny.mobileEngine?.operationalMode !== 'hybrid') return;

    windowAny.mobileEngine.layoutStateChange = (layoutState: LayoutState) => {
      this.appService.emitMobileEngineLayoutStateChange(layoutState);
    }
  }

  scheduleNextHeartbeat(timeoutInMs = this.defaultHeartbeatIntervalInMs) {
    this.stopHeartbeat();

    this.heartbeatTimeout = setTimeout(() => {
      this.heartBeat();
    }, timeoutInMs);
  }

  stopHeartbeat() {
    if (!this.heartbeatTimeout) return;

    clearTimeout(this.heartbeatTimeout);
    this.heartbeatTimeout = undefined;
  }

  heartBeat() {
    if (!this.heartbeatTimeout) return;

    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'hybrid') {
      if (!windowAny.mobileEngine.instance) {
        setTimeout(() => {
          this.heartBeat();
        }, 100);
        return;
      }

      windowAny.mobileEngine.instance.invokeMethodAsync('GetStateAsync')
      .then((layoutState: LayoutState) => {
        this.appService.emitMobileEngineLayoutStateChange(layoutState);
        this.scheduleNextHeartbeat();
      })
      .catch((error: any) => {
        this.scheduleNextHeartbeat();
      });
      return;
    }

    this.pingService.ping()
    .subscribe(() => {
      this.scheduleNextHeartbeat();
      this.appService.isAppOnline = true;
      this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, 'online');
      this.appService.emitConnectionToServerActive(this.appService.isAppOnline);
    }, (error: any) => {
      this.scheduleNextHeartbeat();
      if (!this.appService.isAppOnline) return;

      LogUtils.warn('Heartbeat (ping): Disconnected from server.');
      this.appService.isAppOnline = false;
      this.opsStatsService.addValue(OpsStatsType.AppOnlineStateChanges, 'offline');
      this.appService.emitConnectionToServerActive(this.appService.isAppOnline);

      if (this.appService.isPersistentErrorPopupShowing) return;

      this.enrollService.getLocalEnrollment()
      .subscribe((de: DeviceEnrollment3) => {
        this.appService.initWsAndAuthenticate(de).subscribe();
      });
    });
  }

}
