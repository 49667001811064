<!-- having listDefinition.style?.width set + listDefinition.style?.display == 'inline-grid' is what distinguishes between list / grid -->
<ion-content *ngIf="listDefinition && listData"
  class="list-control"
  [style.opacity]="listReady ? 1 : 0">

  <div class="wrapper">
    <ion-segment *ngIf="layoutControl | parseRV:'MapView'"
      (ionChange)="viewChanged($event?.detail?.value)"
      [value]="activeView">
      <ion-segment-button value="list">
        <ion-label>{{ 'List' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="map">
        <ion-label>{{ 'Map' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>

    <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
      {{ layoutControl | parseRV:'ControlHeadlineText' }}
    </h2>

    <div *ngIf="renderedViews.indexOf('list') >= 0"
      class="flex-1 {{ listDefinition.style?.width ? 'flex-row wrap' : 'flex-col gridster-gap' }}"
      [hidden]="activeView !== 'list'">
      <gridster *ngIf="headerDefinition?.items?.length"
        class="gridster background"
        [ngStyle]="listDefinition.header?.style"
        [style.maxHeight]="listDefinition.header?.style?.width ? listDefinition.header?.style?.height || listDefinition.header?.style?.minHeight : undefined"
        [style.minHeight]="listDefinition.header?.style?.minHeight ? getMinRowHeight(listDefinition.header?.style?.minHeight, '3.5rem') : ''"
        [style.flex]="!listDefinition.header?.style?.width ? 0 : undefined"
        [options]="gridsterOptions1">
        <gridster-item *ngFor="let item of headerDefinition?.items || []; let i = index;"
          class="gridster-item pointer"
          [ngStyle]="item.itemStyle"
          [hidden]="!listData[0]"
          [item]="item">
          <span *ngIf="item.field && (item.field.staticValue || item.field.textId || item.field.originalVariableGuidId)"
            class="label"
            [ngStyle]="item.labelStyle">{{
              item.field.staticValue ? item.field.staticValue :
              item.field.textId ? layoutTexts.get(item.field.textId)?.text :
              listData[0] ? listData[0].parseRV(item.field.subVariableMemberGuidId || item.field.originalVariableGuidId) :
              ''
            }}
          </span>
        </gridster-item>
      </gridster>

      <gridster *ngFor="let repeat of getNgForArray(listData.length / listDefinition.rows); let i = index;"
        class="gridster gridSize"
        [ngStyle]="listDefinition.style"
        [style.flex]="!listDefinition.style?.width ? 0 : undefined"
        [style.maxHeight]="listDefinition.style?.width ? listDefinition.style?.height || listDefinition.style?.minHeight : undefined"
        [style.minHeight]="listData[(i * listDefinition.rows)]?.$uiGrouping ? ('calc(' + (rowMinHeight || 0) + ' / 2)') : rowMinHeight"
        [style.maxWidth]="listDefinition.style?.width ? 'calc(' + listDefinition.style.width + ' - 1px)' : ''"
        [style.marginLeft]="!!uiGroupingMap && !listData[(i * listDefinition.rows)]?.$uiGrouping ? '0.5rem' : undefined"
        [options]="gridsterOptions1">
        <gridster-item *ngFor="let item of listDefinition?.items || []; let j = index;"
          class="gridster-item pointer"
          [ngStyle]="item.itemStyle"
          [hidden]="!listData[(i * listDefinition.rows) + (j + headerAddToIndex)]"
          [item]="item"
          (click)="itemClick(listData[(i * listDefinition.rows) + (j + headerAddToIndex)], getDesignStyleWithMapping(i, j))">
          <gridster *ngIf="i | getDesignStyle:j:thisComponent"
            class="gridster background"
            [ngStyle]="((i | getDesignStyle:j:thisComponent) | parseDesignStyleJson)?.style"
            [options]="gridsterOptions2">
            <gridster-item *ngFor="let item of ((i | getDesignStyle:j:thisComponent) | parseDesignStyleJson)?.items || []"
              class="gridster-item"
              [ngStyle]="item.itemStyle"
              [item]="item">

              <div class="gridster-item-content">
                <img *ngIf="(item | getItemData:i:j:thisComponent)?.isResource"
                  class="image"
                  [ngStyle]="item.labelStyle"
                  [src]="resourceMap[(item | getItemData:i:j:thisComponent)?.resourceGuidId] ? resourceMap[(item | getItemData:i:j:thisComponent)?.resourceGuidId] : 'assets/img/loading.gif'"
                  alt="picture" />

                <span *ngIf="!(item | getItemData:i:j:thisComponent)?.isResource"
                  class="label"
                  [ngStyle]="item.labelStyle"
                  [innerHTML]="item | getItemFieldValue:i:j:thisComponent">
                </span>
              </div>
            </gridster-item>
          </gridster>

        </gridster-item>
      </gridster>

      <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>

    <lc-control-list1-map *ngIf="renderedViews.indexOf('map') >= 0"
      class="flex-1"
      [hidden]="activeView !== 'map'"
      (itemClick)="itemClick($event)"
      [layoutControl]="layoutControl"
      [layoutDesigns]="layoutDesigns"
      [layoutTexts]="layoutTexts"
      [listData]="mapData"
      [listDefinition]="listDefinition"
      [rowDefinition]="rowDefinition">
    </lc-control-list1-map>
  </div>

  <div *ngIf="uiSummaryValue != null"
    class="ui-summary {{ uiSummaryLocation }}">
    {{ uiSummaryValue }}
  </div>

</ion-content>

<div class="barcode-scanner-container">
  <lc-barcode-scanner-livestream-overlay
    [layout]="layout"
    [layoutControl]="layoutControl"
    [types]="barcodeTypes"
    (valueChanges)="onCameraScan($event)">
  </lc-barcode-scanner-livestream-overlay>
</div>
