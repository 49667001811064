import { RuntimeLayoutNotifyType } from "./runtime-layout/runtime-layout-notify-type.enum";


export class Notification {

  id: bigint;
  title: string;
  text: string;
  type: RuntimeLayoutNotifyType;
  blocking?: boolean;
  showTimeInSeconds?: number;

  constructor(item?: Partial<Notification>) {
    Object.assign(this, item);

    this.id = this.id || BigInt(Date.now());
  }

}
