import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutSettingGroupSetType } from "./RuntimeLayoutSettingGroupSetType";
import { RuntimeLayoutSetting } from "./RuntimeLayoutSetting";

export class RuntimeLayoutSettingGroup {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    guidId: string | null;
    name: string | null;
    path: string | null;
    setType: RuntimeLayoutSettingGroupSetType;
    settingGroupDateTime: string | null;
    settings: (RuntimeLayoutSetting | null)[] | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.guidId = null;
        this.name = null;
        this.path = null;
        this.setType = 0;
        this.settingGroupDateTime = null;
        this.settings = null;

    }

    static serialize(value: RuntimeLayoutSettingGroup | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutSettingGroup | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(10);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.guidId);
        writer.writeString(value.name);
        writer.writeString(value.path);
        writer.writeInt32(value.setType);
        writer.writeString(value.settingGroupDateTime);
        writer.writeArray(value.settings, (writer, x) => RuntimeLayoutSetting.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutSettingGroup | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutSettingGroup | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutSettingGroup.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutSettingGroup | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutSettingGroup | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutSettingGroup();
        if (count == 10) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.guidId = reader.readString();
            value.name = reader.readString();
            value.path = reader.readString();
            value.setType = reader.readInt32();
            value.settingGroupDateTime = reader.readString();
            value.settings = reader.readArray(reader => RuntimeLayoutSetting.deserializeCore(reader));

        }
        else if (count > 10) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.guidId = reader.readString(); if (count == 5) return value;
            value.name = reader.readString(); if (count == 6) return value;
            value.path = reader.readString(); if (count == 7) return value;
            value.setType = reader.readInt32(); if (count == 8) return value;
            value.settingGroupDateTime = reader.readString(); if (count == 9) return value;
            value.settings = reader.readArray(reader => RuntimeLayoutSetting.deserializeCore(reader)); if (count == 10) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutSettingGroup | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutSettingGroup | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutSettingGroup.deserializeCore(reader));
    }
}
