import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutSettingGroup } from "./RuntimeLayoutSettingGroup";

export class RuntimeLayoutSession {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    startedTick: bigint;
    lastResetTick: bigint;
    settingGroups: Map<bigint, RuntimeLayoutSettingGroup | null> | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.startedTick = 0n;
        this.lastResetTick = 0n;
        this.settingGroups = null;

    }

    static serialize(value: RuntimeLayoutSession | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutSession | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(7);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.startedTick);
        writer.writeInt64(value.lastResetTick);
        writer.writeMap(value.settingGroups, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutSettingGroup.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutSession | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutSession | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutSession.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutSession | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutSession | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutSession();
        if (count == 7) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.startedTick = reader.readInt64();
            value.lastResetTick = reader.readInt64();
            value.settingGroups = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSettingGroup.deserializeCore(reader));

        }
        else if (count > 7) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.startedTick = reader.readInt64(); if (count == 5) return value;
            value.lastResetTick = reader.readInt64(); if (count == 6) return value;
            value.settingGroups = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSettingGroup.deserializeCore(reader)); if (count == 7) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutSession | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutSession | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutSession.deserializeCore(reader));
    }
}
