import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage;
  private isReadySubject = new Subject<void>();

  constructor(
    private storage: Storage,
  ) {
    this.init();
  }

  init() {
    this.storage.create()
    .then((storage: Storage) => {
      this._storage = storage;

      this.isReadySubject.next();
      this.isReadySubject.complete();
    });
  }

  private waitUntilReady(): Observable<void> {
    if (this._storage) {
      return of(null);
    } else {
      return this.isReadySubject as Observable<void>;
    }
  }

  get(key: string): Observable<any> {
    return this.waitUntilReady()
    .pipe(
      mergeMap(() => {
        return from(this._storage.get(key));
      }),
    );
  }

  remove(key: string): Observable<any> {
    return from(this._storage.remove(key));
  }

  set(key: string, value: any): Observable<any> {
    return from(this._storage.set(key, value));
  }

}
