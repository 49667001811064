export enum DeviceRunStatus {

  Alive = 0,

  Deactivated = 10,
  Destroyed = 11,

  Expired = 20,

  PrepairedDeviceComplete = 30,

  ErrorObsoleteEngine = 100,
  ErrorObsoleteSolution = 101,
  ErrorCriticalError = 102,

}
