import { Injectable, Injector } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { Observable, Observer, of } from 'rxjs';
import { BrowserUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';


export interface ZebraPluginSettings {
  customProfile?: any;
}


@Injectable({
  providedIn: 'root'
})
export class ZebraPlugin extends BasePlugin {

  private readonly profileName = 'LogicCenter Mobile';
  private readonly intentAction = 'se.mobilelogic.logiccenter.mobile.ACTION';

  private hasScanner: boolean;
  private isPluginAllowedChecked: boolean;
  private settings: ZebraPluginSettings;
  private statusObject: any;

  constructor(
    private device: Device,
    injector: Injector,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'ZebraPlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    const manufacturer = this.platform.is('cordova') ? (this.device.manufacturer || '').toLowerCase() : '';
    return BrowserUtils.isDeviceApp() && (manufacturer.indexOf('zebra') >= 0 || manufacturer.indexOf('motorola solutions') >= 0);
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Zebra device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.createAndConfigureDWProfile();

    // give it some time for the profile to settle and then...
    setTimeout(() => {
      this.registerScannerCallback();

      // also trigger intent to check if device has an internal imager / scanner
      // this will be returned when a client calls zebraPlugin.action({ command: 'has_scanner' });
      (window as any).plugins.intentShim.sendBroadcast({
        action: 'com.symbol.datawedge.api.ACTION_ENUMERATESCANNERS'
      },
        () => { }, // Success in sending the intent, not success of DW to process the intent.
        () => { }, // Failure in sending the intent, not failure of DW to process the intent.
      );
    }, 1000);

    return of(null);
  }

  private createAndConfigureDWProfile() {
    this.log('Creating DataWedge Profile...');
    this.sendBroadcast('com.symbol.datawedge.api.CREATE_PROFILE', this.profileName);

    this.log('Configuring profile...');
    // Configure the created profile (associated app and decoders)
    const profileConfig1 = {
      'PROFILE_NAME': this.profileName,
      'PROFILE_ENABLED': 'true',
      'CONFIG_MODE': 'UPDATE',
      'PLUGIN_CONFIG': {
        'PLUGIN_NAME': 'BARCODE',
        'RESET_CONFIG': 'true',
        'PARAM_LIST': {
          'scanner_selection': 'auto',
        },
      },
      'APP_LIST': [{
        'PACKAGE_NAME': 'se.mobilelogic.logiccenter.mobile',
        'ACTIVITY_LIST': ['*']
      }]
    };
    Object.assign(profileConfig1.PLUGIN_CONFIG.PARAM_LIST, this.settings?.customProfile?.PLUGIN_CONFIG?.PARAM_LIST || {})
    this.sendBroadcast('com.symbol.datawedge.api.SET_CONFIG', profileConfig1);

    //  Configure the created profile (intent plugin)
    const profileConfig2 = {
      'PROFILE_NAME': this.profileName,
      'PROFILE_ENABLED': 'true',
      'CONFIG_MODE': 'UPDATE',
      'PLUGIN_CONFIG': {
        'PLUGIN_NAME': 'INTENT',
        'RESET_CONFIG': 'true',
        'PARAM_LIST': {
          'intent_output_enabled': 'true',
          'intent_action': this.intentAction,
          'intent_delivery': '2'
        }
      }
    };
    this.sendBroadcast('com.symbol.datawedge.api.SET_CONFIG', profileConfig2);
  }

  private registerScannerCallback(): void {
    this.log('Registering to receive scanner data...');
    (window as any).plugins.intentShim.unregisterBroadcastReceiver();
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        this.intentAction,
        'com.symbol.datawedge.api.RESULT_ACTION',
        'com.symbol.datawedge.api.ACTION_ENUMERATEDSCANNERLIST',
      ],
      filterCategories: [
        'android.intent.category.DEFAULT',
      ]},
      this.handleScannerIntent.bind(this)
    );
  }

  private handleScannerIntent(intent: any): void {
    intent.extras = intent.extras || {};
    if (intent.action === this.intentAction) {
      const value = intent.extras['com.symbol.datawedge.data_string'];
      const valueType = intent.extras['com.symbol.datawedge.label_type'];
      this.scannerService.emitScan({
        source: 'SCANNER',
        value: value,
        valueType: valueType,
      });
    } else if (intent.action === 'com.symbol.datawedge.api.ACTION_ENUMERATEDSCANNERLIST') {
      const scannerList: string[] = intent.extras['DWAPI_KEY_ENUMERATEDSCANNERLIST'];
      this.hasScanner = scannerList && scannerList.length > 0;
      this.log('Has internal scanner / imager? ' + this.hasScanner);
    } else if (intent.action === 'com.symbol.datawedge.api.RESULT_ACTION') {
      // this.log('Command result:' + JSON.stringify(intent));
    } else {
      this.statusObject = this.statusObject || {};
      Object.assign(this.statusObject, intent.extras);

      if (
        intent.extras['com.symbol.datawedge.api.RESULT_GET_ACTIVE_PROFILE'] &&
        intent.extras['com.symbol.datawedge.api.RESULT_GET_ACTIVE_PROFILE'] !== this.profileName
      ) {
        alert('DataWedge profile not correctly configured.');
      }
    }
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Zebra device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'enable_scanner':
        this.log('Enabling scanner...');
        this.sendBroadcast('com.symbol.datawedge.api.SCANNER_INPUT_PLUGIN', 'ENABLE_PLUGIN');
        return of(null);
      case 'disable_scanner':
        this.log('Disabling scanner...');
        this.sendBroadcast('com.symbol.datawedge.api.SCANNER_INPUT_PLUGIN', 'DISABLE_PLUGIN');
        return of(null);
      case 'has_scanner':
        // this.log('Has internal scanner / imager? ' + this.hasScanner);
        return of(this.hasScanner);
      case 'toggle_soft_scan':
        this.log('Toggle soft scanner state...');
        this.sendBroadcast('com.symbol.datawedge.api.SOFT_SCAN_TRIGGER', 'TOGGLE_SCANNING');
        return of(null);
      default:
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Zebra device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on Zebra device...');
    }

    return new Observable((observer: Observer<void>) => {
      this.sendBroadcast('com.symbol.datawedge.api.GET_VERSION_INFO', '');
      this.sendBroadcast('com.symbol.datawedge.api.GET_ACTIVE_PROFILE', '');
      this.sendBroadcast('com.symbol.datawedge.api.ENUMERATE_SCANNERS', '');

      setTimeout(() => {
        this.statusObject = this.statusObject || {};
        this.statusObject.log = Array.from(this.logRingBuffer);
        observer.next(this.statusObject);
        observer.complete();
      }, 2000);
    });
  }

  private sendBroadcast(extraName: string, extraValue: any,) {
    (window as any).plugins.intentShim.sendBroadcast({
      action: 'com.symbol.datawedge.api.ACTION',
      extras: {
        [extraName]: extraValue,
        'SEND_RESULT': 'true'
      }
    },
      () => { }, // Success in sending the intent, not success of DW to process the intent.
      () => { }, // Failure in sending the intent, not failure of DW to process the intent.
    );
  }

}
