import { Pipe, PipeTransform } from '@angular/core';
import { RuntimeLayoutDesignStyle } from '../../models/memorypack/RuntimeLayoutDesignStyle';
import { DesignStyleJson } from '../../models/studio';


@Pipe({ name: 'parseDesignStyleJson' })
export class ParseDesignStyleJsonPipe implements PipeTransform {
  transform (designStyle: RuntimeLayoutDesignStyle, pipePureValueBusting?: number): any {
    if (!designStyle) return null;
    return DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary)
  }

}