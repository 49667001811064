import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutValue } from "./RuntimeLayoutValue";
import { RuntimeLayoutScreenControlCollection } from "./RuntimeLayoutScreenControlCollection";
import { RuntimeLayoutControl } from "./RuntimeLayoutControl";
import { RuntimeLayoutObjectPointer } from "./RuntimeLayoutObjectPointer";
import { RuntimeLayoutHead } from "./RuntimeLayoutHead";
import { RuntimeLayoutSet } from "./RuntimeLayoutSet";
import { RuntimeLayoutData } from "./RuntimeLayoutData";
import { RuntimeLayoutVariable } from "./RuntimeLayoutVariable";
import { RuntimeLayoutLocalEvent } from "./RuntimeLayoutLocalEvent";
import { RuntimeLayoutNotify } from "./RuntimeLayoutNotify";

export class RuntimeLayoutScreen {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    solutionLayoutScreenGuidId: string | null;
    layoutScreenGuidId: string | null;
    runtimeFlowObjectIds: bigint[] | null;
    layoutScreenCode: string | null;
    renderValues: Map<string | null, RuntimeLayoutValue | null> | null;
    backButton: boolean;
    actionButton: boolean;
    forwardButton: boolean;
    scannerEnabled: boolean;
    primaryLayoutControlObjectId: bigint;
    screenControlCollections: Map<string | null, RuntimeLayoutScreenControlCollection | null> | null;
    controls: Map<bigint, RuntimeLayoutControl | null> | null;
    removedControls: (RuntimeLayoutObjectPointer | null)[] | null;
    controlTick: bigint;
    heads: Map<bigint, RuntimeLayoutHead | null> | null;
    removedHeads: (RuntimeLayoutObjectPointer | null)[] | null;
    sets: Map<bigint, RuntimeLayoutSet | null> | null;
    removedSets: (RuntimeLayoutObjectPointer | null)[] | null;
    datas: Map<bigint, RuntimeLayoutData | null> | null;
    removedDatas: (RuntimeLayoutObjectPointer | null)[] | null;
    variables: Map<bigint, RuntimeLayoutVariable | null> | null;
    hybridDataSynchronizationRequirement: number;
    supportHybridState: number;
    hybridEventQueueMaxEvents: number;
    localEvents: (RuntimeLayoutLocalEvent | null)[] | null;
    notifys: Map<bigint, RuntimeLayoutNotify | null> | null;
    flowSolutionType: number;
    attentionType: number;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.solutionLayoutScreenGuidId = null;
        this.layoutScreenGuidId = null;
        this.runtimeFlowObjectIds = null;
        this.layoutScreenCode = null;
        this.renderValues = null;
        this.backButton = false;
        this.actionButton = false;
        this.forwardButton = false;
        this.scannerEnabled = false;
        this.primaryLayoutControlObjectId = 0n;
        this.screenControlCollections = null;
        this.controls = null;
        this.removedControls = null;
        this.controlTick = 0n;
        this.heads = null;
        this.removedHeads = null;
        this.sets = null;
        this.removedSets = null;
        this.datas = null;
        this.removedDatas = null;
        this.variables = null;
        this.hybridDataSynchronizationRequirement = 0;
        this.supportHybridState = 0;
        this.hybridEventQueueMaxEvents = 0;
        this.localEvents = null;
        this.notifys = null;
        this.flowSolutionType = 0;
        this.attentionType = 0;

    }

    static serialize(value: RuntimeLayoutScreen | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutScreen | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(32);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeString(value.solutionLayoutScreenGuidId);
        writer.writeString(value.layoutScreenGuidId);
        writer.writeArray(value.runtimeFlowObjectIds, (writer, x) => writer.writeInt64(x));
        writer.writeString(value.layoutScreenCode);
        writer.writeMap(value.renderValues, (writer, x) => writer.writeString(x), (writer, x) => RuntimeLayoutValue.serializeCore(writer, x));
        writer.writeBoolean(value.backButton);
        writer.writeBoolean(value.actionButton);
        writer.writeBoolean(value.forwardButton);
        writer.writeBoolean(value.scannerEnabled);
        writer.writeInt64(value.primaryLayoutControlObjectId);
        writer.writeMap(value.screenControlCollections, (writer, x) => writer.writeString(x), (writer, x) => RuntimeLayoutScreenControlCollection.serializeCore(writer, x));
        writer.writeMap(value.controls, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutControl.serializeCore(writer, x));
        writer.writeArray(value.removedControls, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeInt64(value.controlTick);
        writer.writeMap(value.heads, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutHead.serializeCore(writer, x));
        writer.writeArray(value.removedHeads, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeMap(value.sets, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutSet.serializeCore(writer, x));
        writer.writeArray(value.removedSets, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeMap(value.datas, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutData.serializeCore(writer, x));
        writer.writeArray(value.removedDatas, (writer, x) => RuntimeLayoutObjectPointer.serializeCore(writer, x));
        writer.writeMap(value.variables, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutVariable.serializeCore(writer, x));
        writer.writeInt32(value.hybridDataSynchronizationRequirement);
        writer.writeInt32(value.supportHybridState);
        writer.writeInt32(value.hybridEventQueueMaxEvents);
        writer.writeArray(value.localEvents, (writer, x) => RuntimeLayoutLocalEvent.serializeCore(writer, x));
        writer.writeMap(value.notifys, (writer, x) => writer.writeInt64(x), (writer, x) => RuntimeLayoutNotify.serializeCore(writer, x));
        writer.writeInt32(value.flowSolutionType);
        writer.writeInt32(value.attentionType);

    }

    static serializeArray(value: (RuntimeLayoutScreen | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutScreen | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutScreen.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutScreen | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutScreen | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutScreen();
        if (count == 32) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.solutionLayoutScreenGuidId = reader.readString();
            value.layoutScreenGuidId = reader.readString();
            value.runtimeFlowObjectIds = reader.readArray(reader => reader.readInt64());
            value.layoutScreenCode = reader.readString();
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader));
            value.backButton = reader.readBoolean();
            value.actionButton = reader.readBoolean();
            value.forwardButton = reader.readBoolean();
            value.scannerEnabled = reader.readBoolean();
            value.primaryLayoutControlObjectId = reader.readInt64();
            value.screenControlCollections = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutScreenControlCollection.deserializeCore(reader));
            value.controls = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutControl.deserializeCore(reader));
            value.removedControls = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.controlTick = reader.readInt64();
            value.heads = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutHead.deserializeCore(reader));
            value.removedHeads = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.sets = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSet.deserializeCore(reader));
            value.removedSets = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutData.deserializeCore(reader));
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader));
            value.variables = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutVariable.deserializeCore(reader));
            value.hybridDataSynchronizationRequirement = reader.readInt32();
            value.supportHybridState = reader.readInt32();
            value.hybridEventQueueMaxEvents = reader.readInt32();
            value.localEvents = reader.readArray(reader => RuntimeLayoutLocalEvent.deserializeCore(reader));
            value.notifys = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutNotify.deserializeCore(reader));
            value.flowSolutionType = reader.readInt32();
            value.attentionType = reader.readInt32();

        }
        else if (count > 32) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.solutionLayoutScreenGuidId = reader.readString(); if (count == 5) return value;
            value.layoutScreenGuidId = reader.readString(); if (count == 6) return value;
            value.runtimeFlowObjectIds = reader.readArray(reader => reader.readInt64()); if (count == 7) return value;
            value.layoutScreenCode = reader.readString(); if (count == 8) return value;
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader)); if (count == 9) return value;
            value.backButton = reader.readBoolean(); if (count == 10) return value;
            value.actionButton = reader.readBoolean(); if (count == 11) return value;
            value.forwardButton = reader.readBoolean(); if (count == 12) return value;
            value.scannerEnabled = reader.readBoolean(); if (count == 13) return value;
            value.primaryLayoutControlObjectId = reader.readInt64(); if (count == 14) return value;
            value.screenControlCollections = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutScreenControlCollection.deserializeCore(reader)); if (count == 15) return value;
            value.controls = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutControl.deserializeCore(reader)); if (count == 16) return value;
            value.removedControls = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 17) return value;
            value.controlTick = reader.readInt64(); if (count == 18) return value;
            value.heads = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutHead.deserializeCore(reader)); if (count == 19) return value;
            value.removedHeads = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 20) return value;
            value.sets = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutSet.deserializeCore(reader)); if (count == 21) return value;
            value.removedSets = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 22) return value;
            value.datas = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutData.deserializeCore(reader)); if (count == 23) return value;
            value.removedDatas = reader.readArray(reader => RuntimeLayoutObjectPointer.deserializeCore(reader)); if (count == 24) return value;
            value.variables = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutVariable.deserializeCore(reader)); if (count == 25) return value;
            value.hybridDataSynchronizationRequirement = reader.readInt32(); if (count == 26) return value;
            value.supportHybridState = reader.readInt32(); if (count == 27) return value;
            value.hybridEventQueueMaxEvents = reader.readInt32(); if (count == 28) return value;
            value.localEvents = reader.readArray(reader => RuntimeLayoutLocalEvent.deserializeCore(reader)); if (count == 29) return value;
            value.notifys = reader.readMap(reader => reader.readInt64(), reader => RuntimeLayoutNotify.deserializeCore(reader)); if (count == 30) return value;
            value.flowSolutionType = reader.readInt32(); if (count == 31) return value;
            value.attentionType = reader.readInt32(); if (count == 32) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutScreen | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutScreen | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutScreen.deserializeCore(reader));
    }
}
