<div class="grid-container">
  <div class="control-update-esl1">
    <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
      {{ layoutControl | parseRV:'ControlHeadlineText' }}
    </h2>

    <div class="icon {{ theme }}">
      <i class="fad fa-scanner-touchscreen"
        (click)="start()">
      </i>
    </div>

    <lc-control-input1 *ngIf="staticControlVerification"
      #controlVerificationComponent
      class="flex-1"
      [staticControl]="staticControlVerification"
      (triggerEvent)="runScanVerification()">
    </lc-control-input1>
  </div>
</div>