import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";

export class LayoutResourceUploadRequestMessage {
    messageType: LayoutMessageType;
    contentType: string | null;
    totalSize: bigint;
    guidId: string | null;
    chunkId: number;
    chunkSize: bigint;
    chunkBinary: Uint8Array | null;

    constructor() {
        this.messageType = 0;
        this.contentType = null;
        this.totalSize = 0n;
        this.guidId = null;
        this.chunkId = 0;
        this.chunkSize = 0n;
        this.chunkBinary = null;

    }

    static serialize(value: LayoutResourceUploadRequestMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutResourceUploadRequestMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(7);
        writer.writeInt32(value.messageType);
        writer.writeString(value.contentType);
        writer.writeInt64(value.totalSize);
        writer.writeString(value.guidId);
        writer.writeInt32(value.chunkId);
        writer.writeInt64(value.chunkSize);
        writer.writeUint8Array(value.chunkBinary);

    }

    static serializeArray(value: (LayoutResourceUploadRequestMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutResourceUploadRequestMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutResourceUploadRequestMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutResourceUploadRequestMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutResourceUploadRequestMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutResourceUploadRequestMessage();
        if (count == 7) {
            value.messageType = reader.readInt32();
            value.contentType = reader.readString();
            value.totalSize = reader.readInt64();
            value.guidId = reader.readString();
            value.chunkId = reader.readInt32();
            value.chunkSize = reader.readInt64();
            value.chunkBinary = reader.readUint8Array();

        }
        else if (count > 7) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageType = reader.readInt32(); if (count == 1) return value;
            value.contentType = reader.readString(); if (count == 2) return value;
            value.totalSize = reader.readInt64(); if (count == 3) return value;
            value.guidId = reader.readString(); if (count == 4) return value;
            value.chunkId = reader.readInt32(); if (count == 5) return value;
            value.chunkSize = reader.readInt64(); if (count == 6) return value;
            value.chunkBinary = reader.readUint8Array(); if (count == 7) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutResourceUploadRequestMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutResourceUploadRequestMessage | null)[] | null {
        return reader.readArray(reader => LayoutResourceUploadRequestMessage.deserializeCore(reader));
    }
}
