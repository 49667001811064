import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { LogUtils } from 'src/app/shared/utils';
import { DictString } from '../../../models';
import { PluginType } from '../../../services/plugin';
import { ControlBaseComponent } from '../base/control-base.component';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';

@Component({
  selector: 'lc-control-scale1',
  templateUrl: 'control-scale1.component.html',
  styleUrls: ['./control-scale1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlScale1Component extends ControlBaseComponent implements OnInit {

  theme: string;

  currentScaleData: string = '0.0';
  currentScaleSend: number = 0;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }


  ngOnInit() {
    this.currentScaleSend = 0;
    this.currentScaleData = '0.0';

    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    if (satoPlugin.isPluginAllowed()) {
      satoPlugin.action({
        command: 'set_user_data_callback',
        callback: (data: string) => {
          LogUtils.log(data);
          this.currentScaleData = data;
          this.cdr.markForCheck();

          let scaleData: any = [];

          let parsedData = data.trimLeft();
          parsedData = parsedData.trimRight();
          LogUtils.log(parsedData);

          if(!parsedData.startsWith('Scale is') &&
             !parsedData.startsWith('Failed to connect')) {
              scaleData = parsedData.split(" ");

              if(scaleData[1] == 'g') {
                this.currentScaleSend = scaleData[0];
              }
              else {
                this.currentScaleSend = scaleData[0];
              }

              this.noUserInteractionHandler('Scaled')
          }
        },
      }).subscribe();
    }
  }

  ngOnDestroy() {
    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    if (satoPlugin.isPluginAllowed()) {
      satoPlugin.action({
        command: 'set_user_data_callback',
        callback: undefined,
      });
    }

    super.ngOnDestroy();
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    context.set('WeightInKilograms', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.currentScaleSend || 0),
      valueTypeId: RuntimeLayoutValueType.Double
    }));

    context.set('Scaled', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.currentScaleSend > 0),
      valueTypeId: RuntimeLayoutValueType.Bool
    }));

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    this.currentScaleSend = 0;

    return context;
  }

  private noUserInteractionHandler(portName: string) {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

}

