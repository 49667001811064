import { Injectable } from '@angular/core';
import { LogUtils } from '../../utils';
import { ApiService } from '../api';
import { OpsStatsService, OpsStatsType } from './ops-stats.service';
const { name, version } = require('../../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
    private apiService: ApiService,
    private opsStatsService: OpsStatsService,
  ) {
  }

  logNetworkAndSystemStatus() {
    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'hybrid') return;

    const networkInfo = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
    if (networkInfo) {
      LogUtils.log('NetworkInfo', {
        connection: networkInfo.type === 'none' ? networkInfo.type : networkInfo?.effectiveType?.replace('4g', 'fast') + (networkInfo?.type ? ' (' + networkInfo.type + ')' : ''),
        downlink: networkInfo.downlink ? networkInfo.downlink + 'Mbps' : 'Not Available',
        rtt: networkInfo.rtt + 'ms',
      });
    }
    this.apiService.getRaw('status')
    .subscribe((status: any) => {
      Object.assign(status, { requestResponseTimeInMs: this.opsStatsService.getLastOpsStatsValueForType(OpsStatsType.ApiRequestResponseTimeInMs).value });
      LogUtils.log('SystemStatus', status);
    }, (error: any) => {
      LogUtils.log('SystemStatus', error);
    });

    const img = new Image();
    img.onload = () => {
      LogUtils.log('LogicCenterStatus', 'OK');
    };
    img.onerror = () => {
      LogUtils.log('LogicCenterStatus', 'Error');
    };
    img.src = 'https://center.logiccenter.com/assets/img/1x1.png';
  }

}
