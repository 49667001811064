import { Injectable, Injector } from '@angular/core';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Platform } from '@ionic/angular';
import { concat, from, Observable, Observer, of, Subscription, throwError, zip } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap } from 'rxjs/operators';
import { BluetoothDevice, BluetoothDeviceMode, BluetoothDeviceType } from '../../../models/bluetooth-device.model';
import { BrowserUtils } from '../../../utils';
import { LocalSettingsService } from '../../local-settings/local-settings.service';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';
import satoApi from 'webaep-api'
import { SetSettingsService } from '../../protocol/set-settings.service';


export interface BluetoothLEPluginSettings {
  retryIntervalInMs: number;
  retryAttempts: number;
  readDelimiter: string | undefined;
  scanInterval: number;
  writeChunkSize: number;
}


@Injectable({
  providedIn: 'root'
})
export class BluetoothLEPlugin extends BasePlugin {

  private readonly alwaysOnDeviceTypes: string[] = [
    BluetoothDeviceType.Scanner,
  ];
  private readonly writeDelayInMS = 250;
  private readonly connectDelayInMS = 250;

  private connectedDevices: BluetoothDevice[];
  private discoveredDevices: BluetoothDevice[];
  private inactiveTimeoutMap: { [key: string]: any } = {};
  private isPluginAllowedChecked: boolean;
  private lastScanValue: any;
  private lastScanTick: number;
  private reconnectTimeoutMap: { [key: string]: any } = {};
  private satoBleConnectObserverMap: { [key: string]: Observer<null> } = {};
  private satoBleDeviceMap: { [key: string]: BluetoothDevice } = {};
  private satoBleStartCallbackMap: { [key: string]: any } = {};
  private satoHasBle: boolean;
  private satoPrinterVariables: any;
  private settings: BluetoothLEPluginSettings;
  private subscriptionsMap: { [key: string]: Subscription } = {};

  constructor(
    private ble: BLE,
    injector: Injector,
    private localSettingsService: LocalSettingsService,
    private platform: Platform,
    private scannerService: ScannerService,
    private setSettingsService: SetSettingsService,
  ) {
    super(injector);

    this.pluginName = 'BlePlugin';

    this.settings = {
      retryIntervalInMs: 5 * 1000,
      retryAttempts: 3,
      readDelimiter: undefined,
      scanInterval: 10 * 1000,
      writeChunkSize: 90,
    };
    this.connectedDevices = [];
  }

  isPluginAllowed(): boolean {
    return BrowserUtils.isDeviceApp()
    || (satoApi.isPrinter() && (this.satoHasBle == null || this.satoHasBle));
  }

  initialize(options?: any): Observable<null> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);

    if (satoApi.isPrinter()) {
      return from(
        satoApi.connect()
      ).pipe(
        mergeMap(() => {
          satoApi.fetchVariables().then((val) => {
            this.satoPrinterVariables = val || {};
            this.satoHasBle = this.satoPrinterVariables.BLE != null;
          });

          satoApi.setUserDataCallback(this.satoBleCallback.bind(this));

          return this.initAndHandleConnections(this.connectedDevices);
        })
      );
    } else {
      return this.initAndHandleConnections(this.connectedDevices, options?.forceEnable || this.connectedDevices.length > 0);
    }
  }

  private initAndHandleConnections(connectedDevices: BluetoothDevice[], forceEnable?: boolean): Observable<null> {
    return this.checkAndHandleEnabledState(forceEnable)
    .pipe(
      map((isEnabled: boolean) => {
        if (isEnabled && connectedDevices?.length > 0) this.checkAndHandleConnectedState(connectedDevices);

        return null;
      })
    );
  }

  private checkAndHandleEnabledState(forceEnable?: boolean): Observable<boolean> {
    if (satoApi.isPrinter()) return of(true);

    return new Observable((observer: Observer<boolean>) => {
      this.log('Checking BLE enabled state...');

      from(this.ble.isEnabled())
      .subscribe(() => {
        this.log('Enabled');
        observer.next(true);
        observer.complete();
      }, (error: any) => {
        this.log(error);
        if (forceEnable) {
          this.enable(observer);
          return;
        }

        observer.next(false);
        observer.complete();
      });
    });
  }

  private enable(observer: Observer<boolean>) {
    this.log('Enabling BLE...');

    from(this.ble.enable())
    .subscribe((success: any) => {
      this.log(success);
      observer.next(true);
      observer.complete();
    }, (error: any) => {
      this.log(error);

      setTimeout(() => {
        this.enable(observer);
      }, this.settings.retryIntervalInMs);
    });
  }

  private checkAndHandleConnectedState(connectedDevices: BluetoothDevice[]) {
    this.log('Checking BLE devices connected state...');
    for (const device of connectedDevices || []) {
      // Iterate over all the known devices and check if any of them is connected.
      // If yes, disconnect. If any of them is in auto-connect mode, connect to it.
      if (satoApi.isPrinter()) {
        device.isConnected = false;
        this.ifAlwaysOnDeviceTryToConnect(device);
        continue;
      }

      from(this.ble.isConnected(device.id))
      .subscribe((success: any) => {
        this.log(`Connected, but shouldn't be. Disconnecting...`);

        device.isConnected = false;
        this.disconnect(device, false)
        .subscribe(() => {
          this.log(`Disconnected from '${device.id}', as it should be at this point.`);

          this.ifAlwaysOnDeviceTryToConnect(device);
        }, (error: any) => {
          this.log(`Error trying to disconnect from '${device.id}': ` + JSON.stringify(error));

          this.ifAlwaysOnDeviceTryToConnect(device);
        });
      }, (error: any) => {
        this.log(`Disconnected from '${device.id}', as it should be at this point.`);

        this.ifAlwaysOnDeviceTryToConnect(device);
      });
    }
  }

  private ifAlwaysOnDeviceTryToConnect(device: BluetoothDevice) {
    if (
      device.type === BluetoothDeviceType.Scanner ||
      device.type === BluetoothDeviceType.Thermometer ||
      (device.type === BluetoothDeviceType.Printer && !device.settings.disconnectOnInactivityTimeoutMS)
    ) {
      this.log(`Trying to connect to '${device.id}'...`);
      this.connect(device, false, true).subscribe();
    }
  }

  private connect(device: BluetoothDevice, firstTime: boolean, skipSaveSettingRemotely?: boolean): Observable<null> {
    return new Observable((observer: Observer<null>) => {
      this.log('Connecting to ' + device.id + '...');

      if (satoApi.isPrinter()) {
        this.satoBleDeviceMap[device.id] = device;
        this.satoBleConnectObserverMap[device.id] = observer;
        this.satoBleCall(
          'connect',
          [
            device.id,
            { RemoteAddressRandom: device.settings.remoteAddressRandom, Connection_Interval_Min: 70, Connection_Interval_Max: 120 },
            5
          ],
          'connect'
        );
        return;
      }

      if (
        device.type !== BluetoothDeviceType.Thermometer &&
        device.settings?.disconnectOnInactivityTimeoutMS &&
        device.settings?.disconnectOnInactivityTimeoutMS < 60000
      ) {
        this.ble.connect(device.id)
        .subscribe((deviceData: BluetoothDevice) => {
          Object.assign(device, deviceData);
          this.connectSuccessHandler(device, !!skipSaveSettingRemotely, observer);
        }, (error: any) => {
          this.connectErrorHandler(device, error, firstTime, observer);
        });
      } else {
        this.ble.autoConnect(
          device.id,
          (deviceData: BluetoothDevice) => {
            Object.assign(device, deviceData);
            this.connectSuccessHandler(device, !!skipSaveSettingRemotely, observer);
          }, (error: any) => {
            this.connectErrorHandler(device, error, firstTime, observer);
          }
        );
      }
    });
  }

  private connectSuccessHandler(device: BluetoothDevice, skipSaveSettingRemotely: boolean, observer: Observer<null>) {
    this.log(`Connected to '${device.id}'.`);

    device.shouldBeConnected = true;
    device.type = device.type || BluetoothDeviceType.Unknown;
    device.isConnected = true;

    this.localSettingsService.addOrUpdateBtDevice(device);
    this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);

    if (!skipSaveSettingRemotely) {
      this.setSettingsService.setBluetoothDeviceSettingRemotely(device);
    }

    observer.next(null);
    observer.complete();
  }

  private connectErrorHandler(device: BluetoothDevice, error: any, firstTime: boolean, observer: Observer<null>) {
    device.isConnected = false;

    if (firstTime || this.alwaysOnDeviceTypes.indexOf(device.type) < 0) return observer.error(error);

    setTimeout(() => {
      this.connect(device, false, false)
      .subscribe(() => {
        observer.next(null);
        observer.complete();
      }, (error: any) => {
        observer.error(error);
      });
    }, device.settings.reconnectTimeoutMS || this.settings.retryIntervalInMs);
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    if (options.command !== 'list' && options.command !== 'discover' && options.device && this.inactiveTimeoutMap[options.device.id]) {
      clearTimeout(this.inactiveTimeoutMap[options.device.id]);
      delete this.inactiveTimeoutMap[options.device.id];
    }

    if (['start', 'stop'].indexOf(options.command) < 0) this.log(`Action '${options.command}' for device '${options.device?.id || ''}' started: ${JSON.stringify(options || {}, (key, value) => key !== 'device' ? value : undefined, 1)}`);
    switch(options.command) {
      case 'list':
        if (satoApi.isPrinter() || this.platform.is('ios')) {
          this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);
          return of(this.connectedDevices);
        }

        return from(this.ble.bondedDevices())
        .pipe(
          map((devices: BluetoothDevice[]) => {
            this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);

            devices = devices || [];
            devices.push(...this.connectedDevices);

            devices = BluetoothDevice.removeDuplicatesDevices(devices)
            .map((d: BluetoothDevice) => {
              d.mode = BluetoothDeviceMode.BLE;
              const knownDevice = this.getKnownDevice(d);
              if (knownDevice) {
                knownDevice.shouldBeConnected = true;
                return knownDevice;
              } else {
                d.shouldBeConnected = false;
                d.isConnected = false;
                d.type = BluetoothDeviceType.Unknown;
                return new BluetoothDevice(d);
              }
            });

            if (this.localSettingsService.get().runDeviceDebug) {
              this.log(`Action 'list' found ${devices.length} devices: ${JSON.stringify(devices)}`);
            } else {
              this.log(`Action 'list' found ${devices.length} devices.`);
            }
            return devices;
          })
        );
      case 'discover':
        return new Observable((observer: Observer<BluetoothDevice[]>) => {
          this.discoveredDevices = [];

          setTimeout(() => {
            if (satoApi.isPrinter()) {
              this.satoBleCall('stopScan', [], 'stopScan');
            } else {
              this.ble.stopScan();
            }

            if (this.localSettingsService.get().runDeviceDebug) {
              this.log(`Action 'discover' found ${this.discoveredDevices.length} devices: ${JSON.stringify(this.discoveredDevices)}`);
            } else {
              this.log(`Action 'discover' found ${this.discoveredDevices.length} devices.`);
            }

            observer.next(this.discoveredDevices);
            observer.complete();
          }, this.settings.scanInterval);

          if (satoApi.isPrinter()) {
            this.satoBleCall('startScan', [], 'startScan');
          } else {
            this.ble.startScanWithOptions([], { reportDuplicates: true })
            .subscribe((d: BluetoothDevice) => {
              d.mode = BluetoothDeviceMode.BLE;
              const knownDevice = this.getKnownDevice(d);
              if (knownDevice) {
                knownDevice.shouldBeConnected = true;
                this.discoveredDevices.push(knownDevice);
              } else {
                d.shouldBeConnected = false;
                d.isConnected = false;
                d.type = BluetoothDeviceType.Unknown;
                this.discoveredDevices.push(new BluetoothDevice(d));
              }
            }, (error: any) => {
              this.log(error);
              observer.next(this.discoveredDevices);
              observer.complete();
            });
          }
        });
      case 'connect':
        return this.actionConnect(options);
      case 'disconnect':
        return this.disconnect(options.device, options.unpair);
      case 'disconnectOnInactivity':
        this.inactiveTimeoutMap[options.device.id] = setTimeout(() => {
          if (this.inactiveTimeoutMap[options.device.id]) this.disconnect(options.device, options.unpair).subscribe();
        }, options.device.settings.disconnectOnInactivityTimeoutMS);

        return of(null);
      case 'read':
        if (satoApi.isPrinter()) {
          return new Observable((observer: Observer<BluetoothDevice[]>) => {
            this.start(
              (data: any) => {
                observer.next(data);
                observer.complete();
              },
              {
                device: options.device,
                serviceUUID: options.serviceUUID,
                characteristicUUID: options.characteristicUUID,
              }
            );
          });
        }

        return from(
          this.ble.read(
            options.device.id,
            options.serviceUUID,
            options.characteristicUUID,
          )
        );
      case 'reconnectTimeout':
        if (this.reconnectTimeoutMap[options.device.id]) {
          clearTimeout(this.reconnectTimeoutMap[options.device.id]);
          delete this.reconnectTimeoutMap[options.device.id];
        }
        this.reconnectTimeoutMap = setTimeout(() => {
          if (this.reconnectTimeoutMap) this.actionConnect(options).subscribe();
        }, options.device.settings.reconnectTimeoutMS);

        return of(null);
      case 'requestMtu':
        if (satoApi.isPrinter()) {
          // we may need to implement this...
          return of(null);
        }

        return from(
          this.ble.requestMtu(
            options.device.id,
            options.mtu,
          )
        );
      case 'start':
        this.start(options.callback, options);
        return of(null);
      case 'stop':
        this.stop(options);
        return of(null);
      case 'write':
        return of(null)
        .pipe(
          delay(this.writeDelayInMS),
          mergeMap(() => {
            return this.writeStringToBluetoothDevice(
              options.device,
              options.writeData,
              options.serviceUUID,
              options.characteristicUUID
            );
          }),
          mergeMap(() => {
            if (!options.device.settings?.disconnectOnInactivityTimeoutMS) return of(null);

            return this.action({ command: 'disconnectOnInactivity', device: options.device });
          }),
          catchError((error: any) => {
            const errorMsg = typeof error === 'string' ? error
            : error?.message ? error.message
            : JSON.stringify(error);
            this.log('Error writing data to device: ' + errorMsg);
            options.device.isConnected = false;
            if (options.device.settings?.disconnectOnInactivityTimeoutMS) {
              this.action({ command: 'disconnectOnInactivity', device: options.device }).subscribe();
            }
            return throwError(() => error);
          }),
        );
    }

    return of(null);
  }

  private actionConnect(options: any): Observable<null> {
    return new Observable((observer: Observer<null>) => {
      const checkIsConnectedRequest = satoApi.isPrinter() ? throwError(null) : from(this.ble.isConnected(options.device.id));

      checkIsConnectedRequest
      .subscribe((success: any) => {
        this.log(`Device '${options.device.id}' already connected.`);
        options.device.isConnected = true;
        observer.next(null);
        observer.complete();
      }, (error: any) => {
        options.device.isConnected = false;

        let hasRetried = false;
        // if connection fails, always retry at least one more time because you know...bluetooth...
        this.connect(options.device, true, options.skipSaveSettingRemotely)
        .pipe(
          catchError((error: any) => {
            this.log(`Failed to connect to '${options.device.id}': ${JSON.stringify(error)}`);

            if (!hasRetried) {
              hasRetried = true;
              return of(null).pipe(
                delay(500),
                mergeMap(() => {
                  return this.connect(options.device, true, options.skipSaveSettingRemotely);
                })
              );
            } else {
              return throwError(() => error);
            }
          }),
        ).subscribe(() => {
          observer.next(null);
          observer.complete();
        }, (error: any) => {
          observer.error(error);
        });
      })
    }).pipe(
      delay(this.connectDelayInMS), // give it an extra second to REALLLY connect to the printer
    );
  }

  private disconnect(device: BluetoothDevice, unpair = false): Observable<null> {
    return new Observable((observer: Observer<null>) => {
      this.log(`Disconnecting from ${device.id}...${unpair ? 'and unpairing.' : ''}`);

      if (satoApi.isPrinter()) {
        this.satoBleCall('disconnect', undefined, 'disconnect');

        // TODO: We may want to handle this properly...
        this.disconnectSuccessHandler(device, 'Maybe...', unpair, observer);
        return;
      }

      from(this.ble.disconnect(device.id))
      .subscribe((success: any) => {
        this.disconnectSuccessHandler(device, success, unpair, observer);
      }, (error: any) => {
        this.disconnectErrorHandler(device, error, unpair, observer);
      });
    });
  }

  private disconnectSuccessHandler(device: BluetoothDevice, response: string, unpair: boolean, observer?: Observer<null>): void {
    this.log(`Disconnected from '${device.id}': ${response}`);
    device.isConnected = false;

    if (unpair) {
      device.shouldBeConnected = false;
      this.localSettingsService.removeBtDevice(device);
      this.setSettingsService.setBluetoothDeviceSettingRemotely(device, false);
    } else {
      this.localSettingsService.addOrUpdateBtDevice(device);
    }

    this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);

    observer?.next(null);
    observer?.complete();
  }

  private disconnectErrorHandler(device: BluetoothDevice, error: any, unpair: boolean, observer: Observer<null>) {
    if (!device.isConnected && unpair) {
      device.shouldBeConnected = false;
      this.localSettingsService.removeBtDevice(device);
      this.setSettingsService.setBluetoothDeviceSettingRemotely(device, false);

      this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.BLE);

      observer.next(null);
      observer.complete();
    } else {
      this.log(error);
      observer.error(error);
    }
  }

  private start(callback?: (data: any) => void, options?: any): void {
    Object.assign(this.settings, options || {});

    if (options?.device) {
      this.subscriptionsMap[options.device.id + '|' + options.serviceUUID + '|' + options.characteristicUUID] = this.ble.startNotification(
        options.device.id,
        options.serviceUUID,
        options.characteristicUUID,
      )
      .subscribe((data: ArrayBuffer[]) => {
        if (callback) callback(data);
      });
      return;
    }

    const shouldBeConnectedDevices = this.connectedDevices.filter(x => x.shouldBeConnected && !x.isConnected);
    if (shouldBeConnectedDevices.length) {
      this.log('start(): Got ' + shouldBeConnectedDevices.length + ' devices that should be connected...');
      this.log('start(): devices ' + JSON.stringify(this.connectedDevices, null, 1));

      concat(
        ...this.connectedDevices.filter(x => x.shouldBeConnected && !x.isConnected)
        .map((device: BluetoothDevice) => {
          return this.connect(device, false, true);
        })
      ).subscribe(() => {
        this.log('start(): connect subscribe');
      }, (error: any) => {
        this.log('start(): connect error');
      }, () => {
        this.log('start(): connect complete');
        this.start(callback, options);
      });
      return;
    }

    for (const device of this.connectedDevices || []) {
      if (!device.isConnected) continue;

      if (device.type === BluetoothDeviceType.Thermometer) {
        let satoHandle1: string = '';
        let subKey1: string = '';
        if (satoApi.isPrinter()) {
          satoHandle1 = device.settings.handles[`0x${BluetoothDevice.HealthThermometerServiceUUID}0x${BluetoothDevice.TemperatureStreamCharacteristicUUID}`];
          subKey1 = device.id + '|' + satoHandle1;
        } else {
          subKey1 = device.id + '|' + BluetoothDevice.HealthThermometerServiceUUID + '|' + BluetoothDevice.TemperatureStreamCharacteristicUUID;
        }

        if (
          options?.stream &&
          callback &&
          !this.subscriptionsMap[subKey1] &&
          !this.satoBleStartCallbackMap[subKey1]
        ) {
          if (satoApi.isPrinter()) {
            this.satoBleStartCallbackMap[subKey1] = callback;
            this.satoBleCall('writeValue', [satoHandle1, 'bin://%1%0'], 'writeValue');
          } else {
            this.subscriptionsMap[subKey1] = this.ble.startNotification(
              device.id,
              BluetoothDevice.HealthThermometerServiceUUID,
              BluetoothDevice.TemperatureStreamCharacteristicUUID,
            )
            .subscribe((data: any /*ArrayBuffer*/) => {
              console.warn(data);
              const temp = this.getTemperatureValueInCelsius(new DataView(data[0]), 1);

              if (callback) callback({
                value: temp,
                valueType: 'temp-stream',
                source: device.name,
              });
            }, (error: any) => {
              this.log(error);
              if (this.subscriptionsMap[subKey1]) delete this.subscriptionsMap[subKey1];
              if (callback) callback({ error: error });
            });
          }
        }

        let satoHandle2: string = '';
        let subKey2: string = '';
        if (satoApi.isPrinter()) {
          satoHandle2 = device.settings.handles[`0x${BluetoothDevice.HealthThermometerServiceUUID}0x${BluetoothDevice.TemperatureMeasurementCharacteristicUUID}`];
          subKey2 = device.id + '|' + satoHandle2;
        } else {
          subKey2 = device.id + '|' + BluetoothDevice.HealthThermometerServiceUUID + '|' + BluetoothDevice.TemperatureMeasurementCharacteristicUUID;
        }

        if (
          !this.subscriptionsMap[subKey2] &&
          !this.satoBleStartCallbackMap[subKey2]
        ) {
          if (satoApi.isPrinter()) {
            this.satoBleStartCallbackMap[subKey2] = callback;
            this.satoBleCall('writeValue', [satoHandle2, 'bin://%2%0'], 'writeValue');
          } else {
            this.subscriptionsMap[subKey2] = this.ble.startNotification(
              device.id,
              BluetoothDevice.HealthThermometerServiceUUID,
              BluetoothDevice.TemperatureMeasurementCharacteristicUUID,
            )
            .subscribe((data: any /*ArrayBuffer*/) => {
              const temp = this.getTemperatureValueInCelsius(new DataView(data[0]), 1);
              if (this.lastScanTick && Date.now() - this.lastScanTick < 2 * 1000/* && this.lastScanValue === temp*/) return;

              this.handleScanData(temp.toFixed(1), 'temp-read', device.name);

              if (callback) callback({
                value: temp,
                valueType: 'temp-read',
                source: device.name,
              });
            }, (error: any) => {
              this.log(error);
              if (this.subscriptionsMap[subKey2]) delete this.subscriptionsMap[subKey2];
            });
          }
        }
      }
    }
  }

  private stop(options?: any): void {
    for (const subKey of Object.keys(this.satoBleStartCallbackMap)) {
      if (!this.satoBleStartCallbackMap[subKey]) continue;

      const subKeySplit = subKey.split('|');
      this.satoBleCall('writeValue', [subKeySplit[1], 'bin://%0%0'], 'writeValue'); // I'm hoping this "stops" the notifications / indications...but I have no idea...
      delete this.satoBleStartCallbackMap[subKey];
    }

    for (const subKey of Object.keys(this.subscriptionsMap)) {
      if (!this.subscriptionsMap[subKey]) continue;

      const subKeySplit = subKey.split('|');
      from(
        this.ble.stopNotification(subKeySplit[0], subKeySplit[1], subKeySplit[2])
      ).subscribe(() => {
        this.subscriptionsMap[subKey]?.unsubscribe();
        delete this.subscriptionsMap[subKey];
      }, (error: any) => {
        this.subscriptionsMap[subKey]?.unsubscribe();
        delete this.subscriptionsMap[subKey];
      });
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of('Cordova not available...');
    }

    if (satoApi.isPrinter()) {
      return of({
        // connectedTo: this.connectedDevices.map((d: BluetoothDevice) => {
        //   delete d.characteristics;
        //   return d;
        // }),
        log: Array.from(this.logRingBuffer),
        satoPrinterVariables: this.satoPrinterVariables,
      });
    } else {
      return zip(
        this.fromPromiseToSuccess(this.ble.isEnabled()),
      ).pipe(
        map(([enabled]: any) => {
          return {
            // connectedTo: this.connectedDevices.map((d: BluetoothDevice) => {
            //   delete d.characteristics;
            //   return d;
            // }),
            enabled: enabled,
            log: Array.from(this.logRingBuffer),
          };
        })
      );
    }
  }

  private getKnownDevice(device: BluetoothDevice) {
    return this.connectedDevices.find((d: BluetoothDevice) => {
      return d.mode === device.mode && d.id === device.id;
    });
  }

  private fromPromiseToSuccess(promise: Promise<any>): Observable<any> {
    return from(promise)
    .pipe(
      catchError((error: any) => {
        return of(error);
      })
    )
  }

  // Write printer language string to the printer
  writeStringToBluetoothDevice(device: BluetoothDevice, str: string, serviceUUID = BluetoothDevice.ZebraPrinterServiceUUID, characteristicUUID = BluetoothDevice.ZebraPrinterCharacteristicUUID): Observable<any> {
    const deviceID = device.id;
    const characteristic = (device.characteristics || [])
    .find(x => (x.service || '').toLowerCase() === serviceUUID.toLowerCase() && (x.characteristic || '').toLowerCase() === characteristicUUID.toLowerCase());
    const expectResponse = (characteristic?.properties || [])
    .indexOf('Write') >= 0;

    const writeChunkSize = device.settings.writeChunkSize != null ? device.settings.writeChunkSize : this.settings.writeChunkSize;
    if (!writeChunkSize || str.length <= writeChunkSize) {
      return this.writeChunkToCharacteristic(
        deviceID,
        serviceUUID,
        characteristicUUID,
        str,
        expectResponse,
      );
    } else {
      return new Observable((observer: Observer<any>) => {
        // Need to partion the string and write one chunk at a time.
        const chunks: string[] = [];
        let subStr = '';
        for (let i = 0; i < str.length; i += writeChunkSize) {
          if (i + writeChunkSize <= str.length) {
            subStr = str.substring(i, i + writeChunkSize)
          } else {
            subStr = str.substring(i, str.length)
          }
          chunks.push(subStr);
        }

        from(chunks)
        .pipe(
          concatMap((chunkStr: string) => {
            return this.writeChunkToCharacteristic(
              deviceID,
              serviceUUID,
              characteristicUUID,
              chunkStr,
              expectResponse,
            ).pipe(
              delay(100)
            );
          })
        ).subscribe(() => {

        }, (error: any) => {
          this.log('Error writing chunk to BT device: ' + error);
          observer.error(error);
        }, () => {
          this.log('Write data to BT device successfuly.');
          observer.next(null);
          observer.complete();
        });
      });
    }
  }

  private writeChunkToCharacteristic(deviceId: string, serviceUUID: string, characteristicUUID: string, str: string, expectResponse: boolean): Observable<any> {
    // Convert str to ArrayBuff and write to device
    const buffer = new ArrayBuffer(str.length);
    const dataView = new DataView(buffer);
    for (let i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.charAt(i).charCodeAt(0));
    }

    // Write buffer to device
    if (expectResponse) {
      return from(
        this.ble.write(
          deviceId,
          serviceUUID,
          characteristicUUID,
          buffer,
        )
      );
    } else {
      return from(
        this.ble.writeWithoutResponse(
          deviceId,
          serviceUUID,
          characteristicUUID,
          buffer,
        )
      );
    }
  }

  private handleScanData(value: string, valueType: string, scanSource: string) {
    if (value == null) return;
    if (this.lastScanTick && Date.now() - this.lastScanTick < 2 * 1000/* && this.lastScanValue === value*/) return;

    this.lastScanTick = Date.now();
    this.lastScanValue = value;

    this.scannerService.emitScan({
      source: scanSource,
      value: value,
      valueType: valueType,
    });
  }

  private satoBleCall(fnc: string, arg: any[] = [], id: string = 'RV') {
    satoApi.saveVariables({ BLE: { fnc: fnc, arg: arg, id: id } });
  }

  private satoBleCallback(data: any) {
    if (!data) return;
    if (data.id !== 'advertisement' && data.id.indexOf('getServices') < 0) this.log(JSON.stringify(data)); // there can be too many of these...they end up poluting the logs...

    const dataValue = JSON.parse(data.value);
    if (data.id === 'advertisement') {
      // Handle advertisements during scanning.
      const d = new BluetoothDevice({
        id: dataValue.mac,
        isConnected: false,
        mode: BluetoothDeviceMode.BLE,
        name: dataValue.name,
        settings: {
          remoteAddressRandom: dataValue.RemoteAddressRandom,
        },
        shouldBeConnected: false,
        type: BluetoothDeviceType.Unknown,
      })

      const knownDevice = this.getKnownDevice(d);
      if (knownDevice) {
        knownDevice.shouldBeConnected = true;
        this.discoveredDevices.push(knownDevice);
      } else {
        this.discoveredDevices.push(new BluetoothDevice(d));
      }
    } else if (data.id === 'update') {
      // Got a request to update connection parameter, just go along with the suggested ones.
      this.satoBleCall('updateConnectionParameters', [dataValue], 'updateConnectionParameters');
      this.log('updateConnectionParameters: ' + data.value);
    } else if (data.id === 'disconnect') {
      const device = this.satoBleDeviceMap[dataValue.mac];
      if (!device) return;

      this.disconnectSuccessHandler(device, 'Device disconnected', false, undefined);
      delete this.satoBleDeviceMap[device.id];
    } else if (data.id === 'connect') {
      for (const connectWrapper of dataValue) {
        const device = this.satoBleDeviceMap[connectWrapper.mac];
        if (!device) continue;

        if (connectWrapper.Established) {
          if (device.settings.established === connectWrapper.Established) continue; // duplicated message, we want to ignore it...

          device.settings.established = connectWrapper.Established;
          this.satoBleCall('getServices', [], 'getServices' + device.id);
          continue; // this.connectSuccessHandler(device, true, this.satoBleConnectObserverMap[device.id]);
        } else {
          if (!this.satoBleConnectObserverMap[device.id]) return; // this may be called twice

          this.connectErrorHandler(device, data.value, true, this.satoBleConnectObserverMap[device.id]);
          delete this.satoBleDeviceMap[device.id];
          delete this.satoBleConnectObserverMap[device.id];
        }
      }
    } else if (data.id.indexOf('getServices') === 0) {
      // Get Services, Characteristics, Descriptors and SATO handles
      const mac = data.id.substring('getServices'.length);
      const device = this.satoBleDeviceMap[mac];
      if (!device) return;

      for (const serviceWrapper of dataValue) {
        for (const serviceUUID of Object.keys(serviceWrapper)) {
          const service = serviceWrapper[serviceUUID];
          for (const characteristicUUID of Object.keys(service.Characteristics)) {
            const characteristic = service.Characteristics[characteristicUUID];
            for (const descriptorUUID of Object.keys(characteristic.Descriptors)) {
              if (descriptorUUID !== '0x2902') continue; // we only care about this one...i think...

              const descriptor = characteristic.Descriptors[descriptorUUID];
              device.settings.handles = device.settings.handles || {};
              device.settings.handles[serviceUUID + characteristicUUID] = descriptor.Handle;
            }
          }
        }
      }
      const observer = this.satoBleConnectObserverMap[device.id];
      if (!observer) return;

      this.connectSuccessHandler(device, true, observer);
      delete this.satoBleConnectObserverMap[device.id];
    } else if (data.id === 'notification') {
      const device = this.satoBleDeviceMap[dataValue.mac];
      if (!device) return;

      const temp = this.getSatoTemperatureValueInCelsius(dataValue.value);

      const satoHandle1 = device.settings.handles[`0x${BluetoothDevice.HealthThermometerServiceUUID}0x${BluetoothDevice.TemperatureStreamCharacteristicUUID}`];
      const subKey1 = device.id + '|' + satoHandle1;
      const callback = this.satoBleStartCallbackMap[subKey1];
      if (callback) callback({
        value: temp,
        valueType: 'temp-stream',
        source: device.name,
      });
    } else if (data.id === 'indication') {
      const device = this.satoBleDeviceMap[dataValue.mac];
      if (!device) return;

      const temp = this.getSatoTemperatureValueInCelsius(dataValue.value);
      if (this.lastScanTick && Date.now() - this.lastScanTick < 2 * 1000/* && this.lastScanValue === temp*/) return;

      this.handleScanData(temp.toFixed(1), 'temp-read', device.name);

      const satoHandle2 = device.settings.handles[`0x${BluetoothDevice.HealthThermometerServiceUUID}0x${BluetoothDevice.TemperatureMeasurementCharacteristicUUID}`];
      const subKey2 = device.id + '|' + satoHandle2;
      const callback = this.satoBleStartCallbackMap[subKey2];
      if (callback) callback({
        value: temp,
        valueType: 'temp-read',
        source: device.name,
      });
    }
  }

  /**
 * Converts a DataView that represents a 4 byte float (IEEE-11073)
 * to an actual float. Useful for example when reading temperature
 * from a Bluetooth thermometer :)
 *
 * The DataView buffer should contain at least 4 bytes:
 *
 *  [b0, b1, b2, b3]
 *   ^   ^   ^   └---------- Exponent
 *   └---└---└------- Will become the mantissa
 *
 * The offset param determines which byte in the DataView to start
 * from.
 *
 * @param value DataView
 * @param offset number
 */
  private getTemperatureValueInCelsius(value: DataView, offset: number) {
    const isFahrenheit = value.getInt8(0) === 1;
    // if the last byte is a negative value (MSB is 1), the final
    // float should be too
    const negative = value.getInt8(offset + 2) >>> 31;

    // this is how the bytes are arranged in the byte array/DataView
    // buffer
    const [b0, b1, b2, exponent] = [
        // get first three bytes as unsigned since we only care
        // about the last 8 bits of 32-bit js number returned by
        // getUint8().
        // Should be the same as: getInt8(offset) & -1 >>> 24
        value.getUint8(offset),
        value.getUint8(offset + 1),
        value.getUint8(offset + 2),

        // get the last byte, which is the exponent, as a signed int
        // since it's already correct
        value.getInt8(offset + 3)
    ];

    let mantissa = b0 | (b1 << 8) | (b2 << 16);
    if (negative) {
        // need to set the most significant 8 bits to 1's since a js
        // number is 32 bits but our mantissa is only 24.
        mantissa |= 255 << 24;
    }

    let temp = mantissa * Math.pow(10, exponent);
    if (isFahrenheit) temp = (temp - 32) * (5/9);
    return temp;
  }

  private getSatoTemperatureValueInCelsius(data: any) {
    const rawdata = this.binStringToBuffer(data);
    const rawview = new DataView(rawdata);
    // Our rawdata contains three fields at offsets:
    // 0: flags, UInt8
    // 1: mantissa, Int24, little endian. We sign extend into a Int32.
    // 4: exponent, Int8

    const flags = rawview.getUint8(0);
    const exp = rawview.getInt8(4);
    const mantData = new ArrayBuffer(4);
    const mantView = new DataView(mantData);
    mantView.setUint8(0, (rawview.getInt8(3) < 0) ? 0xff : 0x00);
    mantView.setUint8(1, rawview.getUint8(3));
    mantView.setUint8(2, rawview.getUint8(2));
    mantView.setUint8(3, rawview.getUint8(1));
    const mant = mantView.getInt32(0, false); // big endian order

    let temp = { unit:'', value: 0, error: '' };

    temp.unit = ((flags & 1) == 0) ? '°C' : '°F';

    if (exp == 0 && mant == 0x007ffffe) {
      temp.error = '+INF';
    } else if (exp == 0 && mant == 0x007fffff) {
      temp.error = 'N/A';
    } else if (exp == 0 && mant == 0xff800000) {
      temp.error = 'NRes';
    } else if (exp == 0 && mant == 0xff800001) {
      temp.error = 'Reserved';
    } else if (exp == 0 && mant == 0xff800002) {
      temp.error = '-INF';
    } else {
      temp.value = mant * Math.pow(10, exp);
    }
    if (temp.unit === '°F') temp.value = (temp.value - 32) * (5/9);
    return temp.value;
  }

  private binStringToBuffer(str: string) {
    let matches: any = str.match(/%[0-9a-f]{1,2}/gi);
    matches = matches.map((x: string) => x.replace(/%/g, ''));
    const buffer = new ArrayBuffer(matches.length);
    const uint8 = new Uint8Array(buffer);
    let i = 0;
    for (i = 0; i < matches.length; i++) {
        uint8[i] = parseInt(matches[i], 16);
    }
    return buffer;
  }

  private stringToBytes(str: string) {
    const array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        array[i] = str.charCodeAt(i);
     }
     return array.buffer;
  }

  private bytesToString(buffer: any) {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer)));
  }


}
