<div class="height-100 flex-col center middle gap-1">
  <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
    {{ layoutControl | parseRV:'ControlHeadlineText' }}
  </h2>

  <div class="swish-info number">
    {{ swishNumber }}
  </div>

  <div class="swish-info amount">
    {{ swishAmount }}{{ swishAmountCurrency }}
  </div>

  <div *ngIf="swishSvg"
    class="swish-qr"
    [innerHTML]="swishSvg">
  </div>
  <div *ngIf="!swishSvg"
    class="swish-qr">
    <img alt="swish-qr"
      [src]="swishBase64 ? swishBase64 : 'assets/img/loading.gif'" />
  </div>
</div>