import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { LogUtils } from '../../utils';
import { JsPluginLoaderService } from '../js-plugin-loader/js-plugin-loader.service';
declare var CortexDecoderWeb;

export interface CortexInitOptions {
  decoderTimeLimit?: number; // default on the web SDK is 50...not really sure on native
  exactlyNBarcodes?: boolean;
  licenseKey: string;
  numberOfBarcodesToDecode?: number; // default is 1, max is 20
}

@Injectable({
  providedIn: 'root'
})
export class CortexService {

  // symbologies enabled by default:
  // CortexDecoderWeb.CDSymbology.Aztec
  // CortexDecoderWeb.CDSymbology.Code39
  // CortexDecoderWeb.CDSymbology.Code93
  // CortexDecoderWeb.CDSymbology.Code128
  // CortexDecoderWeb.CDSymbology.DataMatrix
  // CortexDecoderWeb.CDSymbology.DotCode
  // CortexDecoderWeb.CDSymbology.EAN8
  // CortexDecoderWeb.CDSymbology.EAN13
  // CortexDecoderWeb.CDSymbology.GS1Databar14
  // CortexDecoderWeb.CDSymbology.Interleaved2of5
  // CortexDecoderWeb.CDSymbology.PDF417
  // CortexDecoderWeb.CDSymbology.QR
  // CortexDecoderWeb.CDSymbology.UPCA
  // CortexDecoderWeb.CDSymbology.UPCE

  private isCameraLoaded: boolean;
  private isLoaded: boolean;

  constructor(
    private jsPluginLoaderService: JsPluginLoaderService,
  ) {

  }

  init(options: CortexInitOptions): Observable<any> {
    if (this.isLoaded) return of(null);

    const now = performance.now();
    return this.jsPluginLoaderService.load('cortex')
    .pipe(
      delay(100),
      mergeMap(() => {
        return from(CortexDecoderWeb.CDDecoder.init('../assets/js-plugins/cortex'));
      }),
      mergeMap(() => {
        CortexDecoderWeb.CDDecoder.decoderTimeLimit = options?.decoderTimeLimit || 50; // default is 50ms
        CortexDecoderWeb.CDDecoder.setBarcodesToDecode(options?.numberOfBarcodesToDecode || 10, options?.exactlyNBarcodes || false);

        const cdSymbology = new CortexDecoderWeb.CDSymbology();
        cdSymbology.Aztec.enable = false;
        cdSymbology.DataMatrix.enable = false;
        cdSymbology.DotCode.enable = false;
        cdSymbology.Interleaved2of5.enable = false;
        cdSymbology.PDF417.enable = false;
        cdSymbology.QR.enable = true;
        cdSymbology.UPCA.enable = false;
        cdSymbology.UPCE.enable = false;

        return from(CortexDecoderWeb.CDLicense.activateLicense(options.licenseKey));
      }),
      map((result: any) => {
        LogUtils.log(`CortexService.init() took: ${~~(performance.now() - now)}ms`, result);
        this.isLoaded = true;
      }),
    );
  }

  startCamera(videoEl?: HTMLVideoElement): Observable<any> {
    const now = performance.now();
    return from(CortexDecoderWeb.CDCamera.init(videoEl))
    .pipe(
      mergeMap(() => {
        if (this.isCameraLoaded) return of(null);

        const cameras = CortexDecoderWeb.CDCamera.getConnectedCameras() || [];
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          return from(CortexDecoderWeb.CDCamera.setCameraPosition(0)) // Back
          .pipe(
            catchError((error: any) => {
              return from(CortexDecoderWeb.CDCamera.setCamera(cameras[0].deviceId));
            })
          );
        } else {
          const camera = cameras.find(c => (c.label?.toUpperCase() || '').indexOf('BACK') >= 0) || cameras[0];
          return from(CortexDecoderWeb.CDCamera.setCamera(camera.deviceId));
        }
      }),
      mergeMap(() => {
        if (this.isCameraLoaded) return of(null);

        return from(CortexDecoderWeb.CDCamera.setResolution(1));
      }),
      mergeMap(() => {
        return from (CortexDecoderWeb.CDCamera.startCamera());
      }),
      map(() => {
        this.isCameraLoaded = true;
        console.log(`CortexService.startCamera() took: ${~~(performance.now() - now)}ms`);
      })
    );
  }

  getResolutionDimensions(): Observable<{ width: number, height: number }> {
    // RES640x360: 0
    // RES1280x720: 1
    // RES1920x1080: 2
    // RES3840x2160: 3
    return from(CortexDecoderWeb.CDCamera.getResolution())
    .pipe(
      map((resolution: number) => {
        switch (resolution) {
          case 3:
            return { width: 3840, height: 2160 };
          case 2:
            return { width: 1920, height: 1080 };
          case 0:
            return { width: 640, height: 360 };
          case 1:
          default:
            return { width: 1280, height: 720 };
        }
      })
    );
  }

  startVideoCapture(resultCallback: any): Observable<any> {
    const now = performance.now();
    return from(CortexDecoderWeb.CDCamera.startPreview(resultCallback))
    .pipe(
      map((result) => {
        console.log(`CortexService.startPreview() took: ${~~(performance.now() - now)}ms`);
      })
    );
  }

  stopVideoCapture(): Observable<any> {
    const now = performance.now();
    return from(CortexDecoderWeb.CDCamera.stopPreview())
    .pipe(
      mergeMap(() => {
        return from (CortexDecoderWeb.CDCamera.stopCamera());
      }),
      map((result) => {
        console.log(`CortexService.stopPreviewAndCamera() took: ${~~(performance.now() - now)}ms`);
      })
    );
  }

}
