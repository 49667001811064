import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { LayoutMessageType } from "./LayoutMessageType";
import { LayoutMessageResult } from "./LayoutMessageResult";

export class LayoutCoreMessage {
    messageOrigin: bigint;
    messageSequenceNr: bigint;
    messageType: LayoutMessageType;
    responseMessage: boolean;
    messageResult: LayoutMessageResult;
    messageContent: Uint8Array | null;
    errorMessage: string | null;

    constructor() {
        this.messageOrigin = 0n;
        this.messageSequenceNr = 0n;
        this.messageType = 0;
        this.responseMessage = false;
        this.messageResult = 0;
        this.messageContent = null;
        this.errorMessage = null;

    }

    static serialize(value: LayoutCoreMessage | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: LayoutCoreMessage | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(7);
        writer.writeInt64(value.messageOrigin);
        writer.writeInt64(value.messageSequenceNr);
        writer.writeInt32(value.messageType);
        writer.writeBoolean(value.responseMessage);
        writer.writeInt32(value.messageResult);
        writer.writeUint8Array(value.messageContent);
        writer.writeString(value.errorMessage);

    }

    static serializeArray(value: (LayoutCoreMessage | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (LayoutCoreMessage | null)[] | null): void {
        writer.writeArray(value, (writer, x) => LayoutCoreMessage.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): LayoutCoreMessage | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): LayoutCoreMessage | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new LayoutCoreMessage();
        if (count == 7) {
            value.messageOrigin = reader.readInt64();
            value.messageSequenceNr = reader.readInt64();
            value.messageType = reader.readInt32();
            value.responseMessage = reader.readBoolean();
            value.messageResult = reader.readInt32();
            value.messageContent = reader.readUint8Array();
            value.errorMessage = reader.readString();

        }
        else if (count > 7) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.messageOrigin = reader.readInt64(); if (count == 1) return value;
            value.messageSequenceNr = reader.readInt64(); if (count == 2) return value;
            value.messageType = reader.readInt32(); if (count == 3) return value;
            value.responseMessage = reader.readBoolean(); if (count == 4) return value;
            value.messageResult = reader.readInt32(); if (count == 5) return value;
            value.messageContent = reader.readUint8Array(); if (count == 6) return value;
            value.errorMessage = reader.readString(); if (count == 7) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (LayoutCoreMessage | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (LayoutCoreMessage | null)[] | null {
        return reader.readArray(reader => LayoutCoreMessage.deserializeCore(reader));
    }
}
