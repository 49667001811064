import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutValue {
    valueTypeId: number;
    valueJson: string | null;
    extendedValueType: string | null;

    constructor() {
        this.valueTypeId = 0;
        this.valueJson = null;
        this.extendedValueType = null;

    }

    static serialize(value: RuntimeLayoutValue | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutValue | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(3);
        writer.writeInt32(value.valueTypeId);
        writer.writeString(value.valueJson);
        writer.writeString(value.extendedValueType);

    }

    static serializeArray(value: (RuntimeLayoutValue | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutValue | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutValue.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutValue | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutValue | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutValue();
        if (count == 3) {
            value.valueTypeId = reader.readInt32();
            value.valueJson = reader.readString();
            value.extendedValueType = reader.readString();

        }
        else if (count > 3) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.valueTypeId = reader.readInt32(); if (count == 1) return value;
            value.valueJson = reader.readString(); if (count == 2) return value;
            value.extendedValueType = reader.readString(); if (count == 3) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutValue | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutValue | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutValue.deserializeCore(reader));
    }
}
