import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DictString, Notification, Scan } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { LogUtils } from 'src/app/shared/utils';
import { BasePlugin, PluginType } from 'src/app/shared/services';
import { Subscription } from 'rxjs';
import { SharedModule } from 'src/app/shared/shared.module';
import { SolutionDeviceControlScannerEnabledFlagType } from 'src/app/shared/models/runtime-layout/solution-device-control-scanner-enabled-type.enum';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';

enum RfidUiType {
  SimpleCounter = 0,
  TidList = 1,
  List = 2,
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-control-rfid-scan',
  templateUrl: 'control-rfid-scan.component.html',
  styleUrls: ['./control-rfid-scan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRfidScanComponent extends ControlBaseComponent implements OnInit {

  get allBufferValues() {
    return this.appService.bufferingSetValues?.[this.layoutControl?.objectId?.toString()] || [];
  }

  get uiBufferValues() {
    return this.appService.uiBufferingSetValues?.[this.layoutControl?.objectId?.toString()] || [];
  }

  theme: string;

  bufferValues: string[];
  cipherLabPlugin: BasePlugin;
  listenRfidSubscription: Subscription;
  rfidUiType: RfidUiType;
  rfidTagTimeoutInSeconds: number;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    (this.layoutControl as RuntimeLayoutControl).scannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Advanced; // | SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner;

    this.bufferValues = [];

    this.rfidUiType = RuntimeLayoutUtils.parseRV(this.layoutControl, 'RfidUiType', RfidUiType.SimpleCounter);
    this.rfidTagTimeoutInSeconds = RuntimeLayoutUtils.parseRV(this.layoutControl, 'RfidTagTimeout', 0) * 1000;

    this.cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    if (!this.cipherLabPlugin.isPluginAllowed()) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Not running on CipherLab device...'),
        type: RuntimeLayoutNotifyType.Alert,
      }));
      return;
    }

    this.cipherLabPlugin.action({ command: 'has_rfid' })
    .subscribe((result: boolean) => {
      if (!result) {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('CipherLab RFID Pistol not available.'),
          type: RuntimeLayoutNotifyType.Alert,
        }));
        return;
      }

      if (this.listenRfidSubscription && !this.listenRfidSubscription.closed) return;

      this.listenRfidSubscription = this.scannerService.listenRfid()
      .subscribe((scan: Scan) => {
        const scanTid = (scan.tagId || '').toUpperCase();
        const scanEpc = (scan.tagEpc || '').toUpperCase();

        const addToUiBufferResult = this.addToUiBuffer(scanTid || scanEpc);
        LogUtils.warn('addToUiBuffer(' + (scanTid || scanEpc) + '): ' + addToUiBufferResult);
      });
      this.subscriptions.push(this.listenRfidSubscription);
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    const addToBufferResult = this.addToBuffer(this.scanValue || '', false);
    LogUtils.warn('addToBuffer(' + this.scanValue + ', false): ' + addToBufferResult);

    context.set('RfidBufferTids', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.bufferValues || []),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    return context;
  }

  addToBuffer(value: string, dryRun?: boolean): boolean {
    this.bufferValues = this.bufferValues || [];
    this.appService.bufferingSetValues[this.layoutControl.objectId.toString()] = this.appService.bufferingSetValues[this.layoutControl.objectId.toString()] || [];

    const now = Date.now();
    if (!value) {
      // if (!dryRun) LogUtils.log('RfidBuffering: ignoring - no value.');
      return false;
    } else if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingItemRegEx') && !(new RegExp(RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingItemRegEx'))).test(value)) {
      if (!dryRun) LogUtils.log('RfidBuffering: ignoring - value doesn\'t pass regex.');
      return false;
    } else if (
      this.appService.bufferingSetValues[this.layoutControl.objectId.toString()].indexOf(value) >= 0
      && (!this.rfidTagTimeoutInSeconds || (now - (this.appService.bufferingValuesTickMap[value] || 0)) <= this.rfidTagTimeoutInSeconds)
    ) {
      if (!dryRun) LogUtils.log('RfidBuffering: ignoring - value already read on the current control buffer according to the configured RfidTagTimeout (' + (this.rfidTagTimeoutInSeconds || '0') + ')');
      return false;
    }

    if (!dryRun) {
      this.bufferValues.push(value);
      this.appService.bufferingValuesTickMap[value] = now;
      this.appService.bufferingSetValues[this.layoutControl.objectId.toString()].push(value);
      this.cdr.markForCheck();
    }
    return true;
  }

  addToUiBuffer(value: string): boolean {
    this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()] = this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()] || [];

    const now = Date.now();
    if (!value) {
      return false;
    } else if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingItemRegEx') && !(new RegExp(RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingItemRegEx'))).test(value)) {
      return false;
    } else if (
      this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()].indexOf(value) >= 0
      && (!this.rfidTagTimeoutInSeconds || (now - (this.appService.uiBufferingValuesTickMap[value] || 0)) <= this.rfidTagTimeoutInSeconds)
    ) {
      return false;
    }

    this.appService.uiBufferingValuesTickMap[value] = now;
    this.appService.uiBufferingSetValues[this.layoutControl.objectId.toString()].push(value);
    this.cdr.markForCheck();
    return true;
  }

}

