import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";
import { RuntimeLayoutValue } from "./RuntimeLayoutValue";

export class RuntimeLayoutHead {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    runtimeHeadObjectId: bigint;
    sourceRuntimeObjectId: bigint;
    headSequenceNr: number;
    headDesignStyleObjectId: bigint;
    renderValues: Map<string | null, RuntimeLayoutValue | null> | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.runtimeHeadObjectId = 0n;
        this.sourceRuntimeObjectId = 0n;
        this.headSequenceNr = 0;
        this.headDesignStyleObjectId = 0n;
        this.renderValues = null;

    }

    static serialize(value: RuntimeLayoutHead | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutHead | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(9);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.runtimeHeadObjectId);
        writer.writeInt64(value.sourceRuntimeObjectId);
        writer.writeInt32(value.headSequenceNr);
        writer.writeInt64(value.headDesignStyleObjectId);
        writer.writeMap(value.renderValues, (writer, x) => writer.writeString(x), (writer, x) => RuntimeLayoutValue.serializeCore(writer, x));

    }

    static serializeArray(value: (RuntimeLayoutHead | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutHead | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutHead.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutHead | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutHead | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutHead();
        if (count == 9) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.runtimeHeadObjectId = reader.readInt64();
            value.sourceRuntimeObjectId = reader.readInt64();
            value.headSequenceNr = reader.readInt32();
            value.headDesignStyleObjectId = reader.readInt64();
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader));

        }
        else if (count > 9) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.runtimeHeadObjectId = reader.readInt64(); if (count == 5) return value;
            value.sourceRuntimeObjectId = reader.readInt64(); if (count == 6) return value;
            value.headSequenceNr = reader.readInt32(); if (count == 7) return value;
            value.headDesignStyleObjectId = reader.readInt64(); if (count == 8) return value;
            value.renderValues = reader.readMap(reader => reader.readString(), reader => RuntimeLayoutValue.deserializeCore(reader)); if (count == 9) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutHead | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutHead | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutHead.deserializeCore(reader));
    }
}
