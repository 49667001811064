import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutControlAction {
    actionGuidId: string | null;
    actionText: string | null;
    enabled: boolean;
    shortAction: boolean;
    shortActionText: string | null;
    shortActionPosition: number;
    shortActionSize: number;

    constructor() {
        this.actionGuidId = null;
        this.actionText = null;
        this.enabled = false;
        this.shortAction = false;
        this.shortActionText = null;
        this.shortActionPosition = 0;
        this.shortActionSize = 0;

    }

    static serialize(value: RuntimeLayoutControlAction | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutControlAction | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(7);
        writer.writeString(value.actionGuidId);
        writer.writeString(value.actionText);
        writer.writeBoolean(value.enabled);
        writer.writeBoolean(value.shortAction);
        writer.writeString(value.shortActionText);
        writer.writeInt32(value.shortActionPosition);
        writer.writeInt32(value.shortActionSize);

    }

    static serializeArray(value: (RuntimeLayoutControlAction | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutControlAction | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutControlAction.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutControlAction | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutControlAction | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutControlAction();
        if (count == 7) {
            value.actionGuidId = reader.readString();
            value.actionText = reader.readString();
            value.enabled = reader.readBoolean();
            value.shortAction = reader.readBoolean();
            value.shortActionText = reader.readString();
            value.shortActionPosition = reader.readInt32();
            value.shortActionSize = reader.readInt32();

        }
        else if (count > 7) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.actionGuidId = reader.readString(); if (count == 1) return value;
            value.actionText = reader.readString(); if (count == 2) return value;
            value.enabled = reader.readBoolean(); if (count == 3) return value;
            value.shortAction = reader.readBoolean(); if (count == 4) return value;
            value.shortActionText = reader.readString(); if (count == 5) return value;
            value.shortActionPosition = reader.readInt32(); if (count == 6) return value;
            value.shortActionSize = reader.readInt32(); if (count == 7) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutControlAction | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutControlAction | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutControlAction.deserializeCore(reader));
    }
}
