import { MemoryPackWriter } from "./MemoryPackWriter";
import { MemoryPackReader } from "./MemoryPackReader";

export class RuntimeLayoutNotify {
    snapshotObject: boolean;
    objectId: bigint;
    active: boolean;
    tick: bigint;
    controlObjectId: bigint;
    notifyGuidId: string | null;
    notifyType: number;
    blocking: boolean;
    text: string | null;
    showTimeInSeconds: number;
    tTLDateTime: string | null;

    constructor() {
        this.snapshotObject = false;
        this.objectId = 0n;
        this.active = false;
        this.tick = 0n;
        this.controlObjectId = 0n;
        this.notifyGuidId = null;
        this.notifyType = 0;
        this.blocking = false;
        this.text = null;
        this.showTimeInSeconds = 0;
        this.tTLDateTime = null;

    }

    static serialize(value: RuntimeLayoutNotify | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeCore(writer, value);
        return writer.toArray();
    }

    static serializeCore(writer: MemoryPackWriter, value: RuntimeLayoutNotify | null): void {
        if (value == null) {
            writer.writeNullObjectHeader();
            return;
        }

        writer.writeObjectHeader(11);
        writer.writeBoolean(value.snapshotObject);
        writer.writeInt64(value.objectId);
        writer.writeBoolean(value.active);
        writer.writeInt64(value.tick);
        writer.writeInt64(value.controlObjectId);
        writer.writeString(value.notifyGuidId);
        writer.writeInt32(value.notifyType);
        writer.writeBoolean(value.blocking);
        writer.writeString(value.text);
        writer.writeInt32(value.showTimeInSeconds);
        writer.writeString(value.tTLDateTime);

    }

    static serializeArray(value: (RuntimeLayoutNotify | null)[] | null): Uint8Array {
        const writer = MemoryPackWriter.getSharedInstance();
        this.serializeArrayCore(writer, value);
        return writer.toArray();
    }

    static serializeArrayCore(writer: MemoryPackWriter, value: (RuntimeLayoutNotify | null)[] | null): void {
        writer.writeArray(value, (writer, x) => RuntimeLayoutNotify.serializeCore(writer, x));
    }

    static deserialize(buffer: ArrayBuffer): RuntimeLayoutNotify | null {
        return this.deserializeCore(new MemoryPackReader(buffer));
    }

    static deserializeCore(reader: MemoryPackReader): RuntimeLayoutNotify | null {
        const [ok, count] = reader.tryReadObjectHeader();
        if (!ok) {
            return null;
        }

        const value = new RuntimeLayoutNotify();
        if (count == 11) {
            value.snapshotObject = reader.readBoolean();
            value.objectId = reader.readInt64();
            value.active = reader.readBoolean();
            value.tick = reader.readInt64();
            value.controlObjectId = reader.readInt64();
            value.notifyGuidId = reader.readString();
            value.notifyType = reader.readInt32();
            value.blocking = reader.readBoolean();
            value.text = reader.readString();
            value.showTimeInSeconds = reader.readInt32();
            value.tTLDateTime = reader.readString();

        }
        else if (count > 11) {
            throw new Error("Current object's property count is larger than type schema, can't deserialize about versioning.");
        }
        else {
            if (count == 0) return value;
            value.snapshotObject = reader.readBoolean(); if (count == 1) return value;
            value.objectId = reader.readInt64(); if (count == 2) return value;
            value.active = reader.readBoolean(); if (count == 3) return value;
            value.tick = reader.readInt64(); if (count == 4) return value;
            value.controlObjectId = reader.readInt64(); if (count == 5) return value;
            value.notifyGuidId = reader.readString(); if (count == 6) return value;
            value.notifyType = reader.readInt32(); if (count == 7) return value;
            value.blocking = reader.readBoolean(); if (count == 8) return value;
            value.text = reader.readString(); if (count == 9) return value;
            value.showTimeInSeconds = reader.readInt32(); if (count == 10) return value;
            value.tTLDateTime = reader.readString(); if (count == 11) return value;

        }
        return value;
    }

    static deserializeArray(buffer: ArrayBuffer): (RuntimeLayoutNotify | null)[] | null {
        return this.deserializeArrayCore(new MemoryPackReader(buffer));
    }

    static deserializeArrayCore(reader: MemoryPackReader): (RuntimeLayoutNotify | null)[] | null {
        return reader.readArray(reader => RuntimeLayoutNotify.deserializeCore(reader));
    }
}
