/*global cordova*/

/**
 * Constructor.
 *
 * @returns {CipherLab}
 */
function CipherLab() {
    var connectAndWriteInProgress = false;
}

CipherLab.prototype.setNotification = function(options, successCallback, errorCallback) {
    errorCallback = errorCallback || function () {};

    successCallback = successCallback || function () {};

    cordova.exec(
        (result) => {
            successCallback(result);
        },
        (error) => {
            errorCallback(error);
        },
        'CipherLabPlugin',
        'setNotification',
        [options],
    );
};

CipherLab.prototype.setRFIDMode = function(options, successCallback, errorCallback) {
    errorCallback = errorCallback || function () {};

    successCallback = successCallback || function () {};

    cordova.exec(
        (result) => {
            successCallback(result);
        },
        (error) => {
            errorCallback(error);
        },
        'CipherLabPlugin',
        'setRFIDMode',
        [options],
    );
};

CipherLab.prototype.softScanTrigger = function(options, successCallback, errorCallback) {
    errorCallback = errorCallback || function () {};

    successCallback = successCallback || function () {};

    cordova.exec(
        (result) => {
            successCallback(result);
        },
        (error) => {
            errorCallback(error);
        },
        'CipherLabPlugin',
        'softScanTrigger',
        [options],
    );
};

var cipherLab = new CipherLab();
module.exports = cipherLab;
